import React from 'react'
import { graphql, HeadProps, PageProps, StaticQueryDocument } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import {
  PrismicMenuItem,
  MenuItemQuery,
  PrismicSiteInformation,
  Site,
} from 'src/typings/generated/graphql'

// components
import TitleAndDescription, {
  TitleAndDescriptionTextAlign,
  TitleAndDescriptionBackgroundColor,
} from 'src/components/organisms/general/titleAndDescription'
import { WithContext, Menu } from 'schema-dts'
import DocumentHead from 'src/components/pageLayout/documentHead'
import MenuItemDetails from 'src/components/organisms/productRouting/menuItemDetails'
import RichTextCta from '../components/organisms/ctas/richTextCta'
import Locator from '../components/organisms/restaurantLocator/locator'

import * as Styles from './{prismicMenuItem.url}.module.scss'

const MenuItemTemplate = ({ data }: PageProps<MenuItemQuery>) => {
  if (!data) return null

  const menuItem = data.prismicMenuItem as PrismicMenuItem

  return (
    <div className={Styles.menuItemTemplate}>
      {menuItem.data.heading && (
        <TitleAndDescription
          content={{
            title: menuItem.data.heading ?? '',
            titleAsH1: true,
            hideEyebrow: false,
            textAlign: TitleAndDescriptionTextAlign.center,
            backgroundColor: TitleAndDescriptionBackgroundColor.black,
          }}
        />
      )}
      <MenuItemDetails
        content={{
          name: menuItem.data.name ?? '',
          description: menuItem.data.description ?? undefined,
          image: menuItem.data.image?.gatsbyImageData ?? undefined,
          imageAlt: menuItem.data.image?.alt ?? undefined,
        }}
      />
      {menuItem.data.cta_copy && menuItem.data.cta_button_text && (
        <RichTextCta
          content={{
            description: menuItem.data.cta_copy ?? undefined,
            link: menuItem.data.cta_button_link ?? undefined,
            linkText: menuItem.data.cta_button_text ?? '',
          }}
        />
      )}
      <Locator
        content={{
          callout:
            data.prismicGlobalContent?.data?.menu_item_locator_text ?? '',
          link:
            data.prismicGlobalContent?.data?.menu_item_locator_button_link ??
            undefined,
          linkText:
            data.prismicGlobalContent?.data?.menu_item_locator_button_text ??
            '',
        }}
      />
    </div>
  )
}

export default withPrismicPreview(MenuItemTemplate)

export const Head = ({ data }: HeadProps<MenuItemQuery>) => {
  const { prismicMenuItem, prismicSiteInformation, site, prismicPage } = data
  const { siteMetadata } = site as Site
  const siteInformation = prismicSiteInformation as PrismicSiteInformation
  const { data: menuItem, url } = prismicMenuItem as PrismicMenuItem

  // extract necessary data to form JSON+LD schema for MenuItems
  const title =
    menuItem.meta_title ?? menuItem.heading ?? menuItem.name ?? 'Menu Item'

  const description =
    menuItem.meta_description ??
    menuItem?.description?.richText ??
    siteInformation?.data.site_description ??
    siteMetadata?.description ??
    ''
  const generalDescription =
    prismicPage?.data?.meta_description ??
    siteInformation?.data.site_description ??
    siteMetadata?.description ??
    ''
  const imageUrl =
    menuItem.meta_image?.url ??
    menuItem.image?.gatsbyImageData ??
    prismicSiteInformation?.data.site_icon?.url ??
    siteMetadata?.logoPath ??
    ''
  const noIndex = menuItem.meta_no_index ?? undefined

  const schema: WithContext<Menu> = {
    '@context': 'https://schema.org',
    '@type': 'Menu',
    url: siteInformation?.data.menu_page?.url ?? '',
    name: 'Raising Canes Chicken Fingers Menu',
    image: siteInformation?.data.site_icon?.url ?? '',
    description: generalDescription ?? '',
    hasMenuItem: {
      '@type': 'MenuItem',
      url: url ?? '',
      name: title ?? '',
      description: description ?? '',
      image: imageUrl ?? '',
    },
  }

  return DocumentHead({
    title,
    url: url ?? '',
    description,
    imageUrl,
    pageSchema: schema,
    noIndex,
  })
}

export const query: StaticQueryDocument = graphql`
  query MenuItem($id: String) {
    prismicMenuItem(id: { eq: $id }) {
      _previewable
      data {
        name
        heading
        description {
          richText
        }
        cta_button_link {
          ...BasicLinkFragment
        }
        cta_button_text
        cta_copy {
          richText
        }
        image {
          gatsbyImageData
          alt
        }
        meta_title
        meta_description
        meta_image {
          url
        }
        meta_no_index
      }
      url
    }
    prismicGlobalContent {
      data {
        menu_item_locator_button_link {
          url
        }
        menu_item_locator_button_text
        menu_item_locator_text
      }
    }
    prismicSiteInformation {
      data {
        site_icon {
          url
        }
        site_description
        menu_page {
          url
        }
      }
    }
    site {
      siteMetadata {
        description
        logoPath
      }
    }
    prismicPage(uid: { eq: "menu" }) {
      data {
        meta_description
      }
    }
  }
`

/**
 * Touch Start
 *
 * Handles when a touch was started
 *
 * @param e TouchEvent
 * @param handler Function
 */
export const touchStart = (e: React.TouchEvent, handler: Function) => {
  handler(e.targetTouches[0].clientX ?? 0)
}

/**
 * Touch Move
 *
 * Handles when a touch is moved
 *
 * @param e TouchEvent
 * @param handler Function
 */
export const touchMove = (e: React.TouchEvent, handler: Function) => {
  handler(e.targetTouches[0].clientX ?? 0)
}

/**
 * Mouse Start
 *
 * Handles when a mouse click was started
 *
 * @param e MouseEvent
 * @param handler Function
 */
export const mouseStart = (e: React.MouseEvent, handler: Function) => {
  handler(e.clientX ?? 0)
}

/**
 * Mouse Move
 *
 * Handles when a mouse click is moved
 *
 * @param e MouseEvent
 * @param handler Function
 */
export const mouseMove = (e: React.MouseEvent, handler: Function) => {
  handler(e.clientX ?? 0)
}

/**
 * Swipe End
 *
 * Handles when a swipe ends
 *
 * @param start Number
 * @param end Number
 * @param rightSwipeHandler Function
 * @param leftSwipeHandler Function
 */
export const swipeEnd = (
  start: number,
  end: number,
  rightSwipeHandler: Function,
  leftSwipeHandler: Function
) => {
  if (start - end > 75) {
    leftSwipeHandler()
  }
  if (start - end < -75) {
    rightSwipeHandler()
  }
}

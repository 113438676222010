import React from 'react'
import CategoryPill, { IPillStyle } from 'src/components/atoms/categoryPill'
import {
  Maybe,
  PrismicArticle,
  PrismicCategory,
} from 'src/typings/generated/graphql'
import { articleThumbnailImage } from 'src/utils/articlesHelper'

import * as Styles from './articleCard.module.scss'

type Props = {
  article: PrismicArticle
}

const ArticleCard = ({ article }: Props) => {
  const category = article.data.category?.document as Maybe<PrismicCategory>

  const publishedDate = () => {
    const dateStr = article.data.post_date ?? article.first_publication_date
    const date = new Date(dateStr)

    return date
      .toLocaleDateString('en-US', {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
      })
      .replaceAll('/', '.')
  }

  return (
    <a
      href={article.data.url?.url ?? `/news/${article.uid}`}
      target={article.data.url?.url ? '_blank' : '_self'}
      className={Styles.articleCard}
      rel="noreferrer"
    >
      <div className={Styles.imageWrapper}>
        {articleThumbnailImage(article, Styles.image)}
        {category && (
          <div className={Styles.category}>
            <CategoryPill
              category={category.data.name ?? ''}
              style={'grey-gold' as IPillStyle}
            />
          </div>
        )}
      </div>
      <h4>{article.data.title?.text}</h4>
      <p>{publishedDate()}</p>
    </a>
  )
}

export default ArticleCard

// extracted by mini-css-extract-plugin
export var banner = "communityInvolvement-module--banner--110e8";
export var button = "communityInvolvement-module--button--79793";
export var cardWrapper = "communityInvolvement-module--card-wrapper--da1b3";
export var communityInvolvement = "communityInvolvement-module--community-involvement--44529";
export var copy = "communityInvolvement-module--copy--0ca58";
export var ctaButton = "communityInvolvement-module--cta-button--cac69";
export var ctaDescription = "communityInvolvement-module--cta-description--c6446";
export var desktopGrid = "communityInvolvement-module--desktop-grid--0714b";
export var eyebrow = "communityInvolvement-module--eyebrow--9f335";
export var eyebrowText = "communityInvolvement-module--eyebrow-text--f134e";
export var headline = "communityInvolvement-module--headline--9e2fa";
export var lower = "communityInvolvement-module--lower--14a01";
export var mobileSlider = "communityInvolvement-module--mobileSlider--45dfd";
export var upper = "communityInvolvement-module--upper--35923";
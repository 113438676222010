import React from 'react'
import { graphql } from 'gatsby'
import ImageStrip, {
  ImageStripContent,
} from 'src/components/organisms/slidersAndGrids/imageStrip'
import { PrismicPageDataBodyImageStrip } from 'src/typings/generated/graphql'

type Props = {
  slice: PrismicPageDataBodyImageStrip
}

const ImageStripSlice = ({ slice }: Props) => {
  const content: ImageStripContent = {
    anchor: slice.primary.anchor ?? undefined,
    image1: slice.primary.image_1?.gatsbyImageData ?? undefined,
    image1Alt: slice.primary.image_1?.alt ?? undefined,
    image2: slice.primary.image_2?.gatsbyImageData ?? undefined,
    image2Alt: slice.primary.image_2?.alt ?? undefined,
    image3: slice.primary.image_3?.gatsbyImageData ?? undefined,
    image3Alt: slice.primary.image_3?.alt ?? undefined,
    image4: slice.primary.image_4?.gatsbyImageData ?? undefined,
    image4Alt: slice.primary.image_4?.alt ?? undefined,
  }

  return <ImageStrip content={content} />
}

export default ImageStripSlice

export const ImageStripFragment = graphql`
  fragment PageDataBodyImageStrip on PrismicPageDataBodyImageStrip {
    primary {
      anchor
      image_1 {
        gatsbyImageData
        alt
      }
      image_2 {
        gatsbyImageData
        alt
      }
      image_3 {
        gatsbyImageData
        alt
      }
      image_4 {
        gatsbyImageData
        alt
      }
    }
  }
`

import React from 'react'
import { graphql } from 'gatsby'
import { PrismicPageDataBodySaucyCarousel } from 'src/typings/generated/graphql'
import { SaucyCarouselItemContent } from 'src/components/molecules/saucyCarouselItem'
import SaucyCarousel, {
  SaucyCarouselContent,
} from 'src/components/organisms/engagement/saucyCarousel/saucyCarousel'

type Props = {
  slice: PrismicPageDataBodySaucyCarousel
}

const SaucyCarouselSlice = ({ slice }: Props) => {
  const content: SaucyCarouselContent = {
    anchor: slice.primary.anchor ?? undefined,
    title: slice.primary.title ?? undefined,
    ctaLinkText: slice.primary.cta_text ?? undefined,
    ctaLink: slice.primary.cta_link ?? undefined,
    items: slice.items.map(
      item =>
        ({
          title: item.title ?? undefined,
          image: item.image?.gatsbyImageData ?? undefined,
          imageAlt: item.image?.alt ?? undefined,
          link: item.link ?? undefined,
          mobileImage:
            item.image?.thumbnails?.mobile?.gatsbyImageData ?? undefined,
          mobileImageAlt: item.image?.thumbnails?.mobile?.alt ?? undefined,
          overrideTitle: item.override_title ?? undefined,
          overrideImage: item.override_image ?? undefined,
          videoUrl: item.video?.url ?? undefined,
        } as SaucyCarouselItemContent)
    ),
  }

  return <SaucyCarousel content={content} />
}

export default SaucyCarouselSlice

export const SaucyCarouselFragment = graphql`
  fragment PageDataBodySaucyCarousel on PrismicPageDataBodySaucyCarousel {
    id
    primary {
      anchor
      title
      cta_link {
        ...BasicLinkFragment
      }
      cta_text
    }
    items {
      title
      override_title
      override_image
      image {
        gatsbyImageData
        alt
        thumbnails {
          mobile {
            gatsbyImageData
            alt
          }
        }
      }
      link {
        ...DynamicLinkFragment
      }
      video {
        url
      }
    }
  }
`

import { graphql } from 'gatsby'
import React from 'react'
import BannerCta, {
  BannerCtaContent,
} from 'src/components/organisms/ctas/bannerCta'
import { PrismicPageDataBodyBannerCta } from 'src/typings/generated/graphql'

type Props = {
  slice: PrismicPageDataBodyBannerCta
}

const BannerCtaSlice = ({ slice }: Props) => {
  const content: BannerCtaContent = {
    anchor: slice.primary.anchor ?? undefined,
    details: slice.primary.details ?? undefined,
    link: slice.primary.link ?? undefined,
    linkText: slice.primary.link_text ?? undefined,
  }

  return <BannerCta content={content} />
}

export default BannerCtaSlice

export const BannerFragment = graphql`
  fragment PageDataBodyBannerCta on PrismicPageDataBodyBannerCta {
    primary {
      anchor
      details
      link_text
      link {
        ...BasicLinkFragment
      }
    }
  }
`

// extracted by mini-css-extract-plugin
export var content = "crewCallout-module--content--c34da";
export var contentWrapper = "crewCallout-module--content-wrapper--d8401";
export var crewCalloutSlice = "crewCallout-module--crew-callout-slice--2e8c4";
export var description = "crewCallout-module--description--3124d";
export var featuredCrew = "crewCallout-module--featured-crew--73931";
export var headline = "crewCallout-module--headline--fa4f6";
export var highlight = "crewCallout-module--highlight--8e57f";
export var image = "crewCallout-module--image--3e4c2";
export var imageContainer = "crewCallout-module--image-container--fef24";
export var imagesWrapper = "crewCallout-module--images-wrapper--2bed3";
export var location = "crewCallout-module--location--bcc16";
export var retroIconsWrapper = "crewCallout-module--retro-icons-wrapper--01fcd";
import * as React from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { generateRandomId } from 'src/utils/domHelper'
import * as Styles from './imageGrid.module.scss'

export type ImageGridContent = {
  anchor?: string
  text1?: string
  text2?: string
  text3?: string
  image1?: IGatsbyImageData
  image1Alt?: string
  image2?: IGatsbyImageData
  image2Alt?: string
  image3?: IGatsbyImageData
  image3Alt?: string
  image4?: IGatsbyImageData
  image4Alt?: string
  image5?: IGatsbyImageData
  image5Alt?: string
  image6?: IGatsbyImageData
  image6Alt?: string
}

interface Props {
  content: ImageGridContent
}

const ImageGrid = ({ content }: Props) => {
  const {
    anchor = generateRandomId(),
    text1 = '',
    text2 = '',
    text3 = ',',
    image1,
    image1Alt = '',
    image2,
    image2Alt = '',
    image3,
    image3Alt = '',
    image4,
    image4Alt = '',
    image5,
    image5Alt = '',
    image6,
    image6Alt = '',
  } = content

  return (
    <section id={anchor} className={Styles.imageGrid}>
      <div className={Styles.gridWrapper}>
        <div className={Styles.text}>{text1}</div>
        <div>
          {!!image1 && (
            <GatsbyImage
              image={image1}
              alt={image1Alt}
              className={Styles.image}
            />
          )}
        </div>
        <div>
          {!!image2 && (
            <GatsbyImage
              image={image2}
              alt={image2Alt}
              className={Styles.image}
            />
          )}
        </div>
        <div>
          {!!image3 && (
            <GatsbyImage
              image={image3}
              alt={image3Alt}
              className={Styles.image}
            />
          )}
        </div>
        <div className={Styles.text}>{text2}</div>
        <div>
          {!!image4 && (
            <GatsbyImage
              image={image4}
              alt={image4Alt}
              className={Styles.image}
            />
          )}
        </div>
        <div>
          {!!image5 && (
            <GatsbyImage
              image={image5}
              alt={image5Alt}
              className={Styles.image}
            />
          )}
        </div>
        <div>
          {!!image6 && (
            <GatsbyImage
              image={image6}
              alt={image6Alt}
              className={Styles.image}
            />
          )}
        </div>
        <div className={Styles.text}>{text3}</div>
      </div>
    </section>
  )
}

export default ImageGrid

import React from 'react'

/**
 * Use Generate Random ID
 *
 * @returns a random string to be used as an element ID
 */
export const generateRandomId = () => {
  if (process.env.JEST_WORKER_ID !== undefined) return 'TEST'

  const [id] = React.useState(
    Math.random().toString(36).substring(2) + Date.now().toString(36)
  )
  return id
}

/**
 * Toggle Body Scroll
 *
 * Sets the body scroll to active or inactive
 *
 * @param toggle Boolean
 */
export const toggleBodyScroll = (toggle: boolean = true) => {
  if (!toggle) {
    document.documentElement.setAttribute(
      'style',
      'height: 100vh; overflow-x:hidden; overflow-y: hidden; position: relative;'
    )
  } else {
    document.documentElement.setAttribute('style', '')
  }
}

/**
 * Custom Scroll To
 *
 * Triggers a page scroll to an element ID
 *
 * @param selector String
 * @param animation String
 * @param offsetYMobile Number
 * @param offsetYDesktop Number
 */
export const customScrollTo = (
  selector: string | null = null,
  animation: 'smooth' | 'auto' = 'smooth',
  offsetYMobile: number = -70,
  offsetYDesktop: number = -145
) => {
  const element = document.querySelector(selector ?? '')
  if (!element) return

  const rect = element?.getBoundingClientRect() // relative position of element from current viewport position

  let offsetY: number
  if (window.innerWidth > 992) {
    offsetY = offsetYDesktop
  } else {
    offsetY = offsetYMobile // accounts for header + navbar size at desktop and mobile
  }

  const yPos = rect.top + window.scrollY + offsetY // window.scrollY: distance (pixels) page is currently scrolled down
  window.scrollTo({ top: yPos, behavior: animation })
}

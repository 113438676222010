import { graphql } from 'gatsby'
import React from 'react'
import { PrismicPageDataBodyTimeline } from 'src/typings/generated/graphql'

import Timeline, {
  TimelineContent,
  TimelineEvent,
} from 'src/components/organisms/brandEducation/timeline'

type Props = {
  slice: PrismicPageDataBodyTimeline
}

const TimelineSlice = ({ slice }: Props) => {
  const content: TimelineContent = {
    anchor: slice.primary.anchor ?? undefined,
    headline: slice.primary.headline ?? undefined,
    items: slice.items.map(
      item =>
        ({
          title: item.event_headline ?? undefined,
          description: item.event_description ?? undefined,
          year: item.event_year ?? undefined,
          icon: item.event_icon?.gatsbyImageData ?? undefined,
          iconAlt: item.event_icon?.alt ?? undefined,
          image: item.event_image?.gatsbyImageData ?? undefined,
          imageAlt: item.event_image?.alt ?? undefined,
        } as TimelineEvent)
    ),
  }

  return <Timeline content={content} />
}

export default TimelineSlice

export const TimelineFragment = graphql`
  fragment PageDataBodyTimeline on PrismicPageDataBodyTimeline {
    primary {
      anchor
      headline
    }
    items {
      event_headline
      event_description {
        richText
      }
      event_year
      event_image {
        gatsbyImageData
        alt
      }
      event_icon {
        gatsbyImageData
        alt
      }
    }
  }
`

import React from 'react'
import { PrismicNewsroomArticle } from 'src/typings/generated/graphql'
import { articleThumbnailImage } from 'src/utils/articlesHelper'

import * as GlobalStyles from 'src/styles/global.module.scss'

import * as Styles from './newsroomArticleCard.module.scss'

type Props = {
  article: PrismicNewsroomArticle
}

const NewsroomArticleCard = ({ article }: Props) => (
  <div className={Styles.newsroomArticleCard}>
    <div className={Styles.imageWrapper}>
      {articleThumbnailImage(article, Styles.image)}
    </div>
    <h4>{article.data.title?.text}</h4>
    <p className={Styles.outlet}>{article.data.outlet}</p>
    <p className={Styles.brief}>{article.data.body?.text}</p>
    <a
      href={article.data.url?.url ?? `/newsroom/${article.uid}`}
      target={article.data.url?.url ? '_blank' : '_self'}
      className={`${GlobalStyles.redBasicLink} ${Styles.readMore}`}
      rel="noreferrer"
    >
      Read More
    </a>
  </div>
)

export default NewsroomArticleCard

import React from 'react'
import { PrismicLinkType } from 'src/typings/generated/graphql'
// import CustomLink from 'src/utils/customLink'

import * as GlobalStyles from 'src/styles/global.module.scss'

import * as Styles from './doubleCta.module.scss'

export type DoubleCtaContent = {
  anchor?: string
  headlineText1?: string
  description1?: string
  buttonText1?: string
  ctaLink1?: PrismicLinkType
  headlineText2?: string
  description2?: string
  buttonText2?: string
  ctaLink2?: PrismicLinkType
}

type Props = {
  content: DoubleCtaContent
}

const DoubleCta = ({ content }: Props) => {
  const {
    anchor,
    headlineText1,
    description1,
    buttonText1,
    ctaLink1,
    headlineText2,
    description2,
    buttonText2,
    ctaLink2,
  } = content

  return (
    <section id={anchor} className={Styles.doubleCta}>
      <div className={Styles.ctaContainer}>
        <h3>{headlineText1}</h3>
        <p>{description1}</p>
        <div className={Styles.buttonContainer}>
          <a href={`${ctaLink1?.url}`} className={GlobalStyles.redGhostButton}>
            {buttonText1 ?? ''}
          </a>
        </div>
      </div>
      <div className={Styles.ctaContainer}>
        <h3>{headlineText2}</h3>
        <p>{description2}</p>
        <div className={Styles.buttonContainer}>
          <a href={`${ctaLink2?.url}`} className={GlobalStyles.redGhostButton}>
            {buttonText2}
          </a>
        </div>
      </div>
    </section>
  )
}

export default DoubleCta

import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import {
  MobileHeaderNavigationQuery,
  PrismicLinkType,
  PrismicNavigationDataPrimary,
  PrismicNavigationDataUtilities,
  PrismicNavigationItemDataType,
} from 'src/typings/generated/graphql'
import CustomLink from 'src/utils/customLink'
import FoamFingerOneLoveIcon from 'src/images/svgs/icons/retro/FoamFingerOneLoveIcon.svg'

import * as Styles from './mobileHeaderNavigation.module.scss'

interface Props {
  className?: string
  toggleNavigation: () => void
}

const MobileHeaderNavigation = ({ className, toggleNavigation }: Props) => {
  // Prismic Data Fetching
  const data: MobileHeaderNavigationQuery = useStaticQuery(graphql`
    query MobileHeaderNavigation {
      prismicSiteInformation {
        data {
          site_icon {
            gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1.5)
            alt
          }
          home_page {
            url
          }
        }
      }
      prismicNavigation {
        data {
          primary {
            navigation_item {
              document {
                ...NavigationItemFragment
              }
            }
          }
          utilities {
            navigation_item {
              document {
                ...NavigationItemFragment
              }
            }
          }
        }
      }
    }
  `)

  const icon = data.prismicSiteInformation?.data.site_icon
    ?.gatsbyImageData as IGatsbyImageData
  const iconAlt = data.prismicSiteInformation?.data.site_icon?.alt ?? ''
  const homepage = data.prismicSiteInformation?.data.home_page?.url ?? '/'
  const primaryNavigation = data.prismicNavigation?.data
    .primary as PrismicNavigationDataPrimary[]
  const utilityNavigation = data.prismicNavigation?.data
    .utilities as PrismicNavigationDataUtilities[]

  return (
    <div data-testid="mobile-header-navigation" className={className}>
      <div className={Styles.container}>
        <div className={Styles.background}>
          <FoamFingerOneLoveIcon />
        </div>
        <nav className={Styles.navigation}>
          <a
            className={Styles.homeLink}
            href={homepage}
            onClick={() => {
              toggleNavigation()
            }}
          >
            {!!icon && <GatsbyImage image={icon} alt={iconAlt} />}
          </a>
          <ul className={Styles.primary}>
            {primaryNavigation.map(
              (primaryItem: PrismicNavigationDataPrimary, index: number) => {
                const navItem = primaryItem.navigation_item?.document
                  ?.data as PrismicNavigationItemDataType
                const link = navItem.link as PrismicLinkType
                const key = `link-${index}`
                return (
                  <li key={key}>
                    <div
                      onClick={() => {
                        toggleNavigation()
                      }}
                      onKeyDown={e => {
                        if (e.code === 'Enter') toggleNavigation()
                      }}
                      role="button"
                      tabIndex={index}
                      data-testid="primary-nav-item"
                    >
                      <CustomLink link={link} className={Styles.primaryLink}>
                        {navItem.title?.text ?? ''}
                      </CustomLink>
                    </div>
                  </li>
                )
              }
            )}
          </ul>
          <ul className={Styles.utilities}>
            {utilityNavigation.map(
              (utilityItem: PrismicNavigationDataUtilities, index: number) => {
                const navItem = utilityItem.navigation_item?.document
                  ?.data as PrismicNavigationItemDataType
                const link = navItem.link as PrismicLinkType
                const key = `link-${index}`
                const navIcon = getImage(navItem.icon?.gatsbyImageData) ?? {
                  layout: 'constrained',
                  width: 0,
                  height: 0,
                  images: {},
                }
                return (
                  <li key={key}>
                    <div
                      onClick={() => {
                        toggleNavigation()
                      }}
                      onKeyPress={() => {
                        toggleNavigation()
                      }}
                      role="button"
                      tabIndex={index}
                      data-testid="utility-nav-item"
                    >
                      <CustomLink link={link} className={Styles.utilityLink}>
                        {!!navIcon && (
                          <GatsbyImage
                            image={navIcon}
                            alt={navItem.icon?.alt ?? ''}
                            className={Styles.icon}
                          />
                        )}
                        <span>{navItem.title?.text ?? ''}</span>
                        <div className={Styles.arrow} />
                      </CustomLink>
                    </div>
                  </li>
                )
              }
            )}
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default MobileHeaderNavigation

import React from 'react'
import { graphql } from 'gatsby'

import {
  PrismicPageDataBodyCrewCallout,
  PrismicStoreLocationDataBodyCrewCallout,
} from 'src/typings/generated/graphql'

import CrewCallout, {
  CrewCalloutContent,
} from 'src/components/organisms/crewConversion/crewCallout'

type Props = {
  slice:
    | PrismicPageDataBodyCrewCallout
    | PrismicStoreLocationDataBodyCrewCallout
}

const CrewCalloutSlice = ({ slice }: Props) => {
  const content: CrewCalloutContent = {
    anchor: slice.primary.anchor ?? undefined,
    crewName: slice.primary.crew_name ?? undefined,
    description: slice.primary.description ?? undefined,
    link: slice.primary.link ?? undefined,
    linkText: slice.primary.link_text ?? undefined,
    location: slice.primary.location ?? undefined,
    headline: slice.primary.headline ?? undefined,
    highlight: slice.primary.highlight ?? undefined,
    primaryImage1: slice.primary.image_1?.gatsbyImageData,
    primaryImage2: slice.primary.image_2?.gatsbyImageData,
    primaryImage3: slice.primary.image_3?.gatsbyImageData,
    primaryImage4: slice.primary.image_4?.gatsbyImageData,
    primaryImage1Alt: slice.primary.image_1?.alt ?? undefined,
    primaryImage2Alt: slice.primary.image_2?.alt ?? undefined,
    primaryImage3Alt: slice.primary.image_3?.alt ?? undefined,
    primaryImage4Alt: slice.primary.image_4?.alt ?? undefined,
  }
  return <CrewCallout content={content} />
}

export default CrewCalloutSlice

export const crewCalloutFragment = graphql`
  fragment PageDataBodyCrewCallout on PrismicPageDataBodyCrewCallout {
    primary {
      anchor
      headline
      highlight
      description {
        richText
      }
      link {
        ...BasicLinkFragment
      }
      link_text
      crew_name
      location
      image_1 {
        gatsbyImageData
        alt
      }
      image_2 {
        gatsbyImageData
        alt
      }
      image_3 {
        gatsbyImageData
        alt
      }
      image_4 {
        gatsbyImageData
        alt
      }
    }
  }
  fragment StoreLocationDataBodyCrewCallout on PrismicStoreLocationDataBodyCrewCallout {
    primary {
      anchor
      headline
      highlight
      description {
        richText
      }
      link {
        ...BasicLinkFragment
      }
      link_text
      crew_name
      location
      image_1 {
        gatsbyImageData
        alt
      }
      image_2 {
        gatsbyImageData
        alt
      }
      image_3 {
        gatsbyImageData
        alt
      }
      image_4 {
        gatsbyImageData
        alt
      }
    }
  }
`

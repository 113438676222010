import { graphql } from 'gatsby'
import React from 'react'

import { PrismicPageDataBodyLocator } from 'src/typings/generated/graphql'

import Locator, {
  LocatorContent,
} from 'src/components/organisms/restaurantLocator/locator'

type Props = {
  slice: PrismicPageDataBodyLocator
}

const LocatorSlice = ({ slice }: Props) => {
  const content: LocatorContent = {
    anchor: slice.primary.anchor ?? undefined,
    callout: slice.primary.callout ?? undefined,
    link: slice.primary.link ?? undefined,
    linkText: slice.primary.link_text ?? undefined,
  }

  return <Locator content={content} />
}

export default LocatorSlice

export const fragmentQuery = graphql`
  fragment PageDataBodyLocator on PrismicPageDataBodyLocator {
    primary {
      anchor
      callout
      link_text
      link {
        ...BasicLinkFragment
      }
    }
  }
`

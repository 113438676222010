// extracted by mini-css-extract-plugin
export var article = "{prismicArticle-url}-module--article--47e02";
export var banner = "{prismicArticle-url}-module--banner--21fd4";
export var body = "{prismicArticle-url}-module--body--ba855";
export var bodyContainer = "{prismicArticle-url}-module--body-container--0e624";
export var categoryPillEyebrow = "{prismicArticle-url}-module--category-pill-eyebrow--7d5de";
export var eyebrow = "{prismicArticle-url}-module--eyebrow--3ba7b";
export var headline = "{prismicArticle-url}-module--headline--70aca";
export var hero = "{prismicArticle-url}-module--hero--000f0";
export var image = "{prismicArticle-url}-module--image--69529";
export var imageOverlay = "{prismicArticle-url}-module--image-overlay--f5cb3";
export var imageWrapper = "{prismicArticle-url}-module--image-wrapper--9be60";
export var loader = "{prismicArticle-url}-module--loader--1c222";
export var social = "{prismicArticle-url}-module--social--a9fa4";
export var wave = "{prismicArticle-url}-module--wave--89b3e";
// extracted by mini-css-extract-plugin
export var blueBackground = "cardsCta-module--blue-background--b4021";
export var button = "cardsCta-module--button--28ca1";
export var cardsCta = "cardsCta-module--cards-cta--26af4";
export var darkGreyBackground = "cardsCta-module--dark-grey-background--9c801";
export var description = "cardsCta-module--description--94757";
export var headline = "cardsCta-module--headline--889e2";
export var primaryCopy = "cardsCta-module--primary-copy--3b628";
export var retroIconsWrapper = "cardsCta-module--retro-icons-wrapper--90d42";
export var secondaryCopy = "cardsCta-module--secondary-copy--b17ff";
export var secondaryText = "cardsCta-module--secondary-text--a8c9e";
export var whiteRightArrow = "cardsCta-module--whiteRightArrow--7c7fd";
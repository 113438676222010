import * as React from 'react'
import BertholdTitle from 'src/components/atoms/bertholdTitle'

import * as GlobalStyles from 'src/styles/global.module.scss'
import CustomLink from 'src/utils/customLink'
import { PrismicLinkType } from 'src/typings/generated/graphql'
import LogoRepeat from 'src/images/svgs/LogoRepeat.svg'
import { generateRandomId } from 'src/utils/domHelper'
import * as Styles from './logoCta.module.scss'

export enum LogoCTABackgroundColor {
  red = 'red',
  black = 'black',
}

export type LogoCTAContent = {
  anchor?: string
  buttonLink?: PrismicLinkType
  buttonText?: string
  headline?: string
  backgroundColor?: string
}
interface Props {
  content: LogoCTAContent
}

const LogoCTA = ({ content }: Props) => {
  const {
    anchor = generateRandomId(),
    buttonLink,
    buttonText = '',
    headline = '',
    backgroundColor = LogoCTABackgroundColor.red,
  } = content

  let sectionClass = Styles.logoCta

  const backgroundColorFormatted = backgroundColor.toLowerCase()
  if (backgroundColorFormatted === LogoCTABackgroundColor.black) {
    sectionClass += ` ${Styles.blackBackground}`
  }

  return (
    <section id={anchor} className={sectionClass}>
      <div className={Styles.copy}>
        <div className={Styles.headline}>
          {BertholdTitle({ text: headline })}
        </div>
        <div className={Styles.button}>
          {buttonLink?.url && (
            <CustomLink
              className={GlobalStyles.whiteGhostButtonResponsive}
              link={buttonLink}
            >
              {buttonText}
            </CustomLink>
          )}
        </div>
      </div>
      <div className={Styles.logo}>
        <LogoRepeat />
      </div>
    </section>
  )
}

export default LogoCTA

// extracted by mini-css-extract-plugin
export var active = "artOfAPerfectMeal-module--active--13011";
export var activeIngredientIndex = "artOfAPerfectMeal-module--active-ingredient-index--1282a";
export var arrow = "artOfAPerfectMeal-module--arrow--f4a9f";
export var artOfAPerfectMeal = "artOfAPerfectMeal-module--art-of-a-perfect-meal--95fa8";
export var content = "artOfAPerfectMeal-module--content--b1c0f";
export var contentWrapper = "artOfAPerfectMeal-module--content-wrapper--92ce0";
export var detailsButton = "artOfAPerfectMeal-module--details-button--4a2f8";
export var fadeIn = "artOfAPerfectMeal-module--fadeIn--715ce";
export var headline = "artOfAPerfectMeal-module--headline--98685";
export var ingredient1 = "artOfAPerfectMeal-module--ingredient-1--50d63";
export var ingredient2 = "artOfAPerfectMeal-module--ingredient-2--5dd93";
export var ingredient3 = "artOfAPerfectMeal-module--ingredient-3--09388";
export var ingredient4 = "artOfAPerfectMeal-module--ingredient-4--d9cea";
export var ingredient5 = "artOfAPerfectMeal-module--ingredient-5--abd10";
export var ingredient6 = "artOfAPerfectMeal-module--ingredient-6--733e5";
export var ingredientButton = "artOfAPerfectMeal-module--ingredient-button--316dc";
export var ingredientDetails = "artOfAPerfectMeal-module--ingredient-details--4785e";
export var ingredientDetailsWrapper = "artOfAPerfectMeal-module--ingredient-details-wrapper--85049";
export var mealImageWrapper = "artOfAPerfectMeal-module--meal-image-wrapper--d0564";
export var nextButton = "artOfAPerfectMeal-module--next-button--64e85";
export var previousButton = "artOfAPerfectMeal-module--previous-button--2b4ed";
export var retroIconsWrapper = "artOfAPerfectMeal-module--retro-icons-wrapper--cde76";
export var selectDisable = "artOfAPerfectMeal-module--select-disable--9a3a2";
export var title = "artOfAPerfectMeal-module--title--b5b6c";
import { PrismicStoreLocation } from 'src/typings/generated/graphql'
import {
  AddressType,
  Client as GoogleMapsClient,
  LatLng,
} from '@googlemaps/google-maps-services-js'
import { formatHoursForDisplay, formatHoursForSchema } from './hoursFormatter'

export enum StoreLocationStage {
  futureStore,
  comingSoon,
  open,
}

export const getStoreLocationStage = (
  location: PrismicStoreLocation
): StoreLocationStage => {
  const storeBirthdateValue =
    location.data.external_location_data?.birthdate ?? null

  if (!storeBirthdateValue) return StoreLocationStage.futureStore

  const storeBirthdate = new Date(storeBirthdateValue)

  if (storeBirthdate > new Date()) return StoreLocationStage.comingSoon

  return StoreLocationStage.open
}

export const getStoreLocationDisplayName = (location: PrismicStoreLocation) => {
  const locationStage = getStoreLocationStage(location)
  let name =
    location.data.override_external_display_name ??
    location.data.external_location_data?.displayName ??
    'Location'
  if (!name) {
    if (locationStage === StoreLocationStage.comingSoon)
      name = "Raising Cane's Coming Soon!"
    else if (locationStage === StoreLocationStage.futureStore)
      name = 'Future Raising Canes'
  }
  return name
}

export const getStoreLocationDisplayNickname = (
  location: PrismicStoreLocation
) => {
  const locationStage = getStoreLocationStage(location)
  let name =
    location.data.override_external_nickname ??
    location.data.external_location_data?.nickname ??
    ''
  if (!name) {
    if (locationStage === StoreLocationStage.comingSoon)
      name = "Raising Cane's Coming Soon!"
    else if (locationStage === StoreLocationStage.futureStore)
      name = 'Future Raising Canes'
  }
  return name
}

export const getStoreLocationDisplayAddress = (
  location: PrismicStoreLocation
) => {
  const addr1 =
    location.data.override_external_address_1 ??
    location.data.external_location_data?.address1 ??
    ''
  const addr2 =
    location.data.override_external_address_2 ??
    location.data.external_location_data?.address2 ??
    ''
  const city =
    location.data.override_external_city ??
    location.data.external_location_data?.city ??
    ''
  const state =
    location.data.override_external_state ??
    location.data.external_location_data?.state ??
    ''
  const zip =
    location.data.override_external_zip_code ??
    location.data.external_location_data?.postal_code ??
    ''

  const address = !addr1 ? '' : `${addr1} ${addr2}`
  const cityState = !city ? '' : `${city}, ${state} ${zip}`

  if (!address) {
    return cityState
  }

  return `${address}\n${cityState}`
}

export const getStoreLocationDisplayHours = (
  location: PrismicStoreLocation
) => {
  const locationStage = getStoreLocationStage(location)
  if (
    locationStage === StoreLocationStage.futureStore ||
    locationStage === StoreLocationStage.comingSoon
  )
    return ''

  const hours =
    location.data.override_external_hours ??
    location.data.external_location_data?.hours ??
    ''

  return formatHoursForDisplay(hours)
}

export const getStoreLocationSchemaHours = (location: PrismicStoreLocation) => {
  const locationStage = getStoreLocationStage(location)
  if (
    locationStage === StoreLocationStage.futureStore ||
    locationStage === StoreLocationStage.comingSoon
  )
    return ''

  const hours =
    location.data.override_external_hours ??
    location.data.external_location_data?.hours ??
    ''
  if (!hours) return ''

  return formatHoursForSchema(hours)
}

export const getStoreLocationDisplayOpeningDate = (
  location: PrismicStoreLocation
) => {
  const dateStr = location.data.external_location_data?.birthdate ?? ''
  if (!dateStr) return dateStr

  const openingDate = new Date(dateStr)
  return `${
    openingDate.getUTCMonth() + 1
  }.${openingDate.getUTCDate()}.${openingDate
    .getUTCFullYear()
    .toString()
    .slice(2, 4)}`
}

export const getStoreLocationDisplayPhone = (location: PrismicStoreLocation) =>
  location.data.override_external_phone ??
  location.data.external_location_data?.phoneNumber ??
  ''

export const reverseGeocodeCityState = async (latlng: LatLng) => {
  const googleMapsClient = new GoogleMapsClient()
  return googleMapsClient
    .reverseGeocode({
      params: { latlng, key: process.env.GOOGLE_MAPS_KEY ?? '' },
    })
    .then(res => {
      const cityStateResults = res.data.results.filter(result =>
        result.types.includes(AddressType.postal_code)
      )
      if (cityStateResults.length < 1) return ''

      return cityStateResults[0].formatted_address
        .split(', ')
        .slice(0, 2)
        .join(', ')
    })
    .catch(err => {
      // eslint-disable-next-line no-console
      console.error(err)
      return ''
    })
}

const deg2rad = (deg: number) => deg * (Math.PI / 180)

export const getDistanceFromLatLonInKm = (
  lat1: any,
  lon1: any,
  lat2: any,
  lon2: any
) => {
  const R = 6371 // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1)
  const dLon = deg2rad(lon2 - lon1)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = R * c
  return d // distance returned
}

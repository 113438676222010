import React from 'react'
import { PrismicLinkType } from 'src/typings/generated/graphql'
import CustomLink from 'src/utils/customLink'

import * as GlobalStyles from 'src/styles/global.module.scss'

import * as Styles from './bannerCta.module.scss'

export type BannerCtaContent = {
  anchor?: string
  details?: string
  link?: PrismicLinkType
  linkText?: string
}

type Props = {
  content: BannerCtaContent
}

const BannerCta = ({ content }: Props) => {
  const { anchor, details, link, linkText } = content

  return (
    <section id={anchor} className={Styles.bannerCta}>
      <div className={Styles.content}>
        <p className={Styles.copy}>{details}</p>
        {link?.url && (
          <CustomLink
            className={`${GlobalStyles.whiteButtonResponsive} ${GlobalStyles.small} ${Styles.link}`}
            link={link}
            ariaLabel={`${linkText} ${details}`}
          >
            {linkText ?? ''}
          </CustomLink>
        )}
      </div>
    </section>
  )
}

export default BannerCta

import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React from 'react'
import BertholdTitle from 'src/components/atoms/bertholdTitle'
import { generateRandomId } from 'src/utils/domHelper'
import * as Styles from './minimalHero.module.scss'

enum MinimalHeroTitleSize {
  small = 'small',
  large = 'large',
}

export type MinimalHeroContent = {
  anchor?: string
  title?: string
  titleSize?: string
  titleAsH1?: boolean
  image?: IGatsbyImageData
  imageAlt?: string
  mobileImage?: IGatsbyImageData
  mobileImageAlt?: string
}

type Props = {
  content: MinimalHeroContent
}

const MinimalHero = ({ content }: Props) => {
  const {
    anchor = generateRandomId(),
    title = '',
    titleSize = MinimalHeroTitleSize.large,
    titleAsH1 = true,
    image,
    imageAlt = '',
    mobileImage,
    mobileImageAlt = '',
  } = content

  let desktopImageClass = Styles.image
  if (!mobileImage) desktopImageClass += ` ${Styles.desktopImage}`

  let titleClass = Styles.title
  if (titleSize.toLowerCase() === MinimalHeroTitleSize.small)
    titleClass += ` ${Styles.small}`

  return (
    <section id={anchor} className={Styles.minimalHero}>
      <div className={Styles.backgroundImageWrapper}>
        {!!image && (
          <GatsbyImage
            className={desktopImageClass}
            image={image}
            alt={imageAlt}
          />
        )}
        {!!mobileImage && (
          <GatsbyImage
            className={`${Styles.image} ${Styles.mobileImage}`}
            image={mobileImage}
            alt={mobileImageAlt}
          />
        )}
      </div>
      <div className={Styles.overlay} />
      <div className={Styles.content}>
        {titleAsH1 && (
          <h1 className={titleClass}>{BertholdTitle({ text: title })}</h1>
        )}
        {!titleAsH1 && (
          <h2 className={titleClass}>{BertholdTitle({ text: title })}</h2>
        )}
      </div>
    </section>
  )
}

export default MinimalHero

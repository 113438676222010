import { graphql, useStaticQuery } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

import FoamFingerHeartIcon from 'src/images/svgs/icons/retro/FoamFingerHeartIcon.svg'
import FoamFingerOneLoveIcon from 'src/images/svgs/icons/retro/FoamFingerOneLoveIcon.svg'
import OneLoveIcon from 'src/images/svgs/icons/retro/OneLoveIcon.svg'
import CaneDogIcon from 'src/images/svgs/icons/retro/CaneDogIcon.svg'
import GlobeIcon from 'src/images/svgs/icons/retro/GlobeIcon.svg'
import HeartsIcon from 'src/images/svgs/icons/retro/HeartsIcon.svg'
import {
  PrismicStoreLocation,
  UpcomingRestaurantsQuery,
} from 'src/typings/generated/graphql'

import {
  getStoreLocationDisplayAddress,
  getStoreLocationDisplayName,
  getStoreLocationDisplayOpeningDate,
} from 'src/utils/storeLocationHelper'
import { generateRandomId } from 'src/utils/domHelper'
import * as GlobalStyles from 'src/styles/global.module.scss'
import * as Styles from './upcomingRestaurants.module.scss'

enum UpcomingRestaurantsStyle {
  dark = 'dark',
  light = 'light',
}

export type UpcomingRestaurantsContent = {
  anchor?: string
  eyebrowText?: string
  headline?: string
  style?: string
  image1?: IGatsbyImageData
  image1Alt?: string
  image2?: IGatsbyImageData
  image2Alt?: string
  image3?: IGatsbyImageData
  image3Alt?: string
  image4?: IGatsbyImageData
  image4Alt?: string
  image5?: IGatsbyImageData
  image5Alt?: string
}

type Props = {
  content: UpcomingRestaurantsContent
}

const UpcomingRestaurants = ({ content }: Props) => {
  const {
    anchor = generateRandomId(),
    eyebrowText = '',
    headline = '',
    style = UpcomingRestaurantsStyle.dark,
    image1,
    image1Alt = '',
    image2,
    image2Alt = '',
    image3,
    image3Alt = '',
    image4,
    image4Alt = '',
    image5,
    image5Alt = '',
  } = content

  const styleFormatted = style.toLowerCase()
  let contentWrapperClass = Styles.contentWrapper
  if (styleFormatted === UpcomingRestaurantsStyle.light)
    contentWrapperClass += ` ${Styles.light}`

  const restaurantData: UpcomingRestaurantsQuery = useStaticQuery(graphql`
    query UpcomingRestaurants {
      allPrismicStoreLocation(
        filter: {
          data: { external_location_data: { birthdate: { gte: "2022-01-01" } } }
        }
        sort: { data: { external_location_data: { birthdate: ASC } } }
      ) {
        nodes {
          uid
          data {
            external_location_data {
              locationId
              name
              displayName
              nickname
              phoneNumber
              birthdate
              address1
              address2
              city
              state
              postal_code
              hours
              franchise
            }
            override_external_display_name
            override_external_address_1
            override_external_address_2
            override_external_city
            override_external_state
            override_external_zip_code
          }
        }
      }
    }
  `)

  const [locations, setLocations] = useState<PrismicStoreLocation[]>([])
  useEffect(() => {
    const filteredLocations = restaurantData.allPrismicStoreLocation.nodes
      .filter(restaurant => {
        if (restaurant.data.external_location_data?.birthdate) {
          const openingDate = new Date(
            restaurant.data.external_location_data.birthdate
          )
          return openingDate > new Date()
        }

        return true
      })
      .map(restaurant => restaurant as PrismicStoreLocation)
      .splice(0, 8)
    setLocations(filteredLocations)
  }, [])

  if (locations.length === 0) return null

  return (
    <section id={anchor} className={Styles.upcomingRestaurants}>
      <div className={contentWrapperClass}>
        <div className={Styles.retroIcons}>
          <FoamFingerHeartIcon />
          <FoamFingerOneLoveIcon />
          <OneLoveIcon />
          <CaneDogIcon />
          <GlobeIcon />
          <HeartsIcon />
        </div>
        <div className={Styles.content}>
          <div className={Styles.images}>
            <div className={Styles.columnOne}>
              {!!image1 && (
                <GatsbyImage
                  className={Styles.image}
                  image={image1}
                  alt={image1Alt}
                />
              )}
              {!!image2 && (
                <GatsbyImage
                  className={Styles.image}
                  image={image2}
                  alt={image2Alt}
                />
              )}
            </div>
            <div className={Styles.columnTwo}>
              {!!image3 && (
                <GatsbyImage
                  className={Styles.image}
                  image={image3}
                  alt={image3Alt}
                />
              )}
              {!!image4 && (
                <GatsbyImage
                  className={Styles.image}
                  image={image4}
                  alt={image4Alt}
                />
              )}
              {!!image5 && (
                <GatsbyImage
                  className={Styles.image}
                  image={image5}
                  alt={image5Alt}
                />
              )}
            </div>
          </div>
          <div className={Styles.schedule}>
            <p className={Styles.eyebrow}>{eyebrowText}</p>
            <h4 className={Styles.headline}>{headline}</h4>
            <table className={Styles.restaurants}>
              <tr className={GlobalStyles.srOnly}>
                <th>Opening Date</th>
                <th>Location Details</th>
              </tr>
              {locations &&
                locations.map(location => {
                  const openingDate =
                    getStoreLocationDisplayOpeningDate(location)
                  const name = getStoreLocationDisplayName(location)
                  const address = getStoreLocationDisplayAddress(location)
                  return (
                    <tr key={name} className={Styles.restaurant}>
                      <td className={Styles.openingDate}>{openingDate}</td>
                      <td className={Styles.details}>
                        <p className={Styles.name}>{name}</p>
                        <p className={Styles.address}>{address}</p>
                      </td>
                    </tr>
                  )
                })}
            </table>
          </div>
        </div>
      </div>
    </section>
  )
}

export default UpcomingRestaurants

/* eslint-disable import/no-import-module-exports */
import { LinkResolverFunction } from '@prismicio/helpers'
import {
  FilledLinkToDocumentField,
  FilledLinkToMediaField,
  FilledLinkToWebField,
} from '@prismicio/types'
import {
  Maybe,
  PrismicArticle,
  PrismicLinkType,
  PrismicNewsroomArticle,
  PrismicPage,
} from 'src/typings/generated/graphql'

type LinkResolverResponse = {
  linkPath: string
  external: boolean
}

export const customLinkResolver = (
  link:
    | PrismicLinkType
    | FilledLinkToDocumentField<string, string, never>
    | FilledLinkToWebField
    | FilledLinkToMediaField
) => {
  const response: LinkResolverResponse = {
    linkPath: '/',
    external: false,
  }
  // Handle Document Links
  if (link.link_type === 'Document') {
    if ('document' in link) {
      // Page Documents
      if (link.type === 'page') {
        const page = link.document as Maybe<PrismicPage>
        const redirectUrl = page?.data.redirect_url?.url

        if (redirectUrl) {
          response.linkPath = redirectUrl
          response.external = true
        } else response.linkPath = `/${link.uid ?? link.slug}/`
      }

      // Article Documents
      if (link.type === 'article') {
        const article = link.document as Maybe<PrismicArticle>
        const redirectUrl = article?.data.url?.url

        if (redirectUrl) {
          response.linkPath = redirectUrl
          response.external = true
        } else response.linkPath = `/news/${link.uid ?? link.slug}/`
      }

      // Newsroom Article Documents
      if (link.type === 'newsroom_article') {
        const article = link.document as Maybe<PrismicNewsroomArticle>
        const redirectUrl = article?.data.url?.url

        if (redirectUrl) {
          response.linkPath = redirectUrl
          response.external = true
        } else response.linkPath = `/newsroom/${link.uid ?? link.slug}/`
      }

      // Store Location Documents
      if (link.type === 'store_location')
        response.linkPath = `/locations/${link.uid ?? link.slug}/`

      // Menu Item Documents
      if (link.type === 'menu_item')
        response.linkPath = 'https://order.raisingcanes.com'
    } else {
      /**
       * DEV NOTE
       *
       * Internal links coming through RichText fields
       * do not contain the Prismic `document` key-value
       * pair. The `document` value gets used in the above
       * if-statement to help determine if we need to
       * navigate to the document page or a redirect URL
       * (only applicable to `Page` and `Article` documents)
       * Instead, we can rely on the Page and Article
       * templates to handle the redirect for internal RichText links
       */

      // Page Documents
      if (link.type === 'page') response.linkPath = `/${link.uid ?? link.slug}/`

      // Article Documents
      if (link.type === 'article')
        response.linkPath = `/news/${link.uid ?? link.slug}/`

      if (link.type === 'newsroom_article')
        response.linkPath = `/newsroom/${link.uid ?? link.slug}/`

      // Store Location Documents
      if (link.type === 'store_location')
        response.linkPath = `/locations/${link.uid ?? link.slug}/`

      // Menu Item Documents
      if (link.type === 'menu_item')
        response.linkPath = 'https://order.raisingcanes.com'
    }
  } else {
    response.linkPath = link.url ?? '/'
    response.external = true
  }

  return response
}

export const prismicLinkResolver: LinkResolverFunction<string> | any = (
  linkToDocumentField: FilledLinkToDocumentField
) => {
  if (linkToDocumentField?.type === 'page')
    return `/${linkToDocumentField.uid ?? linkToDocumentField.slug}/`
  if (linkToDocumentField?.type === 'store_location')
    return `/locations/${linkToDocumentField.uid ?? linkToDocumentField.slug}/`
  if (linkToDocumentField?.type === 'article')
    return `/news/${linkToDocumentField.uid ?? linkToDocumentField.slug}/`
  if (linkToDocumentField.type === 'newsroom_article')
    return `/newsroom/${linkToDocumentField.uid ?? linkToDocumentField.slug}/`
  if (linkToDocumentField?.type === 'menu_item') {
    return `/menu/${linkToDocumentField.uid ?? linkToDocumentField.slug}/`
  }
  return '/'
}

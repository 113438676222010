import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

import {
  FooterQuery,
  PrismicGlobalContentDataType,
  PrismicLinkType,
  PrismicNavigationDataNavigationGroups,
  PrismicNavigationGroup,
  PrismicSiteInformationDataSocialMediaPlatforms,
  PrismicSiteInformationDataType,
  PrismicStructuredTextType,
} from 'src/typings/generated/graphql'
import CustomLink from 'src/utils/customLink'
import { PrismicRichText } from '@prismicio/react'
import * as GlobalStyles from 'src/styles/global.module.scss'

import BertholdTitle from '../atoms/bertholdTitle'
import FooterNavigationGroup from './footerNavigationGroup'

import * as Styles from './footer.module.scss'

const Footer = () => {
  const data: FooterQuery = useStaticQuery(graphql`
    query Footer {
      prismicGlobalContent {
        data {
          footer_cta_1_title
          footer_cta_1_description {
            richText
          }
          footer_cta_1_link {
            ...BasicLinkFragment
          }
          footer_cta_1_link_text
          footer_cta_1_image {
            gatsbyImageData
          }
          footer_cta_2_title
          footer_cta_2_description {
            richText
          }
          footer_cta_2_link {
            ...BasicLinkFragment
          }
          footer_cta_2_link_text
          footer_cta_2_image {
            gatsbyImageData
          }
          footer_legal {
            legal_button_text
            legal_button_link {
              ...BasicLinkFragment
            }
          }
        }
      }
      prismicSiteInformation {
        data {
          site_icon {
            gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1.5)
            alt
          }
          social_media_platforms {
            link {
              ...BasicLinkFragment
            }
            name
            white_icon {
              gatsbyImageData(layout: CONSTRAINED, height: 20)
              alt
            }
          }
          home_page {
            url
          }
        }
      }
      prismicNavigation {
        data {
          navigation_groups {
            navigation_group {
              document {
                ...NavigationGroupFragment
              }
            }
          }
        }
      }
    }
  `)

  const footerContent = data.prismicGlobalContent
    ?.data as PrismicGlobalContentDataType
  const siteInformation = data.prismicSiteInformation
    ?.data as PrismicSiteInformationDataType

  const icon = siteInformation?.site_icon?.gatsbyImageData as IGatsbyImageData
  const iconAlt = siteInformation.site_icon?.alt ?? ''
  const socialMedia =
    siteInformation.social_media_platforms as PrismicSiteInformationDataSocialMediaPlatforms[]
  const homepage = siteInformation?.home_page?.url ?? '/'

  const navigationGroups =
    (data.prismicNavigation?.data
      .navigation_groups as PrismicNavigationDataNavigationGroups[]) ?? []

  const displayCtas =
    footerContent.footer_cta_1_title &&
    footerContent.footer_cta_1_link &&
    footerContent.footer_cta_2_title &&
    footerContent.footer_cta_2_link

  // Render

  const renderCTA = (
    title: string,
    description: PrismicStructuredTextType | undefined,
    link: PrismicLinkType | undefined,
    linkText: string,
    image: IGatsbyImageData,
    imageAlt: string
  ) => {
    if (!displayCtas) return null

    return (
      <div className={Styles.footerCta}>
        <div className={Styles.content}>
          <h2 className={Styles.title}>{BertholdTitle({ text: title })}</h2>
          <PrismicRichText
            field={description?.richText}
            components={{
              hyperlink: ({ node, children }: any) =>
                CustomLink({ link: node.data, children }),
            }}
          />
          <div className={Styles.linkWrapper}>
            <CustomLink
              link={link}
              className={`${GlobalStyles.whiteGhostButton} ${Styles.link}`}
            >
              {linkText ?? ''}
            </CustomLink>
          </div>
        </div>
        {!!image && (
          <div className={Styles.image}>
            <GatsbyImage image={image} alt={imageAlt} />
          </div>
        )}
      </div>
    )
  }

  return (
    <footer data-testid="footer" className={Styles.footer}>
      <div className={Styles.upperContainer}>
        <div className={Styles.mainContainer}>
          <div className={`${Styles.logoContainer} ${Styles.selectDisable}`}>
            <Link to={homepage}>
              {!!icon && (
                <GatsbyImage
                  className={Styles.logo}
                  data-testid="canes-logo"
                  image={icon}
                  alt={iconAlt}
                />
              )}
            </Link>
          </div>
          <ul
            data-testid="social-media-container"
            className={`${Styles.socialMediaContainer} ${Styles.selectDisable}`}
          >
            {socialMedia.map((socialMediaItem: any, index: number) => {
              const key = `socialMedia-${index}`
              return (
                <li className={Styles.socialMediaLink} key={key}>
                  <CustomLink link={socialMediaItem.link}>
                    <GatsbyImage
                      data-testid="social-media-image"
                      image={socialMediaItem.white_icon.gatsbyImageData}
                      alt={socialMediaItem.white_icon.alt ?? ''}
                    />
                  </CustomLink>
                </li>
              )
            })}
          </ul>
        </div>
        <div className={Styles.divider} />
        <div className={Styles.navContainer}>
          {navigationGroups.map(
            (
              navigationGroup: PrismicNavigationDataNavigationGroups,
              i: number
            ) => {
              const key = `navigation-group-${i}`
              const group = navigationGroup.navigation_group
                ?.document as PrismicNavigationGroup

              return <FooterNavigationGroup key={key} group={group} />
            }
          )}
        </div>
      </div>
      <div className={Styles.lowerContainer}>
        {renderCTA(
          footerContent.footer_cta_1_title ?? '',
          footerContent.footer_cta_1_description ?? undefined,
          footerContent.footer_cta_1_link ?? undefined,
          footerContent.footer_cta_1_link_text ?? '',
          footerContent.footer_cta_1_image?.gatsbyImageData,
          footerContent.footer_cta_1_image?.alt ?? ''
        )}
        {renderCTA(
          footerContent.footer_cta_2_title ?? '',
          footerContent.footer_cta_2_description ?? undefined,
          footerContent.footer_cta_2_link ?? undefined,
          footerContent.footer_cta_2_link_text ?? '',
          footerContent.footer_cta_2_image?.gatsbyImageData ?? '',
          footerContent.footer_cta_2_image?.alt ?? ''
        )}
      </div>
      <div data-testid="copyright-item" className={Styles.copyrightContainer}>
        {footerContent.footer_legal &&
          footerContent.footer_legal.map((link, i) => {
            if (!link) return null
            const key = `legal-link-${i}`
            return (
              <div className={Styles.copyrightLinkWrapper} key={key}>
                <CustomLink
                  className={Styles.copyrightLinkText}
                  link={link.legal_button_link}
                >
                  {link.legal_button_text ?? ''}
                </CustomLink>
              </div>
            )
          })}
        <div className={Styles.copyrightLinkWrapper}>
          <a
            key="temp-cookies-link"
            className={Styles.copyrightLinkText}
            href="#show-cookie-preferences"
          >
            Cookie Preferences
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer

import React from 'react'

import * as Styles from './locationPin.module.scss'

type Props = {
  lat: number
  lng: number
  count?: string | number
  highlighted?: boolean
  selected?: boolean
  interactive: boolean
  ariaLabel?: string
  onClick?: Function
  onKeyDown?: Function
  onMouseEnter?: Function
  onMouseLeave?: Function
}

const LocationPin = ({
  lat,
  lng,
  count = 1,
  highlighted = false,
  selected = false,
  interactive = true,
  ariaLabel = 'Location Pin',
  onClick = undefined,
  onKeyDown = undefined,
  onMouseEnter = undefined,
  onMouseLeave = undefined,
}: Props) => {
  let pinButtonClass = Styles.locationPinWrapper
  if (selected) pinButtonClass += ` ${Styles.selected}`
  else if (highlighted) pinButtonClass += ` ${Styles.highlighted}`

  if (!interactive) pinButtonClass += ` ${Styles.disabled}`

  return (
    <button
      className={pinButtonClass}
      lat={lat}
      lng={lng}
      type="button"
      aria-label={ariaLabel}
      aria-disabled={!interactive}
      onClick={e => {
        if (interactive && onClick) onClick(e)
      }}
      onKeyDown={e => {
        if (interactive && onKeyDown) onKeyDown(e)
      }}
      onMouseEnter={e => {
        if (interactive && onMouseEnter) onMouseEnter(e)
      }}
      onMouseLeave={e => {
        if (interactive && onMouseLeave) onMouseLeave(e)
      }}
    >
      <div className={Styles.pin}>{count}</div>
    </button>
  )
}

export default LocationPin

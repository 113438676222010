import { graphql } from 'gatsby'
import React from 'react'
import StatGrid from 'src/components/organisms/slidersAndGrids/statGrid'

type Props = {
  slice: any
}

type StatGridContent = {
  anchor?: string
  heading?: string
  backgroundImage?: string
  stats?: any[]
}

const StatGridSlice = ({ slice }: Props) => {
  const content: StatGridContent = {
    anchor: slice.primary.anchor ?? '',
    heading: slice.primary.stat_headline?.text ?? '',
    backgroundImage: slice.primary.background_image?.url ?? '',
    stats: slice.items.map((item: any) => ({
      stat_headline: item.stat_headline?.text ?? '',
      stat_description: item.stat_description?.text ?? '',
    })),
  }

  return <StatGrid content={content} />
}

export default StatGridSlice

export const statGridFragment = graphql`
  fragment PageDataBodyStatGrid on PrismicPageDataBodyStatGrid {
    primary {
      anchor
      stat_headline {
        text
      }
      background_image {
        url
      }
    }
    items {
      stat_description {
        text
      }
      stat_headline {
        text
      }
    }
  }
`

import { PrismicRichText } from '@prismicio/react'
import { graphql, useStaticQuery } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { SiteBannerQuery } from 'src/typings/generated/graphql'
import CustomLink from 'src/utils/customLink'
import * as GlobalStyles from 'src/styles/global.module.scss'
import * as Styles from './siteBanner.module.scss'

const SiteBanner = () => {
  const siteBannerQuery: SiteBannerQuery = useStaticQuery(graphql`
    query SiteBanner {
      prismicGlobalContent {
        data {
          banner_message {
            richText
          }
          banner_start_date
          banner_end_date
        }
      }
    }
  `)

  const message =
    siteBannerQuery.prismicGlobalContent?.data.banner_message?.richText
  const startDateTime = new Date(
    siteBannerQuery.prismicGlobalContent?.data.banner_start_date
  )
  const endDateTime = new Date(
    siteBannerQuery.prismicGlobalContent?.data.banner_end_date
  )

  let sessionStorage: Storage
  const bannerDismissedKey = 'bannerDismissed'
  let bannerDismissed: string | null
  if (typeof window !== 'undefined') {
    sessionStorage = window.sessionStorage
    bannerDismissed = sessionStorage.getItem(bannerDismissedKey)
  }

  const [displayBanner, setDisplayBanner] = useState(false)

  useEffect(() => {
    const currentTime = new Date()
    if (
      startDateTime < currentTime &&
      currentTime < endDateTime &&
      !bannerDismissed
    )
      setDisplayBanner(true)
  }, [displayBanner])

  // Handlers
  const dismissBanner = () => {
    if (displayBanner) {
      setDisplayBanner(false)
      sessionStorage.setItem(bannerDismissedKey, 'true')
    }
  }

  // Render
  let siteBannerClass = Styles.siteBanner
  if (!displayBanner) siteBannerClass += ` ${Styles.hide}`

  return (
    <div className={siteBannerClass}>
      <div />
      <div className={Styles.message}>
        <PrismicRichText
          field={message}
          components={{
            hyperlink: ({ node, children }: any) =>
              CustomLink({ link: node.data, children }),
          }}
        />
      </div>
      <div className={GlobalStyles.dismissButton}>
        <div className={GlobalStyles.line} />
        <div className={GlobalStyles.line} />
        <button
          type="button"
          onClick={dismissBanner}
          aria-label="Dismiss Banner"
        >
          <span>Dismiss Banner</span>
        </button>
      </div>
    </div>
  )
}

export default SiteBanner

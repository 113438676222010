import React from 'react'
import { graphql } from 'gatsby'

import { PrismicPageDataBodyFaqCta } from 'src/typings/generated/graphql'

import FaqCta, {
  FaqCtaContent,
  FaqCtaItem,
} from 'src/components/organisms/ctas/faqCta'

type Props = {
  slice: PrismicPageDataBodyFaqCta
}

const FaqCtaSlice = ({ slice }: Props) => {
  const content: FaqCtaContent = {
    anchor: slice.primary.anchor ?? undefined,
    headline: slice.primary.headline ?? undefined,
    image: slice.primary.image?.gatsbyImageData ?? undefined,
    imageAlt: slice.primary.image?.alt ?? undefined,
    link: slice.primary.link ?? undefined,
    linkText: slice.primary.link_text ?? undefined,
    items: slice.items.map(
      item =>
        ({
          link: item.document_link ?? undefined,
          linkIcon: item.document_link_icon?.gatsbyImageData ?? undefined,
          linkIconAlt: item.document_link_icon?.alt ?? undefined,
          linkText: item.document_link_text ?? undefined,
        } as FaqCtaItem)
    ),
  }

  return <FaqCta content={content} />
}

export default FaqCtaSlice

export const FaqCtaFragment = graphql`
  fragment PageDataBodyFaqCta on PrismicPageDataBodyFaqCta {
    primary {
      anchor
      headline
      link_text
      link {
        ...BasicLinkFragment
      }
      image {
        gatsbyImageData
        alt
      }
    }
    items {
      document_link_text
      document_link {
        ...BasicLinkFragment
      }
      document_link_icon {
        gatsbyImageData(aspectRatio: 1, height: 25, width: 25)
        alt
      }
    }
  }
`

import { graphql } from 'gatsby'
import React from 'react'
import NewsroomArticlesLibrary, {
  NewsroomArticlesLibraryContent,
} from 'src/components/organisms/engagement/newsroomArticlesLibrary'
import {
  PrismicNewsroomArticle,
  PrismicPageDataBodyNewsroomArticlesLibrary,
} from 'src/typings/generated/graphql'

type Props = {
  slice: PrismicPageDataBodyNewsroomArticlesLibrary
}

const NewsroomArticlesLibrarySlice = ({ slice }: Props) => {
  const articles = slice.items.map(
    item => item.newsroom_article?.document as PrismicNewsroomArticle
  )
  const content: NewsroomArticlesLibraryContent = {
    anchor: slice.primary.anchor ?? undefined,
    headline: slice.primary.headline ?? undefined,
    articles,
  }

  return <NewsroomArticlesLibrary content={content} />
}
export default NewsroomArticlesLibrarySlice

export const NewsroomArticlesLibraryFragment = graphql`
  fragment PageDataBodyNewsroomArticlesLibrary on PrismicPageDataBodyNewsroomArticlesLibrary {
    id
    primary {
      anchor
      headline
    }
    items {
      newsroom_article {
        document {
          ... on PrismicNewsroomArticle {
            uid
            first_publication_date
            data {
              title {
                text
              }
              url {
                url
              }
              banner_image {
                gatsbyImageData
                alt
              }
              thumbnail_image {
                gatsbyImageData
                alt
              }
              post_date
              outlet
              body {
                richText
                text
              }
            }
          }
        }
      }
    }
  }
`

import { graphql } from 'gatsby'
import React from 'react'
import MinimalHero, {
  MinimalHeroContent,
} from 'src/components/organisms/heroes/minimalHero'

type Props = {
  slice: any
}

const MinimalHeroSlice = ({ slice }: Props) => {
  const content: MinimalHeroContent = {
    anchor: slice.primary.anchor ?? undefined,
    title: slice.primary.title ?? undefined,
    titleSize: slice.primary.title_size ?? undefined,
    image: slice.primary.image?.gatsbyImageData ?? undefined,
    imageAlt: slice.primary.image?.alt ?? undefined,
  }

  return <MinimalHero content={content} />
}

export default MinimalHeroSlice

export const MinimalHeroFragment = graphql`
  fragment PageDataBodyMinimalHero on PrismicPageDataBodyMinimalHero {
    primary {
      anchor
      title
      title_size
      image {
        gatsbyImageData
        alt
        thumbnails {
          mobile {
            gatsbyImageData
            alt
          }
        }
      }
    }
  }
`

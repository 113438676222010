import { graphql } from 'gatsby'
import React from 'react'
import {
  PrismicFaqCategory,
  PrismicPageDataBodyFaqCategory,
} from 'src/typings/generated/graphql'
import FaqCategory, {
  FaqCategoryContent,
} from 'src/components/organisms/general/faqCategory'

type Props = {
  slice: PrismicPageDataBodyFaqCategory
}

const FaqCategorySlice = ({ slice }: Props) => {
  let category
  if (slice.primary.faq_category?.document?.type === 'faq_category')
    category = slice.primary.faq_category.document as PrismicFaqCategory

  const content: FaqCategoryContent = {
    anchor: slice.primary.anchor ?? undefined,
    headline: slice.primary.headline ?? undefined,
    faqCategory: category,
    link: slice.primary.link ?? undefined,
    linkText: slice.primary.link_text ?? undefined,
  }

  return <FaqCategory content={content} />
}

export default FaqCategorySlice

export const FaqCategoryFragment = graphql`
  fragment PageDataBodyFaqCategory on PrismicPageDataBodyFaqCategory {
    primary {
      anchor
      headline
      faq_category {
        document {
          ... on PrismicFaqCategory {
            type
            id
            data {
              question_and_answer {
                question {
                  text
                }
                answer {
                  richText
                }
              }
            }
          }
        }
      }
      link_text
      link {
        ...BasicLinkFragment
      }
    }
  }
`

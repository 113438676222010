import { graphql } from 'gatsby'
import React from 'react'
import RecentArticles, {
  RecentArticlesContnet,
} from 'src/components/organisms/engagement/recentArticles'
import { PrismicPageDataBodyRecentArticles } from 'src/typings/generated/graphql'

type Props = {
  slice: PrismicPageDataBodyRecentArticles
}

const RecentArticlesSlice = ({ slice }: Props) => {
  const content: RecentArticlesContnet = {
    articleCategoryId: slice.primary.article_category?.id ?? undefined,
    headline: slice.primary.headline ?? undefined,
    hiddenArticleId: undefined,
  }
  return <RecentArticles content={content} />
}

export default RecentArticlesSlice

export const RecentArticlesFragment = graphql`
  fragment PageDataBodyRecentArticles on PrismicPageDataBodyRecentArticles {
    primary {
      headline
      article_category {
        id
      }
    }
  }
`

// extracted by mini-css-extract-plugin
export var active = "saucyCarouselItem-module--active--11de5";
export var backgroundMedia = "saucyCarouselItem-module--background-media--095d8";
export var backgroundVideo = "saucyCarouselItem-module--background-video--3553e";
export var categoryIcon = "saucyCarouselItem-module--category-icon--08283";
export var categoryPill = "saucyCarouselItem-module--category-pill--8fa57";
export var content = "saucyCarouselItem-module--content--1e525";
export var contentLink = "saucyCarouselItem-module--content-link--cd14e";
export var contentWrapper = "saucyCarouselItem-module--content-wrapper--6a86b";
export var desktopImage = "saucyCarouselItem-module--desktop-image--26e85";
export var itemLink = "saucyCarouselItem-module--item-link--12c66";
export var link = "saucyCarouselItem-module--link--7960b";
export var mobileImage = "saucyCarouselItem-module--mobile-image--a3330";
export var saucyCarouselItem = "saucyCarouselItem-module--saucy-carousel-item--4148a";
export var videoControl = "saucyCarouselItem-module--video-control--3ed82";
import React, { useEffect } from 'react'
import Eyebrow from 'src/images/svgs/Eyebrow.svg'
import * as GlobalStyles from 'src/styles/global.module.scss'
import { Link } from 'gatsby'
import * as Styles from './howItWorks.module.scss'

const HowItWorks = ({ content }: any) => {
  const {
    anchor,
    description,
    heading,
    listItems,
    primaryCtaText,
    primaryLink,
    primaryLinkTarget,
    secondaryWorksLinkText,
    secondaryLink,
    secondaryLinkTarget,
  } = content

  useEffect(() => {
    const scrollItems = document.querySelectorAll('.scroll-item')
    window.addEventListener('scroll', () =>
      scrollItems.forEach((item: any) => {
        if (item.getBoundingClientRect().top < 300) {
          item.classList.add(Styles.listActive)
        } else {
          item.classList.remove(Styles.listActive)
        }
      })
    )
  }, [])

  return (
    <section id={anchor} className={Styles.howItWorks}>
      <div className={Styles.howItWorksInner}>
        <div className={Styles.infoContainer}>
          <Eyebrow className={Styles.eyebrow} />
          <h2>{heading}</h2>
          <div
            className={Styles.description}
            dangerouslySetInnerHTML={{ __html: description }}
          />
          <div className={Styles.buttonContainer}>
            <Link
              to={primaryLink}
              target={primaryLinkTarget}
              className={GlobalStyles.redButton}
            >
              {primaryCtaText}
            </Link>
            <Link
              to={secondaryLink}
              target={secondaryLinkTarget}
              className={`${GlobalStyles.redBasicLink}`}
            >
              {secondaryWorksLinkText}
            </Link>
          </div>
        </div>
        <div className={Styles.stepsContainer}>
          {listItems.map((item: any, index: number) => (
            <div className={`${Styles.listItem} scroll-item`}>
              <div className={Styles.listContent}>
                <div className={Styles.circleContainer}>
                  <div className={Styles.circle} />
                </div>
                <span className={Styles.number}>{index + 1}</span>
                <div className={Styles.textContainer}>
                  <h3>{item.headline}</h3>
                  <div dangerouslySetInnerHTML={{ __html: item.description }} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default HowItWorks

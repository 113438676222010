import React from 'react'
import { graphql } from 'gatsby'

import { PrismicPageDataBodyHighlights } from 'src/typings/generated/graphql'
import Highlights, {
  HighlightsContent,
} from 'src/components/organisms/brandEducation/highlights'

type Props = {
  slice: PrismicPageDataBodyHighlights
}

const HighlightsSlice = ({ slice }: Props) => {
  const content: HighlightsContent = {
    anchor: slice.primary.anchor ?? undefined,
    headline: slice.primary.headline ?? undefined,
    highlight1: slice.primary.highlight_1 ?? undefined,
    details1: slice.primary.details_1 ?? undefined,
    highlight2: slice.primary.highlight_2 ?? undefined,
    details2: slice.primary.details_2 ?? undefined,
    highlight3: slice.primary.highlight_3 ?? undefined,
    details3: slice.primary.details_3 ?? undefined,
    highlight4: slice.primary.highlight_4 ?? undefined,
    details4: slice.primary.details_4 ?? undefined,
    highlight5: slice.primary.highlight_5 ?? undefined,
    details5: slice.primary.details_5 ?? undefined,
    highlight6: slice.primary.highlight_6 ?? undefined,
    details6: slice.primary.details_6 ?? undefined,
    image: slice.primary.image?.gatsbyImageData ?? undefined,
    imageAlt: slice.primary.image?.alt ?? undefined,
    link: slice.primary.link ?? undefined,
    linkText: slice.primary.link_text ?? undefined,
  }
  return <Highlights content={content} />
}

export default HighlightsSlice

export const HighlightsFragment = graphql`
  fragment PageDataBodyHighlights on PrismicPageDataBodyHighlights {
    primary {
      anchor
      headline
      image {
        gatsbyImageData
        alt
      }
      highlight_1
      details_1 {
        richText
      }
      highlight_2
      details_2 {
        richText
      }
      highlight_3
      details_3 {
        richText
      }
      highlight_4
      details_4 {
        richText
      }
      highlight_5
      details_5 {
        richText
      }
      highlight_6
      details_6 {
        richText
      }
      link {
        ...BasicLinkFragment
      }
      link_text
    }
  }
`

import { graphql } from 'gatsby'
import React from 'react'
import {
  PrismicPageDataBodyArticlesHero,
  PrismicPageDataBodyArticlesHeroItem,
  PrismicLinkType,
} from 'src/typings/generated/graphql'
import ArticlesHero, {
  ArticlesHeroContent,
} from 'src/components/organisms/heroes/articlesHero'

type Props = {
  slice: PrismicPageDataBodyArticlesHero
}

const ArticlesHeroSlice = ({ slice }: Props) => {
  const items: PrismicLinkType[] = []
  slice.items.forEach((item: PrismicPageDataBodyArticlesHeroItem) => {
    if (item.article) items.push(item.article)
  })
  const content: ArticlesHeroContent = {
    anchor: slice.primary.anchor ?? undefined,
    headline: slice.primary.headline ?? undefined,
    items,
  }
  return <ArticlesHero content={content} />
}

export default ArticlesHeroSlice

export const ArticlesHeroFragment = graphql`
  fragment PageDataBodyArticlesHero on PrismicPageDataBodyArticlesHero {
    primary {
      anchor
      headline
    }
    items {
      article {
        ...BasicLinkFragment
        document {
          ... on PrismicArticle {
            type
            data {
              title {
                text
              }
              thumbnail_image {
                gatsbyImageData
                alt
              }
              category {
                document {
                  ...CategoryFragment
                }
              }
              post_date
            }
          }
        }
      }
    }
  }
`

import { graphql } from 'gatsby'
import React from 'react'
import ProfileHighlight, {
  ProfileHighlightContent,
  ProfileHighlightHighlightItem,
} from 'src/components/organisms/brandEducation/profileHighlight'
import { PrismicPageDataBodyProfileHighlight } from 'src/typings/generated/graphql'

type Props = {
  slice: PrismicPageDataBodyProfileHighlight
}

const ProfileHighlightSlice = ({ slice }: Props) => {
  const content: ProfileHighlightContent = {
    anchor: slice.primary.anchor ?? undefined,
    biography: slice.primary.biography ?? undefined,
    facebook: slice.primary.facebook ?? undefined,
    headline: slice.primary.headline ?? undefined,
    highlightItems: slice.items.map(
      item =>
        ({
          headline: item.highlight_headline ?? undefined,
          description: item.highlight_description ?? undefined,
        } as ProfileHighlightHighlightItem)
    ),
    image: slice.primary.image?.gatsbyImageData ?? undefined,
    imageAlt: slice.primary.image?.alt ?? undefined,
    instagram: slice.primary.instagram ?? undefined,
    twitter: slice.primary.twitter ?? undefined,
  }
  return <ProfileHighlight content={content} />
}

export default ProfileHighlightSlice

export const ProfileHighlightFragment = graphql`
  fragment PageDataBodyProfileHighlight on PrismicPageDataBodyProfileHighlight {
    primary {
      anchor
      headline
      biography {
        richText
      }
      facebook {
        ...BasicLinkFragment
      }
      twitter {
        ...BasicLinkFragment
      }
      instagram {
        ...BasicLinkFragment
      }
      image {
        gatsbyImageData
        alt
      }
    }
    items {
      highlight_headline
      highlight_description {
        richText
      }
    }
  }
`

import React from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'
import CustomLink from 'src/utils/customLink'
import BertholdTitle from 'src/components/atoms/bertholdTitle'
import {
  PrismicLinkType,
  PrismicStructuredTextType,
} from 'src/typings/generated/graphql'
import * as GlobalStyles from 'src/styles/global.module.scss'
import Eyebrow from 'src/images/svgs/Eyebrow.svg'
import DescriptiveCard from 'src/components/molecules/cards/descriptiveCard'
import { generateRandomId } from 'src/utils/domHelper'
import * as Styles from './communityInvolvement.module.scss'

export type CommunityInvolvementContent = {
  id?: string
  anchor?: string
  eyebrowText?: string
  headline?: string
  buttonLink?: PrismicLinkType
  buttonText?: string
  bannerImage?: IGatsbyImageData
  bannerImageAlt?: string
  ctaDescription?: PrismicStructuredTextType
  ctaButtonText?: string
  ctaButtonLink?: PrismicLinkType
  card1Title?: string
  card1Description?: string
  card1Image?: IGatsbyImageData
  card1ImageAlt?: string
  card2Title?: string
  card2Description?: string
  card2Image?: IGatsbyImageData
  card2ImageAlt?: string
  card3Title?: string
  card3Description?: string
  card3Image?: IGatsbyImageData
  card3ImageAlt?: string
  card4Title?: string
  card4Description?: string
  card4Image?: IGatsbyImageData
  card4ImageAlt?: string
  card5Title?: string
  card5Description?: string
  card5Image?: IGatsbyImageData
  card5ImageAlt?: string
  card6Title?: string
  card6Description?: string
  card6Image?: IGatsbyImageData
  card6ImageAlt?: string
}

type Props = {
  content: CommunityInvolvementContent
}

const CommunityInvolvement = ({ content }: Props) => {
  const {
    id = '',
    anchor = generateRandomId(),
    eyebrowText = '',
    headline = '',
    buttonLink,
    buttonText = '',
    bannerImage,
    bannerImageAlt = '',
    ctaDescription,
    ctaButtonText = '',
    ctaButtonLink,
    card1Title = '',
    card1Description = '',
    card1Image,
    card1ImageAlt = '',
    card2Title = '',
    card2Description = '',
    card2Image,
    card2ImageAlt = '',
    card3Title = '',
    card3Description = '',
    card3Image,
    card3ImageAlt = '',
    card4Title = '',
    card4Description = '',
    card4Image,
    card4ImageAlt = '',
    card5Title = '',
    card5Description = '',
    card5Image,
    card5ImageAlt = '',
    card6Title = '',
    card6Description = '',
    card6Image,
    card6ImageAlt = '',
  } = content

  const cards = [
    {
      title: card1Title,
      description: card1Description,
      image: card1Image,
      imageAlt: card1ImageAlt,
    },
    {
      title: card2Title,
      description: card2Description,
      image: card2Image,
      imageAlt: card2ImageAlt,
    },
    {
      title: card3Title,
      description: card3Description,
      image: card3Image,
      imageAlt: card3ImageAlt,
    },
    {
      title: card4Title,
      description: card4Description,
      image: card4Image,
      imageAlt: card4ImageAlt,
    },
    {
      title: card5Title,
      description: card5Description,
      image: card5Image,
      imageAlt: card5ImageAlt,
    },
    {
      title: card6Title,
      description: card6Description,
      image: card6Image,
      imageAlt: card6ImageAlt,
    },
  ]

  const generateSlides = (descriptionDisplay: string) =>
    cards.map((card, index) => {
      const key = `descriptive-card-${index}`
      return (
        <div className={Styles.cardWrapper} key={key}>
          <DescriptiveCard
            title={card.title}
            description={card.description}
            image={card.image}
            alt={card.imageAlt}
            descriptionDisplay={descriptionDisplay}
            scaleHeadlineFont
          />
        </div>
      )
    })

  const descriptiveSlider = () => (
    <div>
      <div id={`slider-${id}`} className={Styles.mobileSlider}>
        {generateSlides('toggle')}
      </div>
      <div className={Styles.desktopGrid}>{generateSlides('toggle')}</div>
    </div>
  )

  return (
    <section id={anchor} className={Styles.communityInvolvement}>
      <div className={Styles.upper}>
        <div className={Styles.banner}>
          {!!bannerImage && (
            <GatsbyImage image={bannerImage} alt={bannerImageAlt} />
          )}
        </div>
        <div className={Styles.copy}>
          <div className={Styles.eyebrow}>
            <Eyebrow />
          </div>
          <div className={Styles.eyebrowText} role="heading" aria-level={2}>
            {BertholdTitle({ text: eyebrowText })}
          </div>
          <div className={Styles.headline}>
            {BertholdTitle({ text: headline })}
          </div>
          <div className={Styles.button}>
            <CustomLink
              className={GlobalStyles.redButtonResponsive}
              link={buttonLink}
            >
              {buttonText}
            </CustomLink>
          </div>
        </div>
      </div>
      {descriptiveSlider()}
      <div className={Styles.lower}>
        <div className={Styles.ctaDescription}>
          <PrismicRichText
            field={ctaDescription?.richText}
            components={{
              hyperlink: ({ node, children }: any) =>
                CustomLink({ link: node.data, children }),
            }}
          />
        </div>
        <div className={Styles.ctaButton}>
          <CustomLink
            className={GlobalStyles.redButtonResponsive}
            link={ctaButtonLink}
          >
            {ctaButtonText}
          </CustomLink>
        </div>
      </div>
    </section>
  )
}

export default CommunityInvolvement

import React from 'react'

import * as GlobalStyles from '../../styles/global.module.scss'

type ScreenReaderOnlyTextProps = {
  children: string
}

const ScreenReaderOnlyText = ({
  children,
  ...otherProps
}: ScreenReaderOnlyTextProps) => (
  <span className={GlobalStyles.srOnly} {...otherProps}>
    {children}
  </span>
)

export default ScreenReaderOnlyText

import React from 'react'
import { graphql } from 'gatsby'
import { PrismicPageDataBodyBasicSlider } from 'src/typings/generated/graphql'
import BasicSlider, {
  BasicSliderContent,
  BasicSliderItem,
} from 'src/components/organisms/productRouting/basicSlider'

type Props = {
  slice: PrismicPageDataBodyBasicSlider
}

const BasicSliderSlice = ({ slice }: Props) => {
  const items = slice.items.map(
    item =>
      ({
        title: item.title ?? undefined,
        image: item.image?.gatsbyImageData ?? undefined,
        imageAlt: item.image?.alt ?? undefined,
        link: item.link ?? undefined,
        linkText: item.link_text ?? undefined,
        overrideTitleWithLink: item.override_title_using_link ?? undefined,
        overrideImageWithLink: item.override_image_using_link ?? undefined,
      } as BasicSliderItem)
  )

  const content: BasicSliderContent = {
    anchor: slice.primary.anchor ?? undefined,
    items,
  }

  return <BasicSlider content={content} />
}

export default BasicSliderSlice

export const BasicSliderFragment = graphql`
  fragment PageDataBodyBasicSlider on PrismicPageDataBodyBasicSlider {
    id
    primary {
      anchor
    }
    items {
      title
      image {
        gatsbyImageData
      }
      link_text
      link {
        ...BasicLinkFragment
        document {
          ... on PrismicMenuItem {
            data {
              name
              image {
                gatsbyImageData
              }
            }
          }
        }
      }
      override_title_using_link
      override_image_using_link
    }
  }
`

import * as React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import PinIcon from 'src/images/svgs/icons/PinIcon.svg'
import { MobileLocatorQuery } from 'src/typings/generated/graphql'
import * as Styles from './mobileLocator.module.scss'

const MobileLocator = () => {
  const mobileLocatorData: MobileLocatorQuery = useStaticQuery(graphql`
    query MobileLocator {
      prismicSiteInformation {
        data {
          site_icon {
            gatsbyImageData
            alt
          }
        }
      }
    }
  `)

  return (
    <div className={Styles.mobileLocator}>
      <div className={Styles.headerContainer}>
        <div className={Styles.logo}>
          <Link to="/" aria-label="Raising Cane's homepage">
            <GatsbyImage
              data-testid="canes-logo"
              image={
                mobileLocatorData.prismicSiteInformation?.data.site_icon
                  ?.gatsbyImageData
              }
              alt={
                mobileLocatorData.prismicSiteInformation?.data.site_icon
                  ?.alt as string
              }
            />
          </Link>
        </div>
        <Link
          to="https://locations.raisingcanes.com/search"
          className={Styles.toggleContainer}
          aria-label="Find a Raising Cane's"
        >
          <PinIcon className={Styles.pinIcon} />
        </Link>
      </div>
    </div>
  )
}

export default MobileLocator

// extracted by mini-css-extract-plugin
export var active = "articlesHero-module--active--66689";
export var activeArticle = "articlesHero-module--active-article--ca635";
export var arrow = "articlesHero-module--arrow--1ffde";
export var articleLink = "articlesHero-module--article-link--ad987";
export var articles = "articlesHero-module--articles--408cf";
export var articlesHero = "articlesHero-module--articles-hero--eb0b4";
export var background = "articlesHero-module--background--a85f2";
export var baseTimer = "articlesHero-module--base-timer--4a571";
export var baseTimerCircle = "articlesHero-module--base-timer-circle--f6187";
export var baseTimerRemaining = "articlesHero-module--base-timer-remaining--2a3ef";
export var baseTimerSvg = "articlesHero-module--base-timer-svg--050bb";
export var card = "articlesHero-module--card--34823";
export var cards = "articlesHero-module--cards--c2169";
export var categoryPill = "articlesHero-module--category-pill--16dec";
export var content = "articlesHero-module--content--9f9fd";
export var details = "articlesHero-module--details--2626a";
export var image = "articlesHero-module--image--681d4";
export var links = "articlesHero-module--links--93aa1";
export var next = "articlesHero-module--next--ebbc6";
export var onDeck = "articlesHero-module--on-deck--b2eb4";
export var playPauseIcon = "articlesHero-module--play-pause-icon--9c575";
export var queued = "articlesHero-module--queued--61ec4";
export var selectDisable = "articlesHero-module--select-disable--d766a";
export var thumbnail = "articlesHero-module--thumbnail--a2cd1";
export var web = "articlesHero-module--web--1e6f5";
import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicPageDataBodyImageGrid } from 'src/typings/generated/graphql'
import ImageGrid, {
  ImageGridContent,
} from 'src/components/organisms/slidersAndGrids/imageGrid'

interface Props {
  slice: PrismicPageDataBodyImageGrid
}

const ImageGridSlice = ({ slice }: Props) => {
  const content: ImageGridContent = {
    anchor: slice.primary.anchor ?? undefined,
    text1: slice.primary.text1 ?? undefined,
    text2: slice.primary.text2 ?? undefined,
    text3: slice.primary.text3 ?? undefined,
    image1: slice.primary.image1?.gatsbyImageData ?? undefined,
    image1Alt: slice.primary.image1?.alt ?? undefined,
    image2: slice.primary.image2?.gatsbyImageData ?? undefined,
    image2Alt: slice.primary.image2?.alt ?? undefined,
    image3: slice.primary.image3?.gatsbyImageData ?? undefined,
    image3Alt: slice.primary.image3?.alt ?? undefined,
    image4: slice.primary.image4?.gatsbyImageData ?? undefined,
    image4Alt: slice.primary.image4?.alt ?? undefined,
    image5: slice.primary.image5?.gatsbyImageData ?? undefined,
    image5Alt: slice.primary.image5?.alt ?? undefined,
    image6: slice.primary.image6?.gatsbyImageData ?? undefined,
    image6Alt: slice.primary.image6?.alt ?? undefined,
  }

  return <ImageGrid content={content} />
}

export default ImageGridSlice

export const imageGridFragment = graphql`
  fragment PageDataBodyImageGrid on PrismicPageDataBodyImageGrid {
    primary {
      anchor
      text1
      text2
      text3
      image1 {
        gatsbyImageData
        alt
      }
      image2 {
        gatsbyImageData
        alt
      }
      image3 {
        gatsbyImageData
        alt
      }
      image4 {
        gatsbyImageData
        alt
      }
      image5 {
        gatsbyImageData
        alt
      }
      image6 {
        gatsbyImageData
        alt
      }
    }
  }
`

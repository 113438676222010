import React from 'react'
import {
  PrismicFaqCategory,
  PrismicLinkType,
} from 'src/typings/generated/graphql'
import CustomLink from 'src/utils/customLink'
import BertholdTitle from 'src/components/atoms/bertholdTitle'
import FaqCategoryQuestion, {
  generateFaqCategoryQuestionId,
} from 'src/components/molecules/faqCategoryQuestion'
import { generateRandomId } from 'src/utils/domHelper'

import * as GlobalStyles from 'src/styles/global.module.scss'
import * as Styles from './faqCategory.module.scss'

export type FaqCategoryContent = {
  anchor?: string
  headline?: string
  faqCategory?: PrismicFaqCategory
  link?: PrismicLinkType
  linkText?: string
}

type Props = {
  content: FaqCategoryContent
}

const FaqCategory = ({ content }: Props) => {
  const {
    anchor = generateRandomId(),
    headline = '',
    faqCategory,
    link,
    linkText = '',
  } = content

  return (
    <section id={anchor} className={Styles.faqCategory}>
      <div className={Styles.contentContainer}>
        <h2>{BertholdTitle({ text: headline })}</h2>
        <div className={Styles.questions}>
          {faqCategory &&
            faqCategory.data.question_and_answer?.map((qa, index: number) => {
              const questionId = generateFaqCategoryQuestionId(
                faqCategory.id,
                index
              )
              return (
                qa && (
                  <FaqCategoryQuestion
                    id={questionId}
                    question={qa}
                    key={questionId}
                  />
                )
              )
            })}
        </div>
        <CustomLink link={link} className={GlobalStyles.redBasicLink}>
          {linkText}
        </CustomLink>
      </div>
    </section>
  )
}

export default FaqCategory

import React from 'react'
import { graphql } from 'gatsby'
import { PrismicPageDataBodyArtOfAPerfectMeal } from 'src/typings/generated/graphql'
import ArtOfAPerfectMeal, {
  ArtOfAPerfectMealContent,
} from 'src/components/organisms/productRouting/artOfAPerfectMeal'

type Props = {
  slice: PrismicPageDataBodyArtOfAPerfectMeal
}

const ArtOfAPerfectMealSlice = ({ slice }: Props) => {
  const content: ArtOfAPerfectMealContent = {
    anchor: slice.primary.anchor ?? undefined,
    headline: slice.primary.headline ?? undefined,
    link: slice.primary.link ?? undefined,
    linkText: slice.primary.link_text ?? undefined,
    sauceTitle: slice.primary.sauce_title ?? undefined,
    sauceDetails: slice.primary.sauce_details ?? undefined,
    slawTitle: slice.primary.slaw_title ?? undefined,
    slawDetails: slice.primary.slaw_details ?? undefined,
    drinkTitle: slice.primary.drink_title ?? undefined,
    drinkDetails: slice.primary.drink_details ?? undefined,
    friesTitle: slice.primary.fries_title ?? undefined,
    friesDetails: slice.primary.fries_details ?? undefined,
    toastTitle: slice.primary.toast_title ?? undefined,
    toastDetails: slice.primary.toast_details ?? undefined,
    chickenTitle: slice.primary.chicken_title ?? undefined,
    chickenDetails: slice.primary.chicken_details ?? undefined,
  }
  return <ArtOfAPerfectMeal content={content} />
}

export default ArtOfAPerfectMealSlice

export const ArtOfAPerfectMealFragment = graphql`
  fragment PageDataBodyArtOfAPerfectMeal on PrismicPageDataBodyArtOfAPerfectMeal {
    primary {
      anchor
      headline
      sauce_title
      sauce_details {
        richText
      }
      slaw_title
      slaw_details {
        richText
      }
      drink_title
      drink_details {
        richText
      }
      fries_title
      fries_details {
        richText
      }
      toast_title
      toast_details {
        richText
      }
      chicken_title
      chicken_details {
        richText
      }
      link {
        ...BasicLinkFragment
      }
      link_text
    }
  }
`

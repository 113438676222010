import React from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'
import {
  PrismicLinkType,
  PrismicStructuredTextType,
} from 'src/typings/generated/graphql'
import BertholdTitle from 'src/components/atoms/bertholdTitle'

import LocatorPinIcon from 'src/images/svgs/icons/LocatorPinIcon.svg'
import CustomLink from 'src/utils/customLink'
import RetroIconsStrip from 'src/components/atoms/retroIconsStrip'
import { generateRandomId } from 'src/utils/domHelper'
import * as GlobalStyles from 'src/styles/global.module.scss'
import * as Styles from './crewCallout.module.scss'

export type CrewCalloutContent = {
  anchor?: string
  crewName?: string
  description?: PrismicStructuredTextType
  headline?: string
  highlight?: string
  link?: PrismicLinkType
  linkText?: string
  location?: string
  primaryImage1?: IGatsbyImageData
  primaryImage2?: IGatsbyImageData
  primaryImage3?: IGatsbyImageData
  primaryImage4?: IGatsbyImageData
  primaryImage1Alt?: string
  primaryImage2Alt?: string
  primaryImage3Alt?: string
  primaryImage4Alt?: string
}

type Props = {
  content: CrewCalloutContent
}

const CrewCallout = ({ content }: Props) => {
  const {
    anchor = generateRandomId(),
    crewName = '',
    description,
    headline = '',
    highlight = '',
    link,
    linkText = '',
    location = '',
    primaryImage1 = '',
    primaryImage2 = '',
    primaryImage3 = '',
    primaryImage4 = '',
    primaryImage1Alt = '',
    primaryImage2Alt = '',
    primaryImage3Alt = '',
    primaryImage4Alt = '',
  } = content

  const crewCalloutCaption = () => {
    if (crewName) {
      return (
        <p className={Styles.featuredCrew}>
          <span>Featured Crew</span>
          {crewName}
        </p>
      )
    }
    return <div className={Styles.featuredCrew} />
  }

  const locationCaption = () => {
    if (location) {
      return (
        <p className={Styles.location}>
          <LocatorPinIcon />
          <span>{location}</span>
        </p>
      )
    }
    return <div className={Styles.location} />
  }

  return (
    <section id={anchor} className={Styles.crewCalloutSlice}>
      <div className={Styles.retroIconsWrapper}>
        <RetroIconsStrip />
      </div>
      <div className={Styles.contentWrapper}>
        <div className={Styles.content}>
          <h2 className={Styles.headline}>
            {BertholdTitle({ text: headline })}
          </h2>
          <p className={Styles.highlight}>{highlight}</p>
          {description && (
            <PrismicRichText
              field={description?.richText}
              components={{
                hyperlink: ({ node, children }: any) =>
                  CustomLink({
                    link: node.data,
                    children,
                  }),
              }}
            />
          )}
          <CustomLink link={link} className={GlobalStyles.redBasicLink}>
            {linkText}
          </CustomLink>
        </div>
      </div>
      <div className={Styles.imagesWrapper}>
        <div className={Styles.imageContainer}>
          {!!primaryImage1 && (
            <GatsbyImage
              className={Styles.image}
              image={primaryImage1}
              alt={primaryImage1Alt}
            />
          )}
          {crewCalloutCaption()}
        </div>
        <div className={Styles.imageContainer}>
          {!!primaryImage2 && (
            <GatsbyImage
              className={Styles.image}
              image={primaryImage2}
              alt={primaryImage2Alt}
            />
          )}
        </div>
        <div className={Styles.imageContainer}>
          {!!primaryImage3 && (
            <GatsbyImage
              className={Styles.image}
              image={primaryImage3}
              alt={primaryImage3Alt}
            />
          )}
          {locationCaption()}
        </div>
        <div className={Styles.imageContainer}>
          {!!primaryImage4 && (
            <GatsbyImage
              className={Styles.image}
              image={primaryImage4}
              alt={primaryImage4Alt}
            />
          )}
        </div>
      </div>
    </section>
  )
}

export default CrewCallout

import React from 'react'
import { PrismicRichText } from '@prismicio/react'
import { RichTextField } from '@prismicio/types'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import BertholdTitle from 'src/components/atoms/bertholdTitle'

import {
  PrismicLinkType,
  PrismicStructuredTextType,
} from 'src/typings/generated/graphql'

import * as GlobalStyles from 'src/styles/global.module.scss'

import EyebrowLong from 'src/images/svgs/EyebrowLong.svg'
import SalmonIcon from 'src/images/icons/retro/SalmonIcon.webp'
import HardHatIcon from 'src/images/icons/retro/HardHatIcon.webp'
import HeartsIcon from 'src/images/svgs/icons/retro/HeartsIcon.svg'
import GoodDogPrintIcon from 'src/images/svgs/icons/retro/GoodDogPrintIcon.svg'
import OneLoveIcon from 'src/images/svgs/icons/retro/OneLoveIcon.svg'
import CustomLink from 'src/utils/customLink'
import { generateRandomId } from 'src/utils/domHelper'

import * as Styles from './highlights.module.scss'

export type HighlightsContent = {
  anchor?: string
  headline?: string
  highlight1?: string
  details1?: PrismicStructuredTextType
  highlight2?: string
  details2?: PrismicStructuredTextType
  highlight3?: string
  details3?: PrismicStructuredTextType
  highlight4?: string
  details4?: PrismicStructuredTextType
  highlight5?: string
  details5?: PrismicStructuredTextType
  highlight6?: string
  details6?: PrismicStructuredTextType
  image: IGatsbyImageData
  imageAlt?: string
  link?: PrismicLinkType
  linkText?: string
}

type Props = {
  content: HighlightsContent
}

const Highlights = ({ content }: Props) => {
  const {
    anchor = generateRandomId(),
    headline = '',
    highlight1 = '',
    details1,
    highlight2 = '',
    details2,
    highlight3 = '',
    details3,
    image,
    imageAlt = '',
    highlight4 = '',
    details4,
    highlight5 = '',
    details5,
    highlight6 = '',
    details6,
    link,
    linkText = '',
  } = content

  const highlight = (highlightHeadline: string, details: RichTextField) => (
    <div className={Styles.highlight}>
      <h3>{BertholdTitle({ text: highlightHeadline })}</h3>
      <PrismicRichText
        field={details}
        components={{
          hyperlink: ({ node, children }: any) =>
            CustomLink({ link: node.data, children }),
        }}
      />
      <EyebrowLong />
    </div>
  )

  return (
    <section id={anchor} className={Styles.highlightsSlice}>
      <div className={Styles.retroImages} aria-hidden="true">
        <div className={Styles.salmonIcon}>
          <img src={SalmonIcon} alt="Salmon" />
        </div>
        <div className={Styles.heartsIcon}>
          <HeartsIcon />
        </div>
        <div className={Styles.goodDogIcon}>
          <GoodDogPrintIcon />
        </div>
        <div className={Styles.oneLoveIcon}>
          <OneLoveIcon />
        </div>
        <div className={Styles.hardHatIcon}>
          <img src={HardHatIcon} alt="Hard Hat" />
        </div>
      </div>
      <h2>{BertholdTitle({ text: headline })}</h2>
      <div className={Styles.mainContainer}>
        <div className={Styles.highlightsWrapper}>
          {highlight(highlight1, details1?.richText)}
          {highlight(highlight2, details2?.richText)}
          {highlight(highlight3, details3?.richText)}
        </div>
        {!!image && (
          <div className={Styles.imageWrapper}>
            <GatsbyImage
              image={image}
              alt={imageAlt}
              className={Styles.image}
            />
          </div>
        )}
        <div className={Styles.highlightsWrapper}>
          {highlight(highlight4, details4?.richText)}
          {highlight(highlight5, details5?.richText)}
          {highlight(highlight6, details6?.richText)}
        </div>
      </div>
      <CustomLink
        className={`${GlobalStyles.redGhostButtonResponsive} ${Styles.link}`}
        link={link}
      >
        {linkText}
      </CustomLink>
    </section>
  )
}

export default Highlights

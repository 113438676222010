import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import {
  DocumentHeadQuery,
  PrismicSiteInformation,
  Site,
} from 'src/typings/generated/graphql'
import { WithContext, Thing, Organization } from 'schema-dts'

type Props = {
  title?: string
  url?: string
  description?: string
  imageUrl?: string
  pageSchema?: WithContext<Thing> | null
  noIndex?: boolean
}

const DocumentHead = ({
  title = '',
  url = '',
  description = '',
  imageUrl = '',
  pageSchema = null,
  noIndex = false,
}: Props) => {
  const data: DocumentHeadQuery = useStaticQuery(graphql`
    query DocumentHead {
      site {
        siteMetadata {
          title
          description
          url
          author
        }
      }
      prismicSiteInformation {
        data {
          site_name
          site_description
          site_icon {
            url
          }
          social_media_platforms {
            link {
              url
            }
          }
        }
      }
    }
  `)

  const { prismicSiteInformation, site } = data

  const { data: siteInformationData } =
    prismicSiteInformation as PrismicSiteInformation

  const { siteMetadata } = site as Site

  const siteTitle =
    siteInformationData.site_name ?? siteMetadata?.title ?? 'Raising Canes'
  const headUrl = url ?? ''
  const headDescription =
    description ??
    siteInformationData.site_description ??
    siteMetadata?.description ??
    ''
  const headImageUrl = imageUrl ?? siteInformationData.site_icon?.url ?? ''

  const organizationSchema: WithContext<Organization> = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    url: 'https://raisingcanes.com',
    foundingDate: '1996-08-28',
    founder: {
      '@type': 'Person',
      name: 'Todd Graves',
    },
    name: "Raising Cane's Chicken Fingers",
    foundingLocation: {
      '@type': 'Place',
      address: '3313 Highland Rd Baton Rouge, LA 70802',
    },
  }

  if (siteInformationData.social_media_platforms) {
    const sameAsArr: string[] = []
    siteInformationData.social_media_platforms.forEach(socialMedia => {
      if (socialMedia?.link?.url) {
        sameAsArr.push(socialMedia.link.url)
      }
    })
    organizationSchema.sameAs = sameAsArr
  }
  if (headImageUrl) {
    organizationSchema.logo = headImageUrl
  }

  const documentTitle = () => {
    if (!title) return siteTitle
    return `${title} | ${siteTitle}`
  }

  return (
    <>
      <title>{documentTitle()}</title>
      {headUrl && <link rel="canonical" href={headUrl} />}
      {headDescription && <meta name="description" content={headDescription} />}
      {headImageUrl && <meta name="image" content={headImageUrl} />}
      {pageSchema && (
        <script type="application/ld+json">
          {JSON.stringify(pageSchema, null, 2)}
        </script>
      )}
      <script type="application/ld+json">
        {JSON.stringify(organizationSchema, null, 2)}
      </script>
      {noIndex && <meta name="robots" content="noindex" key="no-index" />}
    </>
  )
}

export default DocumentHead

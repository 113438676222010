import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React from 'react'
import BertholdTitle from 'src/components/atoms/bertholdTitle'

import * as Styles from './basicSlideCard.module.scss'

type Props = {
  headline: string
  details: string
  image?: IGatsbyImageData
  alt?: string
}

const BasicSlideCard = ({ headline, details, image, alt = '' }: Props) => (
  <div className={Styles.basicSlideCard}>
    {!!image && (
      <GatsbyImage className={Styles.image} image={image} alt={alt} />
    )}
    <h3>{BertholdTitle({ text: headline })}</h3>
    <p>{details}</p>
  </div>
)

export default BasicSlideCard

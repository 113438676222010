import { graphql, useStaticQuery } from 'gatsby'
import React, { useState } from 'react'
import FaqCategoryQuestion, {
  generateFaqCategoryQuestionId,
} from 'src/components/molecules/faqCategoryQuestion'
import {
  CategoriesQueryQuery,
  PrismicFaqCategory,
} from 'src/typings/generated/graphql'
import { generateRandomId } from 'src/utils/domHelper'
import * as Styles from './faq.module.scss'

export type FaqContent = {
  anchor?: string
  headline?: string
}

type Props = {
  content: FaqContent
}

const Faq = ({ content }: Props) => {
  const [activeCategory, setActiveCategory] = useState(0)
  const [activeTabPanelId, setActiveTabPanelId] = useState('tabpanel-0')
  const [ariaLabelledById, setAriaLabelledById] = useState('tab-0')
  const { anchor = generateRandomId(), headline = '' } = content

  const categoriesQuery: CategoriesQueryQuery = useStaticQuery(graphql`
    query CategoriesQuery {
      allPrismicFaqCategory(sort: { data: { title: { text: ASC } } }) {
        nodes {
          id
          data {
            title {
              text
            }
            question_and_answer {
              question {
                text
              }
              answer {
                richText
              }
            }
          }
        }
      }
    }
  `)

  const categories: PrismicFaqCategory[] = categoriesQuery.allPrismicFaqCategory
    .nodes as PrismicFaqCategory[]

  const updateActiveCategory = (categoryIndex: number) => {
    setActiveCategory(categoryIndex)
    setActiveTabPanelId(`tabpanel-${categoryIndex}`)
    setAriaLabelledById(`tab-${categoryIndex}`)
    const category: PrismicFaqCategory = categories[activeCategory]
    const questionId = generateFaqCategoryQuestionId(category.id, 0)
    document.getElementById(questionId)?.focus()
  }

  const generateCategories = () =>
    categories.map((category: PrismicFaqCategory, i: number) => {
      const tabId = `tab-${i}`
      const ariaControlsId = `tabpanel-${i}`
      let categoryClass = Styles.category
      if (i === activeCategory) categoryClass += ` ${Styles.active}`

      return (
        <button
          id={tabId}
          key={category.id}
          type="button"
          className={categoryClass}
          onClick={() => updateActiveCategory(i)}
          onKeyDown={e => {
            if (e.code === 'Enter') {
              updateActiveCategory(i)
            }
          }}
          role="tab"
          aria-selected={i === activeCategory}
          aria-controls={ariaControlsId}
        >
          {category.data.title?.text}
        </button>
      )
    })

  const generateQuestions = () => {
    if (categories[activeCategory] === undefined) return ''

    const category: PrismicFaqCategory = categories[activeCategory]

    return category.data.question_and_answer?.map((qa, i) => {
      if (!qa) return null

      const questionId = generateFaqCategoryQuestionId(category.id, i)

      return (
        <FaqCategoryQuestion id={questionId} question={qa} key={questionId} />
      )
    })
  }

  return (
    <section id={anchor} className={Styles.faq}>
      <div className={Styles.contentContainer}>
        <div className={Styles.directory} role="tablist">
          <h3>{headline}</h3>
          {generateCategories()}
        </div>
        <div
          id={activeTabPanelId}
          className={Styles.questions}
          role="tabpanel"
          aria-labelledby={ariaLabelledById}
        >
          {generateQuestions()}
        </div>
      </div>
    </section>
  )
}

export default Faq

import React, { useState } from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'
import CustomLink from 'src/utils/customLink'
import BertholdTitle from 'src/components/atoms/bertholdTitle'

import Eyebrow from 'src/images/svgs/Eyebrow.svg'
import TwitterIcon from 'src/images/svgs/icons/TwitterIcon.svg'
import FacebookIcon from 'src/images/svgs/icons/FacebookIcon.svg'
import InstagramIcon from 'src/images/svgs/icons/InstagramIcon.svg'
import {
  PrismicLinkType,
  PrismicStructuredTextType,
} from 'src/typings/generated/graphql'
import { generateRandomId } from 'src/utils/domHelper'
import * as Styles from './profileHighlight.module.scss'

export type ProfileHighlightHighlightItem = {
  headline?: string
  description?: PrismicStructuredTextType
}

export type ProfileHighlightContent = {
  anchor?: string
  biography?: PrismicStructuredTextType
  facebook?: PrismicLinkType
  headline?: string
  highlightItems?: ProfileHighlightHighlightItem[]
  image?: IGatsbyImageData
  imageAlt?: string
  instagram?: PrismicLinkType
  twitter?: PrismicLinkType
}

type Props = {
  content: ProfileHighlightContent
}

const ProfileHighlight = ({ content }: Props) => {
  const {
    anchor = generateRandomId(),
    biography,
    facebook,
    headline = '',
    highlightItems = [],
    image,
    imageAlt = '',
    instagram,
    twitter,
  } = content

  const [biographyCollapsed, setBiographyCollapsed] = useState(true)

  let biographyClass = Styles.biography
  if (biographyCollapsed) biographyClass += ` ${Styles.collapsed}`
  const biographyToggleText = biographyCollapsed ? 'Expand' : 'Collapse'
  return (
    <section id={anchor} className={Styles.profileHighlight}>
      <div className={Styles.mainContentWrapper}>
        <div className={Styles.headlineContainer}>
          <div className={Styles.headline}>
            <div className={Styles.eyebrow}>
              <Eyebrow />
            </div>
            <h2>{BertholdTitle({ text: headline })}</h2>
            <div className={Styles.socialMedia}>
              {twitter?.url && (
                <CustomLink link={twitter}>
                  <TwitterIcon />
                </CustomLink>
              )}
              {facebook?.url && (
                <CustomLink link={facebook}>
                  <FacebookIcon />
                </CustomLink>
              )}
              {instagram?.url && (
                <CustomLink link={instagram}>
                  <InstagramIcon />
                </CustomLink>
              )}
            </div>
          </div>
          <div className={Styles.profileImage}>
            {!!image && (
              <GatsbyImage
                className={Styles.image}
                image={image}
                alt={imageAlt}
              />
            )}
          </div>
          <div className={biographyClass}>
            <h3>Biography</h3>
            {biography && (
              <PrismicRichText
                field={biography.richText}
                components={{
                  hyperlink: ({ node, children }: any) =>
                    CustomLink({ link: node.data, children }),
                }}
              />
            )}
            <button
              type="button"
              className={Styles.biographyToggle}
              onClick={() => setBiographyCollapsed(!biographyCollapsed)}
            >
              {biographyToggleText}
            </button>
          </div>
        </div>
      </div>
      <div className={Styles.highlights}>
        {highlightItems.map((item, i: number) => {
          const key = `profile-highlight-${i}`
          return (
            <div className={Styles.highlight} key={key}>
              <h3>{item.headline && BertholdTitle({ text: item.headline })}</h3>
              <div className={Styles.details}>
                {item.description && (
                  <PrismicRichText
                    field={item.description.richText}
                    components={{
                      hyperlink: ({ node, children }: any) =>
                        CustomLink({ link: node.data, children }),
                    }}
                  />
                )}
              </div>
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default ProfileHighlight

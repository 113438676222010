import React from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import BertholdTitle from 'src/components/atoms/bertholdTitle'

import { PrismicLinkType } from 'src/typings/generated/graphql'

import * as GlobalStyles from 'src/styles/global.module.scss'

import CustomLink from 'src/utils/customLink'
import { generateRandomId } from 'src/utils/domHelper'
import * as Styles from './faqCta.module.scss'

export type FaqCtaItem = {
  link?: PrismicLinkType
  linkIcon?: IGatsbyImageData
  linkIconAlt?: string
  linkText?: string
}

export type FaqCtaContent = {
  anchor?: string
  headline?: string
  image?: IGatsbyImageData
  imageAlt?: string
  link?: PrismicLinkType
  linkText?: string
  items?: FaqCtaItem[]
}

type Props = {
  content: FaqCtaContent
}

const FaqCta = ({ content }: Props) => {
  const {
    anchor = generateRandomId(),
    headline = '',
    image,
    imageAlt = '',
    link,
    linkText = '',
    items = [],
  } = content

  return (
    <section id={anchor} className={Styles.faqCta}>
      <h2>{BertholdTitle({ text: headline })}</h2>
      <div className={Styles.content}>
        <div className={Styles.documents}>
          {items.map((item, index: number) => {
            const key = `faqDocuments-${index}`
            return (
              <CustomLink className={Styles.link} link={item.link} key={key}>
                <>
                  {!!item.linkIcon && (
                    <GatsbyImage
                      className={Styles.image}
                      image={item.linkIcon}
                      alt={item.linkIconAlt ?? ''}
                    />
                  )}
                  <span>{item.linkText ?? ''}</span>
                </>
              </CustomLink>
            )
          })}
        </div>
        <div className={Styles.mainWrapper}>
          <div className={Styles.imageContainer}>
            {!!image && (
              <GatsbyImage
                className={Styles.image}
                image={image}
                alt={imageAlt}
              />
            )}
          </div>
          {link?.url && (
            <div className={Styles.linkContainer}>
              <CustomLink link={link} className={GlobalStyles.whiteBasicLink}>
                {linkText}
              </CustomLink>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default FaqCta

import React from 'react'
import { graphql } from 'gatsby'
import { PrismicPageDataBodyCommunityInvolvement } from 'src/typings/generated/graphql'

import CommunityInvolvement, {
  CommunityInvolvementContent,
} from 'src/components/organisms/ctas/communityInvolvement'

interface Props {
  slice: PrismicPageDataBodyCommunityInvolvement
}

const CommunityInvolvementSlice = ({ slice }: Props) => {
  const content: CommunityInvolvementContent = {
    id: slice.id,
    anchor: slice.primary.anchor ?? undefined,
    eyebrowText: slice.primary.eyebrow_text ?? undefined,
    headline: slice.primary.headline ?? undefined,
    buttonLink: slice.primary.button_link ?? undefined,
    buttonText: slice.primary.button_text ?? undefined,
    bannerImage: slice.primary.banner_image?.gatsbyImageData ?? undefined,
    bannerImageAlt: slice.primary.banner_image?.alt ?? undefined,
    ctaDescription: slice.primary.cta_description ?? undefined,
    ctaButtonText: slice.primary.cta_button_text ?? undefined,
    ctaButtonLink: slice.primary.cta_button_link ?? undefined,
    card1Title: slice.primary.card_1_title ?? undefined,
    card1Description: slice.primary.card_1_description ?? undefined,
    card1Image: slice.primary.card_1_image?.gatsbyImageData ?? undefined,
    card1ImageAlt: slice.primary.card_1_image?.alt ?? undefined,
    card2Title: slice.primary.card_2_title ?? undefined,
    card2Description: slice.primary.card_2_description ?? undefined,
    card2Image: slice.primary.card_2_image?.gatsbyImageData ?? undefined,
    card2ImageAlt: slice.primary.card_2_image?.alt ?? undefined,
    card3Title: slice.primary.card_3_title ?? undefined,
    card3Description: slice.primary.card_3_description ?? undefined,
    card3Image: slice.primary.card_3_image?.gatsbyImageData ?? undefined,
    card3ImageAlt: slice.primary.card_3_image?.alt ?? undefined,
    card4Title: slice.primary.card_4_title ?? undefined,
    card4Description: slice.primary.card_4_description ?? undefined,
    card4Image: slice.primary.card_4_image?.gatsbyImageData ?? undefined,
    card4ImageAlt: slice.primary.card_4_image?.alt ?? undefined,
    card5Title: slice.primary.card_5_title ?? undefined,
    card5Description: slice.primary.card_5_description ?? undefined,
    card5Image: slice.primary.card_5_image?.gatsbyImageData ?? undefined,
    card5ImageAlt: slice.primary.card_5_image?.alt ?? undefined,
    card6Title: slice.primary.card_6_title ?? undefined,
    card6Description: slice.primary.card_6_description ?? undefined,
    card6Image: slice.primary.card_6_image?.gatsbyImageData ?? undefined,
    card6ImageAlt: slice.primary.card_6_image?.alt ?? undefined,
  }

  return <CommunityInvolvement content={content} />
}

export default CommunityInvolvementSlice

export const communityInvolvementFragment = graphql`
  fragment PageDataBodyCommunityInvolvement on PrismicPageDataBodyCommunityInvolvement {
    id
    primary {
      anchor
      banner_image {
        alt
        gatsbyImageData
      }
      button_link {
        ...BasicLinkFragment
      }
      button_text

      cta_button_link {
        ...BasicLinkFragment
      }
      cta_button_text
      cta_description {
        richText
      }
      eyebrow_text
      headline

      card_1_title
      card_2_title
      card_3_title
      card_4_title
      card_5_title
      card_6_title
      card_1_description
      card_2_description
      card_3_description
      card_4_description
      card_5_description
      card_6_description

      card_1_image {
        alt
        gatsbyImageData(layout: CONSTRAINED, width: 290, height: 330)
      }
      card_2_image {
        alt
        gatsbyImageData(layout: CONSTRAINED, width: 290, height: 330)
      }
      card_3_image {
        alt
        gatsbyImageData(layout: CONSTRAINED, width: 290, height: 330)
      }
      card_4_image {
        alt
        gatsbyImageData(layout: CONSTRAINED, width: 290, height: 330)
      }
      card_5_image {
        alt
        gatsbyImageData(layout: CONSTRAINED, width: 290, height: 330)
      }
      card_6_image {
        alt
        gatsbyImageData(layout: CONSTRAINED, width: 290, height: 330)
      }
    }
  }
`

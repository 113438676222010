import React from 'react'
import * as Styles from './embeddedVideo.module.scss'

/* eslint-disable jsx-a11y/media-has-caption */

type Props = {
  autoplay?: boolean
  controls?: boolean
  loop?: boolean
  mute?: boolean
  url: string
  width?: number
  height?: number
  className?: string
  ariaLabel: string
  videoRef?: React.RefObject<HTMLVideoElement>
}

const EmbeddedVideo = ({
  autoplay = true,
  controls = false,
  loop = true,
  mute = true,
  url,
  width = 355,
  height = 200,
  className = '',
  ariaLabel,
  videoRef = undefined,
}: Props) => (
  <div className={Styles.videoWrapper}>
    <video
      width={width}
      height={height}
      className={`${Styles.videoPlayer} ${className}`}
      autoPlay={autoplay}
      playsInline={autoplay}
      loop={loop}
      muted={mute}
      controls={controls}
      ref={videoRef}
      aria-label={ariaLabel}
      aria-hidden="true"
      data-testid="embedded-video-player"
    >
      <source src={url} type="video/mp4" />
      Your browser does not support the video tag
    </video>
  </div>
)

export default EmbeddedVideo

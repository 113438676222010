import { graphql } from 'gatsby'
import React from 'react'
import RichTextCta, {
  RichTextCtaContent,
} from 'src/components/organisms/ctas/richTextCta'
import { PrismicPageDataBodyRichTextCta } from 'src/typings/generated/graphql'

type Props = {
  slice: PrismicPageDataBodyRichTextCta
}

const RichTextCtaSlice = ({ slice }: Props) => {
  const content: RichTextCtaContent = {
    anchor: slice.primary.anchor ?? undefined,
    description: slice.primary.description ?? undefined,
    link: slice.primary.link ?? undefined,
    linkText: slice.primary.link_text ?? undefined,
  }

  return <RichTextCta content={content} />
}

export default RichTextCtaSlice

export const RichTextFragment = graphql`
  fragment PageDataBodyRichTextCta on PrismicPageDataBodyRichTextCta {
    primary {
      anchor
      description {
        richText
      }
      link {
        ...BasicLinkFragment
      }
      link_text
    }
  }
`

import React, { useState, useEffect } from 'react'
import {
  Maybe,
  PrismicCategory,
  PrismicArticle,
  PrismicLinkType,
} from 'src/typings/generated/graphql'
import BertholdTitle from 'src/components/atoms/bertholdTitle'

import * as GlobalStyles from 'src/styles/global.module.scss'
import * as SwipeHandler from 'src/utils/swipeHandler'

import CanesShadow from 'src/images/svgs/CanesShadow.svg'
import RightArrowIcon from 'src/images/svgs/icons/RightArrowIcon.svg'
import PauseIcon from 'src/images/svgs/icons/PauseIcon.svg'
import PlayIcon from 'src/images/svgs/icons/PlayIcon.svg'

import CategoryPill, { IPillStyle } from 'src/components/atoms/categoryPill'
import CustomLink from 'src/utils/customLink'
import { articleThumbnailImage } from 'src/utils/articlesHelper'
import { generateRandomId } from 'src/utils/domHelper'
import * as Styles from './articlesHero.module.scss'

export type ArticlesHeroContent = {
  anchor?: string
  headline?: string
  items: PrismicLinkType[]
}

type Props = {
  content: ArticlesHeroContent
}

const ArticlesHero = ({ content }: Props) => {
  const [activeArticleIndex, setActiveArticleIndex] = useState(0)
  const TIMER_LIMIT = 50
  const [timePassed, setTimePassed] = useState(0)
  const [timeLeft, setTimeLeft] = useState(TIMER_LIMIT)
  const [timerRunning, setTimerRunning] = useState(true)
  const [gestureStart, setGestureStart] = useState(0)
  const [gestureEnd, setGestureEnd] = useState(0)

  const { anchor = generateRandomId(), headline = '', items } = content

  const activeArticleLink = items[activeArticleIndex]
    ? items[activeArticleIndex]
    : null
  const activeArticle =
    activeArticleLink?.document?.type === 'article'
      ? (activeArticleLink.document as PrismicArticle)
      : null
  const articleTitleText = activeArticle?.data?.title?.text
    ? activeArticle?.data?.title?.text
    : "Raising Cane's Article"
  const activeArticleCategory = activeArticle?.data?.category
    ?.document as Maybe<PrismicCategory>

  const setActiveArticle = (i: number) => {
    setTimePassed(0)
    setTimeLeft(TIMER_LIMIT)
    setActiveArticleIndex(i)
  }

  const generateArticleCards = (web: boolean) =>
    items.map((item, i: number) => {
      if (item.document?.type !== 'article') return null

      const article = item.document as PrismicArticle
      const key = `articleCard-${i}`

      let cardClass = Styles.card
      if (activeArticleIndex === i) cardClass += ` ${Styles.active}`
      else if (activeArticleIndex + 1 === i) cardClass += ` ${Styles.next}`
      else if (activeArticleIndex + 2 === i) cardClass += ` ${Styles.onDeck}`
      else if (i === 0 && activeArticleIndex === items.length - 1)
        cardClass += ` ${Styles.next}`
      else if (i === 0 && activeArticleIndex === items.length - 2)
        cardClass += ` ${Styles.onDeck}`
      // set second element to on deck when at last article
      else if (i === 1 && activeArticleIndex === items.length - 1)
        cardClass += ` ${Styles.onDeck}`
      else cardClass += ` ${Styles.queued}`

      const category = article.data?.category
        ?.document as Maybe<PrismicCategory>

      if (web) {
        cardClass += ` ${Styles.web}`
        return (
          <button
            className={cardClass}
            type="button"
            aria-label={article.data.title?.text ?? 'Article'}
            onClick={() => setActiveArticle(i)}
            key={key}
          >
            {articleThumbnailImage(article, Styles.thumbnail)}
          </button>
        )
      }

      return (
        <CustomLink link={item} className={cardClass} key={key}>
          <>{articleThumbnailImage(article, Styles.thumbnail)}</>
          <div className={Styles.details}>
            <h3>
              {article.data.title?.text &&
                BertholdTitle({ text: article.data.title?.text })}
            </h3>
            {category && (
              <div className={Styles.categoryPill}>
                <CategoryPill
                  category={category.data.name ?? ''}
                  style={'grey-red' as IPillStyle}
                />
              </div>
            )}
          </div>
        </CustomLink>
      )
    })

  // Slider Handlers
  const back = () => {
    const newActive = activeArticleIndex - 1
    setActiveArticle(newActive < 0 ? items.length - 1 : newActive)
  }

  const next = () => {
    const newActive = activeArticleIndex + 1
    setActiveArticle(newActive % items.length)
  }

  function setCircleDasharray() {
    const rawTimeFraction = timeLeft / TIMER_LIMIT
    const timeFraction =
      rawTimeFraction - (1 / TIMER_LIMIT) * (1 - rawTimeFraction)

    const circleDasharray = `${(timeFraction * 283).toFixed(0)} 283`

    document
      .getElementById('base-timer-path-remaining')
      ?.setAttribute('style', `stroke-dasharray: ${circleDasharray};`)
  }

  const updateTimer = () => {
    setTimePassed(timePassed + 1)
    setTimeLeft(TIMER_LIMIT - timePassed)
    setCircleDasharray()
    if (timeLeft === 0) next()
  }

  const toggleTimer = () => {
    setTimerRunning(!timerRunning)
  }

  useEffect(() => {
    let timerInterval: NodeJS.Timer
    if (timerRunning) timerInterval = setInterval(updateTimer, 100)
    return () => clearInterval(timerInterval)
  }, [timerRunning, timePassed, timeLeft])

  return (
    <section id={anchor} className={Styles.articlesHero}>
      <div className={Styles.background}>
        <div className={Styles.image}>
          <CanesShadow />
        </div>
      </div>
      <div className={Styles.content}>
        <h1 className={Styles.selectDisable}>
          {BertholdTitle({ text: headline })}
        </h1>
        <div className={Styles.articles}>
          <div className={Styles.activeArticle}>
            <button
              type="button"
              className={Styles.baseTimer}
              onClick={() => toggleTimer()}
              aria-label={
                timerRunning
                  ? 'Pause the timer that shows the next highlighted Article'
                  : 'Resume the timer that shows the next highlighted Article'
              }
            >
              <svg
                className={Styles.baseTimerSvg}
                viewBox="0 0 100 100"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g className={Styles.baseTimerCircle}>
                  <path
                    id="base-timer-path-remaining"
                    strokeDasharray="283"
                    className={`${Styles.baseTimerRemaining}`}
                    d="
                        M 50, 50
                        m -45, 0
                        a 45,45 0 1,0 90,0
                        a 45,45 0 1,0 -90,0
                    "
                  />
                </g>
              </svg>
              <div className={Styles.playPauseIcon}>
                {timerRunning ? <PauseIcon /> : <PlayIcon />}
              </div>
            </button>
            <h3>
              {activeArticle?.data.title?.text &&
                BertholdTitle({ text: activeArticle?.data.title?.text })}
            </h3>
            <div className={Styles.links}>
              <div className={Styles.categoryPill}>
                {activeArticleCategory && (
                  <CategoryPill
                    category={activeArticleCategory.data.name ?? ''}
                    style={'grey-red' as IPillStyle}
                  />
                )}
              </div>
              <CustomLink
                link={activeArticleLink}
                className={`${GlobalStyles.redBasicLink} ${Styles.articleLink}`}
              >
                Explore The Story
              </CustomLink>
            </div>
          </div>
          <div
            className={Styles.cards}
            role="slider"
            aria-valuemin={0}
            aria-valuemax={items.length - 1}
            aria-valuenow={activeArticleIndex}
            aria-valuetext={articleTitleText}
            aria-label={headline}
            aria-orientation="horizontal"
            tabIndex={0}
            onMouseDown={mouseDownEvent =>
              SwipeHandler.mouseStart(mouseDownEvent, setGestureStart)
            }
            onMouseMove={mouseMoveEvent =>
              SwipeHandler.mouseMove(mouseMoveEvent, setGestureEnd)
            }
            onMouseUp={() =>
              SwipeHandler.swipeEnd(gestureStart, gestureEnd, back, next)
            }
            onTouchStart={touchStartEvent =>
              SwipeHandler.touchStart(touchStartEvent, setGestureStart)
            }
            onTouchMove={touchMoveEvent =>
              SwipeHandler.touchMove(touchMoveEvent, setGestureEnd)
            }
            onTouchEnd={() =>
              SwipeHandler.swipeEnd(gestureStart, gestureEnd, back, next)
            }
          >
            {generateArticleCards(false)}
            {generateArticleCards(true)}
          </div>
        </div>
        <div className={Styles.arrow}>
          <RightArrowIcon />
        </div>
      </div>
    </section>
  )
}

export default ArticlesHero

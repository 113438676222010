import { graphql } from 'gatsby'
import React from 'react'
import DescriptiveSlider, {
  DescriptiveSliderContent,
  DescriptiveSliderItem,
} from 'src/components/organisms/slidersAndGrids/descriptiveSlider'
import { PrismicPageDataBodyDescriptiveSlider } from 'src/typings/generated/graphql'

type Props = {
  slice: PrismicPageDataBodyDescriptiveSlider
}

const DescriptiveSliderSlice = ({ slice }: Props) => {
  const content: DescriptiveSliderContent = {
    anchor: slice.primary.anchor ?? undefined,
    copyLayout: slice.primary.copy_layout ?? undefined,
    id: slice.id,
    items: slice.items.map(
      item =>
        ({
          headline: item.headline ?? undefined,
          details: item.details ?? undefined,
          image: item.image?.gatsbyImageData ?? undefined,
          alt: item.image?.alt ?? undefined,
        } as DescriptiveSliderItem)
    ),
  }

  return <DescriptiveSlider content={content} />
}

export default DescriptiveSliderSlice

export const DescriptiveSliderFragment = graphql`
  fragment PageDataBodyDescriptiveSlider on PrismicPageDataBodyDescriptiveSlider {
    id
    primary {
      anchor
      copy_layout
    }
    items {
      headline
      details
      image {
        gatsbyImageData
        alt
      }
    }
  }
`

// extracted by mini-css-extract-plugin
export var buttonContainer = "imageReel-module--button-container--d3c22";
export var caniacClubCardIcon = "imageReel-module--caniacClubCardIcon--21dbb";
export var copy = "imageReel-module--copy--eab53";
export var description = "imageReel-module--description--3ea05";
export var hashtag = "imageReel-module--hashtag--099aa";
export var headline1 = "imageReel-module--headline1--915af";
export var headline2 = "imageReel-module--headline2--3fcdc";
export var image = "imageReel-module--image--ca111";
export var imageReel = "imageReel-module--image-reel--ca318";
export var outerReelContainer = "imageReel-module--outer-reel-container--7a01f";
export var reelContainer = "imageReel-module--reel-container--78a48";
export var reelImageContainer = "imageReel-module--reel-image-container--cccd6";
export var retro = "imageReel-module--retro--72b30";
export var socialMediaContainer = "imageReel-module--social-media-container--537e9";
export var socialMediaIcons = "imageReel-module--social-media-icons--ee184";
export var text = "imageReel-module--text--cc9d6";
export var videoControl = "imageReel-module--video-control--fdd03";
// extracted by mini-css-extract-plugin
export var arrow = "newsroomArticlesLibrary-module--arrow--ea88f";
export var article = "newsroomArticlesLibrary-module--article--38b1b";
export var articles = "newsroomArticlesLibrary-module--articles--fb4c2";
export var currentPage = "newsroomArticlesLibrary-module--current-page--ad0dd";
export var eyebrow = "newsroomArticlesLibrary-module--eyebrow--9580b";
export var headline = "newsroomArticlesLibrary-module--headline--4b09d";
export var headlineWrapper = "newsroomArticlesLibrary-module--headline-wrapper--ea58a";
export var hidden = "newsroomArticlesLibrary-module--hidden--0ba24";
export var newsroomArticlesLibrary = "newsroomArticlesLibrary-module--newsroom-articles-library--cc137";
export var next = "newsroomArticlesLibrary-module--next--9fc42";
export var pageButton = "newsroomArticlesLibrary-module--page-button--a1914";
export var pager = "newsroomArticlesLibrary-module--pager--b801f";
export var pagerButton = "newsroomArticlesLibrary-module--pager-button--859f5";
export var placeholderCard = "newsroomArticlesLibrary-module--placeholder-card--2131d";
export var placeholderCardContainer = "newsroomArticlesLibrary-module--placeholder-card-container--2120a";
export var prev = "newsroomArticlesLibrary-module--prev--4e895";
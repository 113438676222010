import React from 'react'

import FoamFingerHeartIcon from 'src/images/svgs/icons/retro/FoamFingerHeartIcon.svg'
import FoamFingerOneLoveIcon from 'src/images/svgs/icons/retro/FoamFingerOneLoveIcon.svg'
import OneLoveIcon from 'src/images/svgs/icons/retro/OneLoveIcon.svg'
import CaneDogIcon from 'src/images/svgs/icons/retro/CaneDogIcon.svg'
import GlobeIcon from 'src/images/svgs/icons/retro/GlobeIcon.svg'
import HeartsIcon from 'src/images/svgs/icons/retro/HeartsIcon.svg'

import * as Styles from './retroIconsStrip.module.scss'

const RetroIconsStrip = () => (
  <div className={Styles.retroIconsStrip}>
    <FoamFingerHeartIcon />
    <FoamFingerOneLoveIcon />
    <OneLoveIcon />
    <CaneDogIcon />
    <GlobeIcon />
    <HeartsIcon />
  </div>
)

export default RetroIconsStrip

// extracted by mini-css-extract-plugin
export var basicImageCard = "basicLinkCard-module--basic-image-card--51dc0";
export var content = "basicLinkCard-module--content--3edd2";
export var eyebrow = "basicLinkCard-module--eyebrow--e1d32";
export var image = "basicLinkCard-module--image--9bd2e";
export var imageWrapper = "basicLinkCard-module--image-wrapper--b9c75";
export var link = "basicLinkCard-module--link--62644";
export var linkContainer = "basicLinkCard-module--link-container--d9511";
export var title = "basicLinkCard-module--title--58501";
export var titleWrapper = "basicLinkCard-module--title-wrapper--4960e";
export var wrapper = "basicLinkCard-module--wrapper--e7672";
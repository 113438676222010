import React from 'react'
import { graphql } from 'gatsby'
import ECardWidget, {
  ECardWidgetContent,
} from 'src/components/organisms/general/eCardWidget'

type Props = {
  slice: any
}

const ECardWidgetSlice = ({ slice }: Props) => {
  const content: ECardWidgetContent = {
    anchor: slice.primary.anchor ?? undefined,
    eCardWidgetId: slice.primary.ecardwidget_id ?? '',
  }

  return <ECardWidget content={content} />
}

export default ECardWidgetSlice

export const ECardWidgetFragment = graphql`
  fragment PageDataBodyECardWidget on PrismicPageDataBodyEcardwidget {
    primary {
      anchor
      ecardwidget_id
    }
  }
`

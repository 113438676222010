/* eslint-disable import/prefer-default-export */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import * as React from 'react'
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from 'gatsby-plugin-prismic-previews'
import './src/styles/global.scss'
import { GatsbyBrowser } from 'gatsby'
import Layout from 'src/components/pageLayout/layout'
import MenuItemTemplate from 'src/pages/{prismicMenuItem.url}'
import { prismicLinkResolver } from './src/utils/linkResolver'
import PageTemplate from './src/pages/{prismicPage.url}'
import ArticleTemplate from './src/pages/{prismicArticle.url}'
import NewsroomArticleTemplate from './src/pages/{prismicNewsroomArticle.url}'

interface Props {
  element: React.ReactNode
}

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element,
}: Props) => (
  <PrismicPreviewProvider
    repositoryConfigs={[
      {
        repositoryName: process.env.PRISMIC_REPO_NAME ?? '',
        linkResolver: prismicLinkResolver,
        componentResolver: componentResolverFromMap({
          page: PageTemplate,
          article: ArticleTemplate,
          newsroomArticle: NewsroomArticleTemplate,
          menuItem: MenuItemTemplate,
        }),
      },
    ]}
  >
    {element}
  </PrismicPreviewProvider>
)

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
  props,
}: any) => <Layout {...props}>{element}</Layout>

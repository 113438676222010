import React from 'react'
import { graphql } from 'gatsby'

import { PrismicPageDataBodySecondaryNavigation } from 'src/typings/generated/graphql'
import SecondaryNavigation, {
  SecondaryNavigationContent,
  SecondaryNavigationItem,
} from 'src/components/organisms/general/secondaryNavigation'

type Props = {
  slice: PrismicPageDataBodySecondaryNavigation
}

const SecondaryNavigationSlice = ({ slice }: Props) => {
  const content: SecondaryNavigationContent = {
    items: slice.items.map(
      item =>
        ({
          anchor: item.slice_anchor ?? undefined,
          navigationText: item.navigation_text ?? undefined,
        } as SecondaryNavigationItem)
    ),
  }

  return <SecondaryNavigation content={content} />
}
export default SecondaryNavigationSlice

export const SecondaryNavigationFragment = graphql`
  fragment PageDataBodySecondaryNavigation on PrismicPageDataBodySecondaryNavigation {
    items {
      navigation_text
      slice_anchor
    }
  }
`

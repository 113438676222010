import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { customScrollTo, generateRandomId } from 'src/utils/domHelper'

import { PrismicNewsroomArticle } from 'src/typings/generated/graphql'

import { useQueryParamString } from 'react-use-query-param-string'
import NewsroomArticleCard from 'src/components/molecules/cards/newsroomArticleCard'
import Eyebrow from 'src/images/svgs/Eyebrow.svg'
import BertholdTitle from 'src/components/atoms/bertholdTitle'

import * as Styles from './newsroomArticlesLibrary.module.scss'

export type NewsroomArticlesLibraryContent = {
  anchor?: string
  headline?: string
  articles: PrismicNewsroomArticle[]
  skipArticleId?: string
}

type Props = {
  content: NewsroomArticlesLibraryContent
}

const NewsroomArticlesLibrary = ({ content }: Props) => {
  const {
    anchor = generateRandomId(),
    headline = '',
    articles = [],
    skipArticleId = '',
  } = content
  const [pageQuery, setPageQuery] = useQueryParamString('page', '1')

  const currentPage = Number.isNaN(pageQuery) ? 1 : Number(pageQuery)

  const articlesPerPage = 3

  /**
   * Content Preparation
   */
  const articlesQuery = useStaticQuery(graphql`
    query NewsroomArticlesLibrary {
      allPrismicNewsroomArticle(
        sort: [{ data: { post_date: DESC } }, { first_publication_date: DESC }]
        limit: 4
      ) {
        nodes {
          uid
          first_publication_date
          data {
            title {
              text
            }
            url {
              url
            }
            banner_image {
              gatsbyImageData
              alt
            }
            thumbnail_image {
              gatsbyImageData
              alt
            }
            post_date
            outlet
            body {
              richText
              text
            }
          }
        }
      }
    }
  `)
  const recentArticles = articlesQuery.allPrismicNewsroomArticle
    .nodes as PrismicNewsroomArticle[]
  const filteredRecentArticles = recentArticles.filter(
    article => article.uid !== skipArticleId
  )
  if (filteredRecentArticles.length > 3) filteredRecentArticles.pop()

  const displayedArticles =
    articles.length > 0 ? articles : filteredRecentArticles

  const displayedArticlesPaged = [[]] as PrismicNewsroomArticle[][]
  for (let i = 0; i < displayedArticles.length; i += articlesPerPage) {
    displayedArticlesPaged[Math.floor(i / articlesPerPage)] =
      displayedArticles.slice(i, i + articlesPerPage)
  }

  /**
   * Actions
   */

  const setPage = (i: number) => {
    setPageQuery(`${i}`)
    customScrollTo(`#newsroom-articles-${anchor}`)
  }

  /**
   * Renderings
   */

  const pager = () => {
    if (displayedArticles.length <= articlesPerPage) return null

    let offset = 1
    if (displayedArticlesPaged.length > articlesPerPage) {
      if (currentPage > 3) offset = currentPage - 2
      if (currentPage >= displayedArticlesPaged.length - 1)
        offset = displayedArticlesPaged.length - 4
    }

    const prevClass =
      currentPage === 1
        ? `${Styles.pagerButton} ${Styles.prev} ${Styles.hidden}`
        : `${Styles.pagerButton} ${Styles.prev}`
    const nextClass =
      currentPage === displayedArticlesPaged.length
        ? `${Styles.pagerButton} ${Styles.next} ${Styles.hidden}`
        : `${Styles.pagerButton} ${Styles.next}`

    const maxPageButtons = 5
    const pagerLength =
      displayedArticlesPaged.length > articlesPerPage
        ? offset + maxPageButtons - 1
        : displayedArticlesPaged.length
    const pages = []

    for (let i = offset; i <= pagerLength; i += 1) {
      let pageClass = Styles.pageButton
      if (i === currentPage) pageClass += ` ${Styles.currentPage}`
      pages.push(
        <button
          type="button"
          className={pageClass}
          onClick={() => setPage(i)}
          key={`pager-${i}`}
        >
          {`0${i}`.slice(-2)}
        </button>
      )
    }

    return (
      <div className={Styles.pager}>
        <button
          type="button"
          className={prevClass}
          onClick={() => {
            setPage(currentPage !== 1 ? currentPage - 1 : 1)
          }}
        >
          Prev
        </button>
        {pages.map(page => page)}
        <button
          type="button"
          className={nextClass}
          onClick={() => {
            setPage(
              currentPage !== displayedArticlesPaged.length
                ? currentPage + 1
                : displayedArticlesPaged.length
            )
          }}
        >
          Next
        </button>
      </div>
    )
  }

  return (
    <section id={anchor} className={Styles.newsroomArticlesLibrary}>
      {headline && (
        <div className={Styles.headlineWrapper}>
          <div className={Styles.eyebrow}>
            <Eyebrow />
          </div>
          <h2 className={Styles.headline}>
            {BertholdTitle({ text: headline })}
          </h2>
        </div>
      )}
      <div role="tabpanel" aria-labelledby="tab-button-all">
        <div id={`newsroom-articles-${anchor}`} className={Styles.articles}>
          {displayedArticlesPaged[currentPage - 1] &&
            displayedArticlesPaged[currentPage - 1].map((article, i) => {
              const key = `activeNewsroomArticlesPage-${i}`
              return (
                <div className={Styles.article} key={key}>
                  <NewsroomArticleCard article={article} />
                </div>
              )
            })}
        </div>
        {pager()}
      </div>
    </section>
  )
}

export default NewsroomArticlesLibrary

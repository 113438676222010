// extracted by mini-css-extract-plugin
export var content = "footer-module--content--8e1bc";
export var copyrightContainer = "footer-module--copyright-container--9b3ce";
export var copyrightLinkText = "footer-module--copyright-link-text--338df";
export var copyrightLinkWrapper = "footer-module--copyright-link-wrapper--dda0a";
export var divider = "footer-module--divider--6b3bf";
export var footer = "footer-module--footer--fa9cf";
export var footerCta = "footer-module--footer-cta--d7ab4";
export var image = "footer-module--image--f5381";
export var link = "footer-module--link--54bed";
export var linkWrapper = "footer-module--link-wrapper--55b02";
export var logo = "footer-module--logo--841c3";
export var logoContainer = "footer-module--logo-container--21209";
export var lowerContainer = "footer-module--lower-container--a3a80";
export var mainContainer = "footer-module--main-container--57f8d";
export var navContainer = "footer-module--nav-container--42df1";
export var oneTrustSettingsLink = "footer-module--one-trust-settings-link--dd10a";
export var selectDisable = "footer-module--select-disable--b8a2a";
export var socialMediaContainer = "footer-module--social-media-container--8924f";
export var socialMediaLink = "footer-module--social-media-link--dbe4d";
export var title = "footer-module--title--84fb3";
export var upperContainer = "footer-module--upper-container--77c16";
import React from 'react'

// eslint-disable-next-line import/prefer-default-export
export const useHasMounted = () => {
  const [hasMounted, setHasMounted] = React.useState(false)
  React.useEffect(() => {
    setHasMounted(true)
  }, [])
  return hasMounted
}

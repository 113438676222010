import { graphql } from 'gatsby'
import React from 'react'
import Quote, { QuoteContent } from 'src/components/organisms/general/quote'

type Props = {
  // TODO: Fix typing generation when using an alias
  slice: any
}

const QuoteSlice = ({ slice }: Props) => {
  const content: QuoteContent = {
    anchor: slice.primary.anchor ?? undefined,
    ctaText: slice.primary.ctaTextAlias?.text ?? undefined,
    description: slice.primary.description ?? undefined,
    quote: slice.primary.quote ?? undefined,
    quoteSignature: slice.primary.quote_signature ?? undefined,
    title: slice.primary.aliasTitle?.text ?? undefined,
  }

  return <Quote content={content} />
}

export default QuoteSlice

export const QuoteFragment = graphql`
  fragment PageDataBodyQuote on PrismicPageDataBodyQuote {
    primary {
      aliasTitle: title {
        text
      }
      anchor
      ctaTextAlias: cta_text {
        text
      }
      description {
        richText
      }
      quote
      quote_signature
    }
  }
`

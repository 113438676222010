import { PrismicRichText } from '@prismicio/react'
import React, { useState } from 'react'
import { PrismicFaqCategoryDataQuestionAndAnswer } from 'src/typings/generated/graphql'
import CustomLink from 'src/utils/customLink'

import * as Styles from './faqCategoryQuestion.module.scss'

export const generateFaqCategoryQuestionId = (
  categoryId: string,
  questionIndex: number
) => `faq-category-${categoryId}-question-${questionIndex}`

type Props = {
  id?: string
  question: PrismicFaqCategoryDataQuestionAndAnswer
}

const FaqCategoryQuestion = ({ id, question }: Props) => {
  const [open, setOpen] = useState(false)

  let containerClass = Styles.faqCategoryQuestion
  if (open) containerClass += ` ${Styles.open}`

  const questionText = question.question?.text ?? ''
  const answerId = `${id}-answer`

  return (
    <div className={containerClass}>
      <div id={id} role="heading" aria-level={3}>
        <div
          className={Styles.question}
          role="button"
          aria-expanded={open}
          aria-controls={answerId}
          tabIndex={0}
          onClick={() => setOpen(!open)}
          onKeyDown={e => {
            if (e.code === 'Enter') {
              setOpen(!open)
            }
          }}
        >
          {questionText}
        </div>
      </div>
      <div id={answerId} className={Styles.answer}>
        <PrismicRichText
          field={question.answer?.richText}
          components={{
            hyperlink: ({ node, children }: any) =>
              CustomLink({ link: node.data, children }),
          }}
        />
      </div>
    </div>
  )
}

export default FaqCategoryQuestion

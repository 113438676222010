/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/destructuring-assignment */
import React from 'react'
import { Link } from 'gatsby'
import { RTLinkNode } from '@prismicio/types'
import { Maybe, PrismicLinkType } from 'src/typings/generated/graphql'

import { customLinkResolver } from './linkResolver'

export type CustomLinkButton = {
  url: string
  onClick: Function
}

interface Props {
  link?: Maybe<PrismicLinkType> | RTLinkNode['data'] | CustomLinkButton | ''
  className?: string
  children?: JSX.Element | JSX.Element[] | string
  role?: string
  ariaLabel?: string
}

const CustomLink = (data: Props) => {
  if (!data.link) return <div className={data.className}>{data.children}</div>

  const tempClassName = data.className ?? ''

  if ('onClick' in data.link) {
    const link = data.link as CustomLinkButton
    return (
      <button
        type="button"
        onClick={e => {
          link.onClick(e)
        }}
        onKeyDown={e => {
          link.onClick(e)
        }}
        className={data.className}
        aria-label={data.ariaLabel}
      >
        {data.children ?? data.link.url}
      </button>
    )
  }

  const customLink = customLinkResolver(data.link)
  const linkTarget =
    'target' in data.link ? data.link.target ?? '_self' : '_self'

  if (customLink.external) {
    return (
      <a
        role={data.role}
        href={customLink.linkPath}
        target={linkTarget}
        rel="noreferrer"
        className={tempClassName}
        aria-label={data.ariaLabel}
      >
        {data.children ?? data.link.url}
      </a>
    )
  }

  return (
    <Link
      role={data.role}
      data-testid="custom-link"
      to={customLink.linkPath}
      target={linkTarget}
      className={tempClassName}
      aria-label={data.ariaLabel}
    >
      {data.children ?? data.link.url}
    </Link>
  )
}

export default CustomLink

import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { useLocation } from '@reach/router'

import * as GlobalStyles from 'src/styles/global.module.scss'
import {
  HeaderQuery,
  PrismicLinkType,
  PrismicNavigationDataPrimary,
  PrismicNavigationItemDataType,
} from 'src/typings/generated/graphql'
import CustomLink from 'src/utils/customLink'

import * as Styles from './header.module.scss'

const Header = () => {
  const data: HeaderQuery = useStaticQuery(graphql`
    query Header {
      prismicSiteInformation {
        data {
          site_icon {
            gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1.5)
            alt
          }
          home_page {
            url
          }
        }
      }
      prismicNavigation {
        data {
          primary {
            navigation_item {
              document {
                ...NavigationItemFragment
              }
            }
          }
        }
      }
    }
  `)

  const icon = data.prismicSiteInformation?.data.site_icon
    ?.gatsbyImageData as IGatsbyImageData
  const iconAlt = data.prismicSiteInformation?.data.site_icon?.alt ?? ''
  const primaryNavigation = data.prismicNavigation?.data
    .primary as PrismicNavigationDataPrimary[]
  const homepage = data.prismicSiteInformation?.data.home_page?.url ?? '/'
  useLocation()

  return (
    <header data-testid="header" className={Styles.webHeader}>
      <div className={Styles.container}>
        <a href={homepage} className={`${Styles.icon} ${Styles.selectDisable}`}>
          {!!icon && <GatsbyImage image={icon} alt={iconAlt} />}
        </a>
        <nav className={Styles.navigation}>
          <ul>
            {primaryNavigation.map(
              (primaryItem: PrismicNavigationDataPrimary, index: number) => {
                const navItem = primaryItem.navigation_item?.document
                  ?.data as PrismicNavigationItemDataType
                const link = navItem.link as PrismicLinkType
                const key = `link-${index}`

                return (
                  <li data-testid="nav-item" key={key}>
                    <CustomLink link={link} className={Styles.navLink}>
                      {navItem.title?.text ?? ''}
                    </CustomLink>
                  </li>
                )
              }
            )}
          </ul>
        </nav>
        <div className={Styles.actions}>
          <a
            href="https://order.raisingcanes.com/"
            target="_blank"
            className={`${GlobalStyles.redButton} ${GlobalStyles.small}`}
            rel="noreferrer"
          >
            Order Now
          </a>
        </div>
      </div>
    </header>
  )
}

export default Header

import * as React from 'react'
import {
  PrismicLinkType,
  PrismicStructuredTextType,
} from 'src/typings/generated/graphql'
import BertholdTitle from 'src/components/atoms/bertholdTitle'
import RetroIconsStrip from 'src/components/atoms/retroIconsStrip'
import { PrismicRichText } from '@prismicio/react'
import CustomLink from 'src/utils/customLink'
import * as GlobalStyles from 'src/styles/global.module.scss'
import { generateRandomId } from 'src/utils/domHelper'
import * as Styles from './cardsCta.module.scss'

enum CardsCtaLinkStyle {
  brand_red = 'brand red',
  crew_blue = 'crew blue',
}

enum CardsCtaBackgroundColor {
  dark_grey = 'dark grey',
  crew_blue = 'crew blue',
}

export type CardsCtaContent = {
  anchor?: string
  headline?: string
  description?: PrismicStructuredTextType
  link?: PrismicLinkType
  linkText?: string
  secondaryText?: string
  secondaryLink?: PrismicLinkType
  secondaryLinkText?: string
  secondaryLinkStyle?: string
  backgroundColor?: string
  showIcons?: boolean
}

interface Props {
  content: CardsCtaContent
}

const CardsCta = ({ content }: Props) => {
  const {
    anchor = generateRandomId(),
    headline = '',
    description,
    link,
    linkText = '',
    secondaryText = '',
    secondaryLink,
    secondaryLinkText = '',
    secondaryLinkStyle = CardsCtaLinkStyle.brand_red,
    backgroundColor = CardsCtaBackgroundColor.dark_grey,
    showIcons = true,
  } = content

  const secondaryLinkStyleFormatted = secondaryLinkStyle.toLowerCase()
  const backgroundColorFormatted = backgroundColor.toLowerCase()

  const buttonDisplay = () => {
    if (linkText) {
      return (
        <CustomLink link={link} className={GlobalStyles.whiteGhostButton}>
          {linkText ?? ''}
        </CustomLink>
      )
    }
    return (
      <CustomLink link={link}>
        <div className={Styles.whiteRightArrow} />
      </CustomLink>
    )
  }

  return (
    <section id={anchor} className={Styles.cardsCta}>
      <div
        className={
          backgroundColorFormatted === CardsCtaBackgroundColor.crew_blue
            ? `${Styles.primaryCopy} ${Styles.blueBackground}`
            : `${Styles.primaryCopy} ${Styles.darkGreyBackground}`
        }
      >
        {showIcons && (
          <div className={Styles.retroIconsWrapper}>
            <RetroIconsStrip />
          </div>
        )}
        <h2 className={Styles.headline}>{BertholdTitle({ text: headline })}</h2>
        {description && (
          <div className={Styles.description}>
            <PrismicRichText
              field={description.richText}
              components={{
                hyperlink: ({ node, children }: any) =>
                  CustomLink({ link: node.data, children }),
              }}
            />
          </div>
        )}
        {link && <div className={Styles.button}>{buttonDisplay()}</div>}
      </div>
      {(secondaryText || secondaryLink?.url) && (
        <div className={Styles.secondaryCopy}>
          {secondaryText && (
            <div className={Styles.secondaryText}>{secondaryText}</div>
          )}
          {secondaryLink?.url && (
            <CustomLink
              link={secondaryLink}
              className={
                secondaryLinkStyleFormatted === CardsCtaLinkStyle.crew_blue
                  ? GlobalStyles.blueBasicLink
                  : GlobalStyles.redBasicLink
              }
            >
              {secondaryLinkText ?? ''}
            </CustomLink>
          )}
        </div>
      )}
    </section>
  )
}

export default CardsCta

import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React from 'react'
import { generateRandomId } from 'src/utils/domHelper'
import * as Styles from './imageStrip.module.scss'

export type ImageStripContent = {
  anchor?: string
  image1?: IGatsbyImageData
  image1Alt?: string
  image2?: IGatsbyImageData
  image2Alt?: string
  image3?: IGatsbyImageData
  image3Alt?: string
  image4?: IGatsbyImageData
  image4Alt?: string
}

type Props = {
  content: ImageStripContent
}

const ImageStrip = ({ content }: Props) => {
  const {
    anchor = generateRandomId(),
    image1,
    image1Alt = '',
    image2,
    image2Alt = '',
    image3,
    image3Alt = '',
    image4,
    image4Alt = '',
  } = content
  return (
    <section id={anchor} className={Styles.imageStrip}>
      {!!image1 && (
        <GatsbyImage className={Styles.image} image={image1} alt={image1Alt} />
      )}
      {!!image2 && (
        <GatsbyImage className={Styles.image} image={image2} alt={image2Alt} />
      )}
      {!!image3 && (
        <GatsbyImage className={Styles.image} image={image3} alt={image3Alt} />
      )}
      {!!image4 && (
        <GatsbyImage className={Styles.image} image={image4} alt={image4Alt} />
      )}
    </section>
  )
}

export default ImageStrip

import React from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { PrismicLinkType } from 'src/typings/generated/graphql'
import BertholdTitle from 'src/components/atoms/bertholdTitle'

import Eyebrow from 'src/images/svgs/Eyebrow.svg'
import ForwardCarrotIcon from 'src/images/svgs/icons/ForwardCarrotIcon.svg'

import CustomLink from 'src/utils/customLink'

import * as Styles from './basicLinkCard.module.scss'

type Props = {
  title: string
  image?: IGatsbyImageData
  imageAlt: string
  link?: PrismicLinkType
  linkText: string
}

const BasicLinkCard = ({ title, image, imageAlt, link, linkText }: Props) => (
  <div className={Styles.basicImageCard}>
    <CustomLink link={link} className={Styles.linkContainer}>
      <div className={Styles.wrapper}>
        <div className={Styles.content}>
          <div className={Styles.titleWrapper}>
            <div className={Styles.eyebrow}>
              <Eyebrow />
            </div>
            <h3 className={Styles.title}>{BertholdTitle({ text: title })}</h3>
          </div>
          <div className={Styles.imageWrapper}>
            {!!image && (
              <GatsbyImage
                image={image}
                alt={imageAlt}
                className={Styles.image}
              />
            )}
          </div>
          <div className={Styles.link}>
            <span>{linkText}</span>
            <ForwardCarrotIcon />
          </div>
        </div>
      </div>
    </CustomLink>
  </div>
)

export default BasicLinkCard

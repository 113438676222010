import { Script } from 'gatsby'
import React from 'react'
import { generateRandomId } from 'src/utils/domHelper'

import * as Styles from './eCardWidget.module.scss'

export type ECardWidgetContent = {
  anchor?: string
  eCardWidgetId: string
}

type Props = {
  content: ECardWidgetContent
}

const ECardWidget = ({ content }: Props) => {
  const { anchor = generateRandomId(), eCardWidgetId } = content

  if (!eCardWidgetId) return null

  return (
    <section id={anchor} className={Styles.eCardWidget}>
      <Script
        type="text/javascript"
        src={`https://app.ecardwidget.com/embed/widget/${eCardWidgetId}`}
      />
      <div className={Styles.contentWrapper}>
        <div id={`ecardwidget_id_${eCardWidgetId}`} />
        <a href="https://ecardwidget.com/" className={Styles.waterMark}>
          Powered by eCardWidget
        </a>
      </div>
    </section>
  )
}

export default ECardWidget

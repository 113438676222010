import React from 'react'

import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

import { graphql, useStaticQuery } from 'gatsby'
import {
  ArticlesHelperQuery,
  Maybe,
  PrismicArticle,
  PrismicCategory,
  PrismicNewsroomArticle,
} from 'src/typings/generated/graphql'

const getArticleData = () =>
  useStaticQuery(graphql`
    query ArticlesHelper {
      prismicGlobalContent {
        data {
          article_default_thumbnail_image {
            gatsbyImageData
            alt
            url
          }
          article_default_banner_image {
            gatsbyImageData
            alt
            url
          }
        }
      }
    }
  `) as ArticlesHelperQuery

/**
 * Determines the correct Hero Image to use for an Article
 *
 * @param article The relative Article
 * @param imageClass className for returned
 */
export const articleThumbnailImageUrl = (
  article: PrismicArticle | PrismicNewsroomArticle
) => {
  let imageUrl = ''

  const data = getArticleData()

  let category: Maybe<PrismicCategory> = null
  if ('category' in article) {
    category = (article as PrismicArticle).data.category
      ?.document as Maybe<PrismicCategory>
  }

  if (article.data.thumbnail_image?.url) {
    imageUrl = article.data.thumbnail_image.url
  } else if (category && category.data.image?.url) {
    imageUrl = category.data.image.url
  } else if (
    data.prismicGlobalContent?.data.article_default_thumbnail_image?.url
  ) {
    imageUrl =
      data.prismicGlobalContent?.data.article_default_thumbnail_image?.url
  }

  return imageUrl
}

/**
 * Determines the correct Banner Image to use for an Article
 *
 * @param article The relative Article
 */
export const articleBannerImageUrl = (
  article: PrismicArticle | PrismicNewsroomArticle
) => {
  let imageUrl = ''

  const data = getArticleData()

  if (article.data.banner_image?.url) {
    imageUrl = article.data.banner_image.url
  } else if (
    data.prismicGlobalContent?.data.article_default_banner_image?.url
  ) {
    imageUrl = data.prismicGlobalContent.data.article_default_banner_image?.url
  }

  return imageUrl ?? articleThumbnailImageUrl(article)
}

/**
 * Determines the correct Hero Image to use for an Article
 *
 * @param article The relative Article
 * @param imageClass className for returned
 */
export const articleThumbnailImage = (
  article: PrismicArticle | PrismicNewsroomArticle,
  imageClass?: string
) => {
  let image: IGatsbyImageData | null = null
  let alt: string = ''

  const data = getArticleData()
  let category: Maybe<PrismicCategory> = null
  if ('category' in article) {
    category = (article as PrismicArticle).data.category
      ?.document as Maybe<PrismicCategory>
  }

  if (article.data.thumbnail_image?.gatsbyImageData) {
    image = article.data.thumbnail_image.gatsbyImageData
    alt = article.data.thumbnail_image.alt ?? ''
  } else if (category && category.data.image?.gatsbyImageData) {
    image = category.data.image.gatsbyImageData
    alt = category.data.image.alt ?? ''
  } else if (
    data.prismicGlobalContent?.data.article_default_thumbnail_image
      ?.gatsbyImageData
  ) {
    image =
      data.prismicGlobalContent?.data.article_default_thumbnail_image
        ?.gatsbyImageData
    alt =
      data.prismicGlobalContent?.data.article_default_thumbnail_image?.alt ?? ''
  }

  return image ? (
    <GatsbyImage className={imageClass ?? ''} image={image} alt={alt} />
  ) : null
}

/**
 * Determines the correct Banner Image to use for an Article
 *
 * @param article The relative Article
 */
export const articleBannerImage = (
  article: PrismicArticle,
  imageClass?: string
) => {
  let image: IGatsbyImageData | null = null
  let alt: string = ''

  const data = getArticleData()

  if (article.data.banner_image?.gatsbyImageData) {
    image = article.data.banner_image.gatsbyImageData
    alt = article.data.banner_image.alt ?? ''
  } else if (
    data.prismicGlobalContent?.data.article_default_banner_image
      ?.gatsbyImageData
  ) {
    image =
      data.prismicGlobalContent.data.article_default_banner_image
        ?.gatsbyImageData
    alt = data.prismicGlobalContent.data.article_default_banner_image?.alt ?? ''
  }

  return image ? (
    <GatsbyImage className={imageClass ?? ''} image={image} alt={alt} />
  ) : (
    articleThumbnailImage(article, imageClass)
  )
}

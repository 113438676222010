import React, {
  Dispatch,
  forwardRef,
  SetStateAction,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import GoogleMapReact from 'google-map-react'
import { PointFeature } from 'supercluster'
import useSupercluster from 'use-supercluster'
import { BBox, GeoJsonProperties } from 'geojson'

import { PrismicStoreLocation } from 'src/typings/generated/graphql'
import { useHasMounted } from 'src/utils/useHasMounted'
import { calculateDistance } from 'src/utils/geoLocation'

import LocationPin from 'src/components/atoms/locationPin'
import {
  getDistanceFromLatLonInKm,
  getStoreLocationDisplayName,
  getStoreLocationStage,
} from 'src/utils/storeLocationHelper'
import * as Styles from './locationsMap.module.scss'

export interface IVisibleLocation {
  locationIndex: number
  location: PrismicStoreLocation
  distanceFromCurrentLocation: number | null
}

export type ILocationsMapRef = {
  map: google.maps.Map | undefined
  mapsApi: typeof google.maps | undefined
}

type Props = {
  locations: PrismicStoreLocation[]
  highlightedLocationIndex?: number
  selectedLocationIndex?: number
  center?: GoogleMapReact.Coords
  interactive?: boolean
  maxZoom?: number
  userCurrentLocation?: GoogleMapReact.Coords
  zoom?: number
  setHighlightedLocationIndex?: Dispatch<SetStateAction<number | undefined>>
  setSelectedLocationIndex?: Dispatch<SetStateAction<number>>
  setMapHasChanged?: Dispatch<SetStateAction<boolean | undefined>>
  setVisibleLocations?: Dispatch<SetStateAction<IVisibleLocation[]>>
}

const LocationsMap = forwardRef<ILocationsMapRef | undefined, Props>(
  (
    {
      locations,
      highlightedLocationIndex,
      selectedLocationIndex,
      center,
      interactive = true,
      maxZoom = 15,
      userCurrentLocation,
      zoom,
      setHighlightedLocationIndex,
      setSelectedLocationIndex,
      setMapHasChanged,
      setVisibleLocations,
    },
    ref
  ) => {
    // Map Configurations
    const viewportWidth =
      typeof window !== 'undefined' ? window.innerWidth : null
    const googleMapsKey = process.env.GOOGLE_MAPS_KEY ?? ''
    const mapConfig = {
      center: { lat: 37.4822807172226, lng: -95.994254625 },
      zoom: viewportWidth && viewportWidth < 767 ? 3 : 4,
    }

    const mapRef = useRef<google.maps.Map>()
    const [mapsApi, setMapsApi] = useState<typeof google.maps>()
    const [mapBounds, setMapBounds] = useState<BBox>([
      -128.144531, 21.125498, -65.917969, 53.173119,
    ])
    const [mapZoom, setMapZoom] = useState(mapConfig.zoom)

    const points: Array<PointFeature<GeoJsonProperties>> = locations?.map(
      (location, i) => {
        const locationStage = getStoreLocationStage(location)
        const locationName = getStoreLocationDisplayName(location)
        const locationZip =
          location.data.override_external_zip_code ??
          location.data.external_location_data?.postal_code ??
          ''
        return {
          type: 'Feature',
          properties: {
            cluster: false,
            locationId: location.uid,
            locationName,
            index: i,
            locationStage,
            locationZip,
          },
          geometry: {
            type: 'Point',
            coordinates: [
              location.data.coordinates?.longitude ?? 0,
              location.data.coordinates?.latitude ?? 0,
            ],
          },
        }
      }
    )

    const [highlightedClusterId, setHighLightedClusterId] = useState(-1)
    const { clusters, supercluster } = useSupercluster({
      points,
      bounds: mapBounds,
      zoom: mapZoom,
      options: { radius: 75, maxZoom },
    })

    // Map Handlers

    const updateVisibleLocations = (bounds: GoogleMapReact.Bounds) => {
      if (setVisibleLocations) {
        const centerCoords = mapConfig.center
        if (mapRef.current) {
          const mapCenter = mapRef.current.getCenter()
          centerCoords.lat = mapCenter?.lat() ?? 0
          centerCoords.lng = mapCenter?.lng() ?? 0
        }
        const visibleLocations: IVisibleLocation[] = locations
          .map((location, i) => {
            let distanceFromCurrentLocation = null
            if (userCurrentLocation) {
              distanceFromCurrentLocation = calculateDistance(
                {
                  lat: location.data.coordinates?.latitude ?? 0,
                  lng: location.data.coordinates?.longitude ?? 0,
                },
                {
                  lat: userCurrentLocation.lat,
                  lng: userCurrentLocation.lng,
                }
              )
            }
            return {
              locationIndex: i,
              location,
              distanceFromCurrentLocation,
            } as IVisibleLocation
          })
          .filter(visibleLocation => {
            const lat = visibleLocation.location.data.coordinates?.latitude ?? 0
            const lng =
              visibleLocation.location.data.coordinates?.longitude ?? 0

            const northBound = bounds.ne.lat
            const southBound = bounds.se.lat

            let eastBound = bounds.ne.lng
            let westBound = bounds.nw.lng

            // Adjust to work on scale of 0 to 360 to determine # of rotations using division
            eastBound += 180
            westBound += 180
            if (eastBound > 360) {
              // If map rotated eastward (over 360 degrees),
              // determine number of rotations and reset to scale of 0 to 360
              const rotations = Math.floor(eastBound / 360)
              const rotatedDegrees = 360 * rotations
              eastBound -= rotatedDegrees
              westBound -= rotatedDegrees
            } else if (westBound < 0) {
              // If map rotated westward (less than 360 degrees),
              // determine number of rotations and reset to scale of 0 to 360
              const rotations = Math.abs(Math.ceil(westBound / 360))
              const rotatedDegrees = 360 * rotations
              eastBound += rotatedDegrees
              westBound += rotatedDegrees
            }
            // Adjust scale back to -180 to 180
            eastBound -= 180
            westBound -= 180

            // Determine if
            if (
              lat > southBound &&
              lat < northBound &&
              ((lng > westBound && lng < eastBound) ||
                // Handles where longitude resets from 180 to -180 (or vice versa)
                (westBound < -180 &&
                  lng > westBound + 360 &&
                  lng < eastBound + 360))
            )
              return visibleLocation

            return null
          })
          .sort((a, b) => {
            // return a.distanceFromCurrentLocation - b.distanceFromCurrentLocation
            const aCoords = {
              lat: a.location.data.coordinates?.latitude ?? 0,
              lng: a.location.data.coordinates?.longitude ?? 0,
            }

            const bCoords = {
              lat: b.location.data.coordinates?.latitude ?? 0,
              lng: b.location.data.coordinates?.longitude ?? 0,
            }

            return (
              calculateDistance(aCoords, centerCoords) -
              calculateDistance(bCoords, centerCoords)
            )
          })

        setVisibleLocations(visibleLocations)
      }
    }

    const handleMapChange = ({
      bounds,
      // eslint-disable-next-line @typescript-eslint/no-shadow
      zoom,
    }: GoogleMapReact.ChangeEventValue) => {
      setMapZoom(zoom)
      setMapBounds([bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat])

      updateVisibleLocations(bounds)
    }

    // Imperative Ref Handler

    useImperativeHandle(ref, () => ({
      map: mapRef.current,
      mapsApi,
    }))

    // Component

    const mapStyles: GoogleMapReact.MapTypeStyle[] = [
      {
        featureType: 'administrative',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#444444',
          },
        ],
      },
      {
        featureType: 'administrative.locality',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#940513',
          },
        ],
      },
      {
        featureType: 'administrative.neighborhood',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#940513',
          },
        ],
      },
      {
        featureType: 'landscape',
        elementType: 'all',
        stylers: [
          {
            color: '#f2f2f2',
          },
        ],
      },
      {
        featureType: 'landscape.natural.terrain',
        elementType: 'geometry.fill',
        stylers: [
          {
            color: '#e5e5e5',
          },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'all',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'all',
        stylers: [
          {
            saturation: -100,
          },
          {
            lightness: 45,
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'all',
        stylers: [
          {
            visibility: 'simplified',
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry.fill',
        stylers: [
          {
            color: '#f0d6b4',
          },
        ],
      },
      {
        featureType: 'road.arterial',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#484b53',
          },
        ],
      },
      {
        featureType: 'road.arterial',
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'road.local',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#242424',
          },
        ],
      },
      {
        featureType: 'transit',
        elementType: 'all',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'all',
        stylers: [
          {
            color: '#46bcec',
          },
          {
            visibility: 'on',
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'geometry.fill',
        stylers: [
          {
            color: '#c9e2e9',
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#242424',
          },
        ],
      },
    ]

    // Calculate distance from user location to each location
    for (let i: number = 0; i < clusters.length; i += 1) {
      const userLatitude =
        userCurrentLocation === undefined
          ? ''
          : userCurrentLocation.lat.toString()
      const userLongitude =
        userCurrentLocation === undefined
          ? ''
          : userCurrentLocation.lng.toString()

      const distance: number = getDistanceFromLatLonInKm(
        parseInt(userLatitude, 10),
        parseInt(userLongitude, 10),
        clusters[i].geometry.coordinates[1],
        clusters[i].geometry.coordinates[0]
      )
      ;(clusters[i] as any).distance = distance * 0.621371
    }

    clusters.sort((a: any, b: any) => a.distance - b.distance)

    return (
      <div className={Styles.locationsMap}>
        {useHasMounted() && (
          <GoogleMapReact
            bootstrapURLKeys={{ key: googleMapsKey, libraries: ['places'] }}
            defaultCenter={mapConfig.center}
            center={center}
            defaultZoom={mapConfig.zoom}
            zoom={zoom}
            options={{
              draggable: interactive,
              fullscreenControl: interactive,
              keyboardShortcuts: interactive,
              zoomControl: interactive,
              zoomControlOptions: {
                position: 3,
              },
              clickableIcons: false,
              styles: mapStyles,
            }}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => {
              mapRef.current = map
              setMapsApi(maps)
            }}
            onChange={handleMapChange}
            onChildClick={() => {
              if (setMapHasChanged) setMapHasChanged(true)
            }}
            onDragEnd={() => {
              if (setMapHasChanged) setMapHasChanged(true)
            }}
            onZoomAnimationEnd={() => {
              if (setMapHasChanged) setMapHasChanged(true)
            }}
            onClick={() => {
              if (setSelectedLocationIndex) setSelectedLocationIndex(-1)
            }}
          >
            {clusters.map((cluster, index) => {
              const [longitude, latitude] = cluster.geometry.coordinates
              const isCluster = cluster.properties?.cluster
              const pointCount = cluster.properties?.point_count
              const key = `clusterPin-${index}`
              const locationPinAriaLabel = `${
                cluster.properties?.locationName ??
                cluster.properties?.locationId
              } map pin`

              if (isCluster) {
                let highlighted = cluster.id === highlightedClusterId
                let locationClusterAriaLabel = `${pointCount} Raising Cane's Locations`
                if (typeof cluster.id === 'number') {
                  const clusterPoints = supercluster?.getLeaves(cluster.id, -1)
                  if (clusterPoints) {
                    for (let i = 0; i < clusterPoints.length; i += 1) {
                      if (
                        clusterPoints[i].properties?.index ===
                        highlightedLocationIndex
                      ) {
                        highlighted = true
                        break
                      }
                    }
                    locationClusterAriaLabel += ` in or near zip code ${clusterPoints[0].properties?.locationZip}`
                  }
                }

                return (
                  <LocationPin
                    lat={latitude}
                    lng={longitude}
                    count={pointCount}
                    interactive={interactive}
                    key={key}
                    highlighted={highlighted}
                    ariaLabel={locationClusterAriaLabel}
                    onClick={() => {
                      mapRef.current?.panTo({ lat: latitude, lng: longitude })
                      mapRef.current?.setZoom(mapZoom + 2)
                    }}
                    onKeyDown={(e: React.KeyboardEvent) => {
                      if (e.code === 'Enter') {
                        mapRef.current?.panTo({ lat: latitude, lng: longitude })
                        mapRef.current?.setZoom(mapZoom + 2)
                      }
                    }}
                    onMouseEnter={() => {
                      if (typeof cluster.id === 'number')
                        setHighLightedClusterId(cluster.id)
                    }}
                    onMouseLeave={() => {
                      setHighLightedClusterId(-1)
                    }}
                  />
                )
              }

              return (
                <LocationPin
                  lat={latitude}
                  lng={longitude}
                  interactive={interactive}
                  key={key}
                  count={index + 1}
                  ariaLabel={locationPinAriaLabel}
                  highlighted={
                    cluster.properties?.index === highlightedLocationIndex
                  }
                  selected={cluster.properties?.index === selectedLocationIndex}
                  onClick={() => {
                    if (setSelectedLocationIndex)
                      setSelectedLocationIndex(cluster.properties?.index)
                    mapRef.current?.panTo({ lat: latitude, lng: longitude })
                  }}
                  onKeyDown={(e: React.KeyboardEvent) => {
                    if (e.code === 'Enter' && setSelectedLocationIndex)
                      setSelectedLocationIndex(cluster.properties?.index)
                    mapRef.current?.panTo({ lat: latitude, lng: longitude })
                  }}
                  onMouseEnter={() => {
                    if (setHighlightedLocationIndex)
                      setHighlightedLocationIndex(cluster.properties?.index)
                  }}
                  onMouseLeave={() => {
                    if (setHighlightedLocationIndex)
                      setHighlightedLocationIndex(-1)
                  }}
                />
              )
            })}
          </GoogleMapReact>
        )}
      </div>
    )
  }
)

export default LocationsMap

// extracted by mini-css-extract-plugin
export var image = "verticalImageReel-module--image--e5faf";
export var imageContainer = "verticalImageReel-module--image-container--9c0d2";
export var paused = "verticalImageReel-module--paused--b9773";
export var slideDown1 = "verticalImageReel-module--slide-down-1--b2bc1";
export var slideDown10 = "verticalImageReel-module--slide-down-10--f929b";
export var slideDown2 = "verticalImageReel-module--slide-down-2--b8d43";
export var slideDown3 = "verticalImageReel-module--slide-down-3--d3e74";
export var slideDown4 = "verticalImageReel-module--slide-down-4--df735";
export var slideDown5 = "verticalImageReel-module--slide-down-5--b9bbc";
export var slideDown6 = "verticalImageReel-module--slide-down-6--b7e01";
export var slideDown7 = "verticalImageReel-module--slide-down-7--77d53";
export var slideDown8 = "verticalImageReel-module--slide-down-8--e573c";
export var slideDown9 = "verticalImageReel-module--slide-down-9--d2fb5";
export var slideUp1 = "verticalImageReel-module--slide-up-1--cc46a";
export var slideUp10 = "verticalImageReel-module--slide-up-10--21950";
export var slideUp2 = "verticalImageReel-module--slide-up-2--0dc2d";
export var slideUp3 = "verticalImageReel-module--slide-up-3--d7ab5";
export var slideUp4 = "verticalImageReel-module--slide-up-4--a63cb";
export var slideUp5 = "verticalImageReel-module--slide-up-5--7068e";
export var slideUp6 = "verticalImageReel-module--slide-up-6--d57d4";
export var slideUp7 = "verticalImageReel-module--slide-up-7--90fc9";
export var slideUp8 = "verticalImageReel-module--slide-up-8--cfc68";
export var slideUp9 = "verticalImageReel-module--slide-up-9--2d7d0";
export var verticalImageReel = "verticalImageReel-module--vertical-image-reel--30b92";
// extracted by mini-css-extract-plugin
export var arrow = "mobileHeaderNavigation-module--arrow--99de6";
export var background = "mobileHeaderNavigation-module--background--b93b2";
export var container = "mobileHeaderNavigation-module--container--48c81";
export var homeLink = "mobileHeaderNavigation-module--home-link--62fc0";
export var icon = "mobileHeaderNavigation-module--icon--2d846";
export var navigation = "mobileHeaderNavigation-module--navigation--8dfb2";
export var primary = "mobileHeaderNavigation-module--primary--87712";
export var primaryLink = "mobileHeaderNavigation-module--primary-link--c82a4";
export var utilities = "mobileHeaderNavigation-module--utilities--5a266";
export var utilityLink = "mobileHeaderNavigation-module--utility-link--b34c8";
import React from 'react'
import { graphql } from 'gatsby'

import {
  PrismicPageDataBodyMenuItemGroup,
  PrismicPageDataBodyMenuItemGroupItem,
  PrismicMenuItem,
} from 'src/typings/generated/graphql'
import MenuItemGroup, {
  MenuItemGroupContent,
  MenuItemGroupItem,
} from 'src/components/organisms/productRouting/menuItemGroup'

type Props = {
  slice: PrismicPageDataBodyMenuItemGroup
}

const MenuItemGroupSlice = ({ slice }: Props) => {
  const items: MenuItemGroupItem[] = []
  slice.items.forEach((item: PrismicPageDataBodyMenuItemGroupItem) => {
    if (item.menu_item?.document)
      items.push({
        link: item.menu_item,
        menuItem: item.menu_item.document as PrismicMenuItem,
      })
  })

  const content: MenuItemGroupContent = {
    anchor: slice.primary.anchor ?? undefined,
    headline: slice.primary.headline ?? undefined,
    items: items ?? undefined,
  }
  return <MenuItemGroup content={content} />
}

export default MenuItemGroupSlice

export const MenuItemGroupFragment = graphql`
  fragment PageDataBodyMenuItemGroup on PrismicPageDataBodyMenuItemGroup {
    primary {
      anchor
      headline
    }
    items {
      menu_item {
        type
        link_type
        document {
          ... on PrismicMenuItem {
            data {
              name
              description {
                richText
              }
              image {
                alt
                gatsbyImageData
                url
              }
            }
          }
        }
      }
    }
  }
`

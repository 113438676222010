import { graphql } from 'gatsby'
import React from 'react'
import DoubleCta, {
  DoubleCtaContent,
} from 'src/components/organisms/ctas/doubleCta'
import { PrismicPageDataBodyDoubleCta } from 'src/typings/generated/graphql'

type Props = {
  slice: PrismicPageDataBodyDoubleCta
}

const DoubleCtaSlice = ({ slice }: Props) => {
  const content: DoubleCtaContent = {
    anchor: slice.primary.anchor ?? '',
    headlineText1: slice.primary.headline_text_1?.text ?? '',
    description1: slice.primary.description_1?.text ?? '',
    buttonText1: slice.primary.cta_button_text_1?.text ?? '',
    ctaLink1: slice.primary.cta_link_1 ?? undefined,
    headlineText2: slice.primary.headline_text_2?.text ?? '',
    description2: slice.primary.description_2?.text ?? '',
    buttonText2: slice.primary.cta_button_text_2?.text ?? '',
    ctaLink2: slice.primary.cta_link_2 ?? undefined,
  }

  return <DoubleCta content={content} />
}

export default DoubleCtaSlice

export const doubleCtaFragment = graphql`
  fragment PageDataBodyDoubleCta on PrismicPageDataBodyDoubleCta {
    primary {
      anchor
      headline_text_1 {
        text
      }
      description_1 {
        text
      }
      cta_button_text_1 {
        text
      }
      cta_link_1 {
        ...BasicLinkFragment
      }
      headline_text_2 {
        text
      }
      description_2 {
        text
      }
      cta_button_text_2 {
        text
      }
      cta_link_2 {
        ...BasicLinkFragment
      }
    }
  }
`

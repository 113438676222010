import React from 'react'
import Eyebrow from 'src/images/svgs/Eyebrow.svg'
import * as Styles from './statGrid.module.scss'

export type GridContent = {
  anchor?: string
  heading?: string
  backgroundImage?: string
  stats?: any[]
}

type Props = {
  content: GridContent
}

const StatGrid = ({ content }: Props) => {
  const { anchor, heading = '', backgroundImage, stats } = content

  return (
    <section
      id={anchor}
      className={Styles.statGrid}
      style={{
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      <div className={Styles.colorOverlay} />
      <div className={Styles.headingContainer}>
        <div className={Styles.eyebrow}>
          <Eyebrow />
        </div>
        {heading && <h2>{heading}</h2>}
      </div>
      <div className={Styles.statContainer}>
        {stats &&
          stats?.map(stat => (
            <div key={stat.stat_headline} className={Styles.stat}>
              <h2>{stat.stat_headline}</h2>
              <p>{stat.stat_description}</p>
            </div>
          ))}
      </div>
    </section>
  )
}

export default StatGrid

// extracted by mini-css-extract-plugin
export var biography = "profileHighlight-module--biography--82ab2";
export var biographyToggle = "profileHighlight-module--biographyToggle--26c04";
export var collapsed = "profileHighlight-module--collapsed--480c0";
export var details = "profileHighlight-module--details--0b0c0";
export var eyebrow = "profileHighlight-module--eyebrow--6cb8f";
export var headline = "profileHighlight-module--headline--f270f";
export var headlineContainer = "profileHighlight-module--headline-container--ca03a";
export var highlight = "profileHighlight-module--highlight--07ec8";
export var highlights = "profileHighlight-module--highlights--0f5a0";
export var image = "profileHighlight-module--image--e0550";
export var mainContentWrapper = "profileHighlight-module--main-content-wrapper--c5064";
export var profileHighlight = "profileHighlight-module--profile-highlight--2274b";
export var profileImage = "profileHighlight-module--profile-image--dc62b";
export var socialMedia = "profileHighlight-module--social-media--0dea0";
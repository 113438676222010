import {
  PrismicPageDataBodyMenuItemGroup,
  PrismicPageDataBodySlicesType,
  PrismicMenuItem,
  PageHelperCategoriesQueryQuery,
} from 'src/typings/generated/graphql'
import { MenuItem, Question, WithContext } from 'schema-dts'
import { graphql, useStaticQuery } from 'gatsby'

export enum SchemaType {
  menu = 'menu',
  faq = 'faq',
  website = 'website',
}

// Helper function to parse through pageBody for slices.
// If there is an instance of MenuItemGroup slice then schema is Menu.
// If there is an instance of Faq slice then schema is Faq.
// Default to website schema type.
export function parseSlicesForSchemaType(
  pageBody: PrismicPageDataBodySlicesType[]
) {
  let schemaType = SchemaType.website
  for (let i = 0; i < pageBody.length; i += 1) {
    const slice = pageBody[i]
    switch (slice.slice_type) {
      case 'menu_item_group':
        schemaType = SchemaType.menu
        break
      case 'faq':
        schemaType = SchemaType.faq
        break
      default:
      // do nothing
    }
    if (schemaType !== SchemaType.website) {
      return schemaType
    }
  }
  return schemaType
}

// Helper function to parse through pageBody and for each MenuItemGroup slice instance
// build up array of MenuItem schema type.
export function buildHasMenuItemsArray(
  pageBody: PrismicPageDataBodySlicesType[],
  menuItemUrl: string
) {
  const menuItemsArray: MenuItem[] = []
  pageBody.forEach(slice => {
    if (slice.slice_type === 'menu_item_group') {
      const menuItemGroupSlice = slice as PrismicPageDataBodyMenuItemGroup
      menuItemGroupSlice.items.forEach(menuItemGroupItem => {
        const menuItemDocument = menuItemGroupItem.menu_item
          ?.document as PrismicMenuItem
        const menuItemSchema: MenuItem = {
          '@type': 'MenuItem',
          url: menuItemUrl,
          name: menuItemDocument.data.name ?? '',
          image: menuItemDocument.data.image?.url ?? '',
        }
        menuItemsArray.push(menuItemSchema)
      })
    }
  })
  return menuItemsArray
}

export function buildFaqMainEntityArray() {
  const mainEntity: Question[] = []
  // faq slice only stores the headline. Need to query all the faq records since the page doens't have that information attached to it
  const categoriesQuery: PageHelperCategoriesQueryQuery =
    useStaticQuery(graphql`
      query PageHelperCategoriesQuery {
        allPrismicFaqCategory(sort: { data: { title: { text: ASC } } }) {
          nodes {
            data {
              title {
                text
              }
              question_and_answer {
                question {
                  text
                }
                answer {
                  text
                }
              }
            }
          }
        }
      }
    `)

  categoriesQuery.allPrismicFaqCategory.nodes.forEach(faqCategory => {
    faqCategory.data.question_and_answer?.forEach(faq => {
      const schema: WithContext<Question> = {
        '@type': 'Question',
        '@context': 'https://schema.org',
        name: faq?.question?.text ?? '',
        acceptedAnswer: {
          '@type': 'Answer',
          text: faq?.answer?.text ?? '',
        },
      }

      mainEntity.push(schema)
    })
  })

  return mainEntity
}

// extracted by mini-css-extract-plugin
export var blueBasicLink = "global-module--blue-basic-link--c2305";
export var dismissButton = "global-module--dismiss-button--1b49f";
export var goldPill = "global-module--gold-pill--a666c";
export var greyPill = "global-module--grey-pill--372f1";
export var line = "global-module--line--85ffa";
export var redBasicLink = "global-module--red-basic-link--798f9";
export var redButton = "global-module--red-button--56e40";
export var redButtonResponsive = "global-module--red-button-responsive--0f3f4";
export var redGhostButton = "global-module--red-ghost-button--3172d";
export var redGhostButtonResponsive = "global-module--red-ghost-button-responsive--e2dfd";
export var skipLink = "global-module--skip-link--34ebf";
export var small = "global-module--small--92e53";
export var srOnly = "global-module--sr-only--204b4";
export var whiteBasicLink = "global-module--white-basic-link--e950d";
export var whiteButton = "global-module--white-button--6e1ed";
export var whiteButtonResponsive = "global-module--white-button-responsive--b3052";
export var whiteGhostButton = "global-module--white-ghost-button--39ce7";
export var whiteGhostButtonResponsive = "global-module--white-ghost-button-responsive--424ec";
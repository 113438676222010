import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import ArticleCard from 'src/components/molecules/cards/articleCard'
import {
  PrismicArticle,
  RecentArticlesQuery,
} from 'src/typings/generated/graphql'
import BertholdTitle from 'src/components/atoms/bertholdTitle'

import Eyebrow from 'src/images/svgs/Eyebrow.svg'

import * as Styles from './recentArticles.module.scss'

export type RecentArticlesContnet = {
  articleCategoryId?: string
  headline?: string
  hiddenArticleId?: string
}

type Props = {
  content: RecentArticlesContnet
}

const RecentArticles = ({ content }: Props) => {
  const articleData: RecentArticlesQuery = useStaticQuery(graphql`
    query RecentArticles {
      categoriezedRecent: allPrismicArticle(
        sort: [{ data: { post_date: DESC } }, { first_publication_date: DESC }]
      ) {
        group(field: { data: { category: { id: SELECT } } }, limit: 4) {
          fieldValue
          nodes {
            uid
            first_publication_date
            data {
              title {
                text
              }
              thumbnail_image {
                gatsbyImageData
                alt
              }
              category {
                document {
                  ...CategoryFragment
                }
              }
              url {
                url
              }
              post_date
            }
          }
        }
      }
      topRecent: allPrismicArticle(limit: 4) {
        nodes {
          uid
          first_publication_date
          data {
            title {
              text
            }
            thumbnail_image {
              gatsbyImageData
              alt
            }
            category {
              document {
                ...CategoryFragment
              }
            }
            url {
              url
            }
            post_date
          }
        }
      }
    }
  `)

  const {
    articleCategoryId = '',
    headline = '',
    hiddenArticleId = '',
  } = content

  // Set the articles to display depending on the slice category settings
  let articles: PrismicArticle[] = articleData.topRecent
    .nodes as PrismicArticle[]
  if (articleCategoryId) {
    for (let i = 0; i < articleData.categoriezedRecent.group.length; i += 1) {
      const categoryGroup = articleData.categoriezedRecent.group[i]
      if (categoryGroup.fieldValue === articleCategoryId) {
        articles = categoryGroup.nodes as PrismicArticle[]
        break
      }
    }
  }
  // Strip out the hidden article if necessary
  if (hiddenArticleId) {
    articles = articles.filter(article => article.uid !== hiddenArticleId)
  }
  // If Articles is now empty, display top recent and strip out the hidden article if it's in this array
  if (articles.length === 0) {
    articles = articleData.topRecent.nodes as PrismicArticle[]
    if (hiddenArticleId) {
      articles = articles.filter(article => article.uid !== hiddenArticleId)
    }
  }

  // Pop the extra article off if the hidden article was not found or not provided in the final articles array
  if (articles.length > 3) articles.pop()

  return (
    <section className={Styles.recentArticles}>
      <div className={Styles.eyebrow}>
        <Eyebrow />
      </div>
      <h2 className={Styles.headline}>{BertholdTitle({ text: headline })}</h2>
      <div className={Styles.articles}>
        {articles.map((article, index) => {
          const key = `article-${index}`
          return <ArticleCard article={article} key={key} />
        })}
      </div>
    </section>
  )
}

export default RecentArticles

// extracted by mini-css-extract-plugin
export var article = "{prismicNewsroomArticle-url}-module--article--e4ce5";
export var banner = "{prismicNewsroomArticle-url}-module--banner--5536d";
export var body = "{prismicNewsroomArticle-url}-module--body--ea720";
export var bodyContainer = "{prismicNewsroomArticle-url}-module--body-container--ab800";
export var eyebrow = "{prismicNewsroomArticle-url}-module--eyebrow--23e4f";
export var headline = "{prismicNewsroomArticle-url}-module--headline--8f4f8";
export var hero = "{prismicNewsroomArticle-url}-module--hero--68e48";
export var image = "{prismicNewsroomArticle-url}-module--image--135df";
export var imageOverlay = "{prismicNewsroomArticle-url}-module--image-overlay--d6997";
export var imageWrapper = "{prismicNewsroomArticle-url}-module--image-wrapper--048db";
export var loader = "{prismicNewsroomArticle-url}-module--loader--ed370";
export var social = "{prismicNewsroomArticle-url}-module--social--2f822";
export var wave = "{prismicNewsroomArticle-url}-module--wave--91b5a";
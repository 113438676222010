import React from 'react'
import BertholdTitle from 'src/components/atoms/bertholdTitle'
import CustomLink from 'src/utils/customLink'
import { generateRandomId } from 'src/utils/domHelper'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'
import { PrismicStructuredTextType } from 'src/typings/generated/graphql'
import * as GlobalStyles from 'src/styles/global.module.scss'
import * as Styles from './menuItemDetails.module.scss'

export type MenuItemDetailsContent = {
  anchor?: string
  name?: string
  description?: PrismicStructuredTextType
  image?: IGatsbyImageData
  imageAlt?: string
}

type Props = {
  content: MenuItemDetailsContent
}

const MenuItemDetails = ({ content }: Props) => {
  const {
    anchor = generateRandomId(),
    name,
    description,
    image,
    imageAlt = '',
  } = content

  return (
    <section id={anchor} className={Styles.menuItemDetails}>
      <div className={Styles.details}>
        <div className={Styles.detailsInner}>
          <div>
            <h2>{name && BertholdTitle({ text: name })}</h2>
            <PrismicRichText
              field={description?.richText}
              components={{
                hyperlink: ({ node, children }: any) =>
                  CustomLink({ link: node.data, children }),
              }}
            />
          </div>
          <div>
            <a
              href="https://order.raisingcanes.com"
              className={`${GlobalStyles.redGhostButton}`}
            >
              Order Now
            </a>
          </div>
        </div>
      </div>
      <div className={Styles.imageContainer}>
        {image && <GatsbyImage image={image} alt={imageAlt} />}
      </div>
    </section>
  )
}

export default MenuItemDetails

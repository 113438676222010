import React from 'react'

import { GatsbyImage } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'
import BertholdTitle from 'src/components/atoms/bertholdTitle'

import { PrismicLinkType, PrismicMenuItem } from 'src/typings/generated/graphql'

import Eyebrow from 'src/images/svgs/Eyebrow.svg'
import RightArrowIcon from 'src/images/svgs/icons/RightArrowIcon.svg'
import CustomLink from 'src/utils/customLink'
import { generateRandomId } from 'src/utils/domHelper'
import * as Styles from './menuItemGroup.module.scss'

export type MenuItemGroupItem = {
  menuItem?: PrismicMenuItem
  link?: PrismicLinkType
}

export type MenuItemGroupContent = {
  anchor?: string
  headline?: string
  items?: MenuItemGroupItem[]
}

type Props = {
  content: MenuItemGroupContent
}

const MenuItemGroup = ({ content }: Props) => {
  const { anchor = generateRandomId(), headline = '', items = [] } = content

  const menuItemCard = (item: MenuItemGroupItem) => (
    <CustomLink link={item.link} className={Styles.menuItem}>
      <div>
        <div className={Styles.eyebrow}>
          <Eyebrow />
        </div>
        <div className={Styles.title}>
          <h3>
            {item.menuItem?.data?.name &&
              BertholdTitle({ text: item.menuItem?.data?.name })}
          </h3>
          <div className={Styles.arrow}>
            <RightArrowIcon />
          </div>
        </div>
      </div>
      {item.menuItem?.data.image?.gatsbyImageData && (
        <GatsbyImage
          className={Styles.image}
          image={item.menuItem.data.image.gatsbyImageData}
          alt={item.menuItem?.data.image?.alt ?? ''}
          objectFit="contain"
        />
      )}
      <div className={Styles.menuItemDetails}>
        {item.menuItem?.data.description && (
          <PrismicRichText
            field={item.menuItem.data.description.richText}
            components={{
              hyperlink: ({ node, children }: any) =>
                CustomLink({ link: node.data, children }),
            }}
          />
        )}
      </div>
    </CustomLink>
  )

  return (
    <section id={anchor} className={Styles.menuItemGroup} tabIndex={-1}>
      <h2>{BertholdTitle({ text: headline })}</h2>
      <div className={Styles.menuItemsWrapper}>
        <div className={Styles.menuItems}>
          {items.map((item: MenuItemGroupItem, i: number) => {
            const key = `menu-item-${i}`
            return (
              <div key={key} className={Styles.menuItemWrapper}>
                {item ? menuItemCard(item) : null}
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default MenuItemGroup

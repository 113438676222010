import { PrismicRichText } from '@prismicio/react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React from 'react'
import BertholdTitle from 'src/components/atoms/bertholdTitle'

import { PrismicStructuredTextType } from 'src/typings/generated/graphql'

import TimeLineTrailOne from 'src/images/svgs/timeline/TimelineTrailOne.svg'
import TimeLineTrailTwo from 'src/images/svgs/timeline/TimelineTrailTwo.svg'
import TimeLineTrailThree from 'src/images/svgs/timeline/TimelineTrailThree.svg'

import SunglassesIcon from 'src/images/svgs/icons/retro/SunglassesIcon.svg'
import FoamFingerOneLoveIcon from 'src/images/svgs/icons/retro/FoamFingerOneLoveIcon.svg'
import OneLoveIcon from 'src/images/svgs/icons/retro/OneLoveIcon.svg'
import FoamFingerHeartIcon from 'src/images/svgs/icons/retro/FoamFingerHeartIcon.svg'
import GlobeIcon from 'src/images/svgs/icons/retro/GlobeIcon.svg'
import NintySixIcon from 'src/images/svgs/icons/retro/NintySixIcon.svg'
import HeartsIcon from 'src/images/svgs/icons/retro/HeartsIcon.svg'
import CustomLink from 'src/utils/customLink'
import { generateRandomId } from 'src/utils/domHelper'
import * as Styles from './timeline.module.scss'

const retroIcons = [
  {
    icon: <SunglassesIcon />,
    iconClass: Styles.sunglasses,
  },
  {
    icon: <FoamFingerOneLoveIcon />,
    iconClass: Styles.foamFingerOneLove,
  },
  {
    icon: <OneLoveIcon />,
    iconClass: Styles.oneLove,
  },
  {
    icon: <FoamFingerHeartIcon />,
    iconClass: Styles.foamFingerHeart,
  },
  {
    icon: <GlobeIcon />,
    iconClass: Styles.globe,
  },
  {
    icon: <NintySixIcon />,
    iconClass: Styles.nintySix,
  },
  {
    icon: <HeartsIcon />,
    iconClass: Styles.hearts,
  },
]

export type TimelineEvent = {
  title?: string
  description?: PrismicStructuredTextType
  year?: string
  icon?: IGatsbyImageData
  iconAlt?: string
  image?: IGatsbyImageData
  imageAlt?: string
}

export type TimelineContent = {
  anchor?: string
  headline?: string
  items?: TimelineEvent[]
}

type Props = {
  content: TimelineContent
}

const Timeline = ({ content }: Props) => {
  let retroIconIndex = 0

  const { anchor = generateRandomId(), headline = '', items = [] } = content

  const retroIcon = () => {
    const icon = retroIcons[retroIconIndex]
    retroIconIndex =
      retroIconIndex === retroIcons.length - 1 ? 0 : retroIconIndex + 1
    return (
      <div className={`${Styles.retroIcon} ${icon.iconClass}`}>{icon.icon}</div>
    )
  }

  const timelineEvent = (event: TimelineEvent, i: number) => {
    const {
      title = '',
      description,
      year = '',
      icon,
      iconAlt = '',
      image,
      imageAlt = '',
    } = event

    let trail = (
      <div className={Styles.trailTwo}>
        <TimeLineTrailTwo />
      </div>
    )
    if (i % 4 === 0)
      trail = (
        <div className={Styles.trailOne}>
          <TimeLineTrailOne />
        </div>
      )
    if (i % 4 === 2)
      trail = (
        <div className={Styles.trailThree}>
          <TimeLineTrailThree />
        </div>
      )
    return (
      <div className={Styles.event} key={`event-${i}`}>
        <div className={Styles.contentWrapper}>
          {i !== items.length - 1 && trail}
          {!icon && retroIcon()}
          <div className={Styles.mainContent}>
            <h4 className={Styles.year}>{year}</h4>
            {!!image && (
              <GatsbyImage
                className={Styles.image}
                image={image}
                alt={imageAlt}
              />
            )}
            <h3>{BertholdTitle({ text: title })}</h3>
            {description && (
              <PrismicRichText
                field={description.richText}
                components={{
                  hyperlink: ({ node, children }: any) =>
                    CustomLink({ link: node.data, children }),
                }}
              />
            )}
          </div>
          {icon && (
            <div className={Styles.iconWrapper}>
              <GatsbyImage image={icon} alt={iconAlt} />
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <section id={anchor} className={Styles.timeline}>
      <div className={Styles.headerContainer}>
        <h2>{BertholdTitle({ text: headline })}</h2>
      </div>
      {items.map((e, i) => timelineEvent(e, i))}
    </section>
  )
}

export default Timeline

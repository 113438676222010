// extracted by mini-css-extract-plugin
export var eyebrowDivider = "highlights-module--eyebrow-divider--b4de8";
export var goodDogIcon = "highlights-module--good-dog-icon--431d6";
export var hardHatIcon = "highlights-module--hard-hat-icon--82b61";
export var heartsIcon = "highlights-module--hearts-icon--f7891";
export var highlight = "highlights-module--highlight--13b42";
export var highlightsSlice = "highlights-module--highlights-slice--bee92";
export var highlightsWrapper = "highlights-module--highlights-wrapper--e8399";
export var image = "highlights-module--image--1f02b";
export var imageWrapper = "highlights-module--image-wrapper--c3c08";
export var link = "highlights-module--link--9a157";
export var mainContainer = "highlights-module--main-container--8f1c3";
export var oneLoveIcon = "highlights-module--one-love-icon--b06f7";
export var retroImages = "highlights-module--retro-images--c4972";
export var salmonIcon = "highlights-module--salmon-icon--4f131";
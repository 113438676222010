import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React, { useEffect, useState } from 'react'
import {
  PrismicLinkType,
  DownloadAppBannerQuery,
} from 'src/typings/generated/graphql'
import CustomLink from 'src/utils/customLink'
import * as GlobalStyles from 'src/styles/global.module.scss'

import * as Styles from './downloadAppBanner.module.scss'

const DownloadAppBanner = () => {
  const downloadAppBannerQuery: DownloadAppBannerQuery = useStaticQuery(graphql`
    query DownloadAppBanner {
      prismicGlobalContent {
        data {
          enable_download_app_banner
          download_app_icon {
            gatsbyImageData
            alt
          }
          download_app_text
          download_app_link {
            ...BasicLinkFragment
          }
          download_app_link_text
        }
      }
    }
  `)

  const enableBanner =
    downloadAppBannerQuery.prismicGlobalContent?.data.enable_download_app_banner
  const icon = downloadAppBannerQuery.prismicGlobalContent?.data
    ?.download_app_icon?.gatsbyImageData as IGatsbyImageData
  const iconAlt =
    downloadAppBannerQuery.prismicGlobalContent?.data?.download_app_icon?.alt ??
    ''
  const bannerText =
    downloadAppBannerQuery.prismicGlobalContent?.data?.download_app_text ?? ''
  const downloadLink =
    (downloadAppBannerQuery.prismicGlobalContent?.data
      ?.download_app_link as PrismicLinkType) ?? ''
  const downloadLinkText =
    downloadAppBannerQuery.prismicGlobalContent?.data?.download_app_link_text ??
    'Download'

  let sessionStorage: Storage
  const downloadAppDismissedKey = 'downloadAppDismissed'
  let downloadAppDismissed: string | null
  if (typeof window !== 'undefined') {
    sessionStorage = window.sessionStorage
    downloadAppDismissed = sessionStorage.getItem(downloadAppDismissedKey)
  }

  const [displayBanner, setDisplayBanner] = useState(false)

  useEffect(() => {
    if (!downloadAppDismissed) setDisplayBanner(true)
  }, [displayBanner])

  // Handlers
  const dismissBanner = () => {
    if (displayBanner) {
      setDisplayBanner(false)
      sessionStorage.setItem(downloadAppDismissedKey, 'true')
    }
  }

  // Render
  let downloadAppClass = Styles.downloadAppBanner
  if (!displayBanner) downloadAppClass += ` ${Styles.hide}`

  return enableBanner ? (
    <div className={downloadAppClass}>
      <div />
      <div className={Styles.bannerContent}>
        {icon && (
          <div className={Styles.icon}>
            <GatsbyImage image={icon} alt={iconAlt} />
          </div>
        )}
        <div className={Styles.bannerText}>{bannerText}</div>
        {downloadLink && (
          <CustomLink
            className={`${GlobalStyles.whiteGhostButton} ${GlobalStyles.small}`}
            link={downloadLink}
          >
            {downloadLinkText}
          </CustomLink>
        )}
      </div>
      <div className={GlobalStyles.dismissButton}>
        <div className={GlobalStyles.line} />
        <div className={GlobalStyles.line} />
        <button
          type="button"
          onClick={dismissBanner}
          aria-label="Dismiss Download App"
        >
          <span>Dismiss Download App</span>
        </button>
      </div>
    </div>
  ) : null
}

export default DownloadAppBanner

import { graphql } from 'gatsby'
import React from 'react'
import ArticlesLibrary, {
  ArticlesLibraryContent,
} from 'src/components/organisms/engagement/articlesLibrary'

import { PrismicPageDataBodyArticlesLibrary } from 'src/typings/generated/graphql'

type Props = {
  slice: PrismicPageDataBodyArticlesLibrary
}

const ArticlesLibrarySlice = ({ slice }: Props) => {
  const content: ArticlesLibraryContent = {
    anchor: slice.primary.anchor ?? undefined,
    categoriesHeader: slice.primary.categories_header ?? undefined,
    placeholderCardLink: slice.primary.placeholder_card_link ?? undefined,
    placeholderCardLinkText:
      slice.primary.placeholder_card_link_text ?? undefined,
  }

  return <ArticlesLibrary content={content} />
}
export default ArticlesLibrarySlice

export const ArticlesLibraryFragment = graphql`
  fragment PageDataBodyArticlesLibrary on PrismicPageDataBodyArticlesLibrary {
    id
    primary {
      anchor
      categories_header
      placeholder_card_link_text
      placeholder_card_link {
        ...BasicLinkFragment
      }
    }
  }
`

import * as React from 'react'
import { graphql } from 'gatsby'

import { PrismicPageDataBodyLogoCta } from 'src/typings/generated/graphql'
import LogoCTA, { LogoCTAContent } from 'src/components/organisms/ctas/logoCta'

interface Props {
  slice: PrismicPageDataBodyLogoCta
}

const LogoCTASlice = ({ slice }: Props) => {
  const content: LogoCTAContent = {
    anchor: slice.primary.anchor ?? undefined,
    buttonLink: slice.primary.button_link ?? undefined,
    buttonText: slice.primary.button_text ?? undefined,
    headline: slice.primary.headline ?? undefined,
    backgroundColor: slice.primary.background_color ?? undefined,
  }
  return <LogoCTA content={content} />
}

export default LogoCTASlice

export const logoCtaFragment = graphql`
  fragment PageDataBodyLogoCta on PrismicPageDataBodyLogoCta {
    primary {
      anchor
      headline
      button_text
      button_link {
        ...BasicLinkFragment
      }
      background_color
    }
  }
`

// extracted by mini-css-extract-plugin
export var arrow = "menuItemGroup-module--arrow--70fe4";
export var colorChange = "menuItemGroup-module--colorChange--dfb6a";
export var eyebrow = "menuItemGroup-module--eyebrow--62d85";
export var image = "menuItemGroup-module--image--19087";
export var menuItem = "menuItemGroup-module--menu-item--910a6";
export var menuItemDetails = "menuItemGroup-module--menu-item-details--5f0c0";
export var menuItemGroup = "menuItemGroup-module--menu-item-group--a4c8b";
export var menuItemWrapper = "menuItemGroup-module--menu-item-wrapper--79bef";
export var menuItems = "menuItemGroup-module--menu-items--fcc06";
export var menuItemsWrapper = "menuItemGroup-module--menu-items-wrapper--56e85";
export var title = "menuItemGroup-module--title--78ec0";
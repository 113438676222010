import React from 'react'
import {
  PrismicLinkType,
  PrismicStructuredTextType,
} from 'src/typings/generated/graphql'
import { generateRandomId } from 'src/utils/domHelper'
import CustomLink from 'src/utils/customLink'

import * as GlobalStyles from 'src/styles/global.module.scss'

import { PrismicRichText } from '@prismicio/react'
import * as Styles from './richTextCta.module.scss'

export type RichTextCtaContent = {
  anchor?: string
  description?: PrismicStructuredTextType
  link?: PrismicLinkType
  linkText?: string
}

type Props = {
  content: RichTextCtaContent
}

const RichTextCta = ({ content }: Props) => {
  const { anchor = generateRandomId(), description, link, linkText } = content

  return (
    <section id={anchor} className={Styles.richTextCta}>
      <div className={Styles.content}>
        <PrismicRichText
          field={description?.richText}
          components={{
            hyperlink: ({ node, children }: any) =>
              CustomLink({ link: node.data, children }),
          }}
        />
        {link?.url && (
          <CustomLink
            className={`${GlobalStyles.redButton} ${GlobalStyles.small} ${Styles.link}`}
            link={link}
          >
            {linkText ?? ''}
          </CustomLink>
        )}
      </div>
    </section>
  )
}

export default RichTextCta

// extracted by mini-css-extract-plugin
export var actionContainer = "saucyCarousel-module--action-container--1ad35";
export var active = "saucyCarousel-module--active--14b94";
export var arrow = "saucyCarousel-module--arrow--18118";
export var arrowLeft = "saucyCarousel-module--arrow-left--50724";
export var arrowRight = "saucyCarousel-module--arrow-right--aee1e";
export var background = "saucyCarousel-module--background--29f0e";
export var callToAction = "saucyCarousel-module--call-to-action--35683";
export var carousel = "saucyCarousel-module--carousel--67424";
export var carouselContainer = "saucyCarousel-module--carousel-container--05ea7";
export var carouselWrapper = "saucyCarousel-module--carousel-wrapper--4ccf9";
export var hidden = "saucyCarousel-module--hidden--96186";
export var next = "saucyCarousel-module--next--16661";
export var next2 = "saucyCarousel-module--next-2--b43be";
export var prev = "saucyCarousel-module--prev--64026";
export var prev2 = "saucyCarousel-module--prev-2--7d8b9";
export var saucyCarousel = "saucyCarousel-module--saucy-carousel--23446";
export var selectDisable = "saucyCarousel-module--select-disable--d1f0f";
export var slideWrapper = "saucyCarousel-module--slide-wrapper--8e209";
export var title = "saucyCarousel-module--title--c53be";
export var web = "saucyCarousel-module--web--d746d";
import React, { useState } from 'react'
import * as GlobalStyles from 'src/styles/global.module.scss'
import { toggleBodyScroll } from 'src/utils/domHelper'

import MobileHeaderNavigation from './mobileHeaderNavigation'
import * as Styles from './mobileHeader.module.scss'

const MobileHeader = () => {
  // State
  const [navigationOpen, setNavigationOpen] = useState(false)

  // Class Styles
  let navigationButtonClass = `${Styles.navigationButton}`
  let navigationClass = `${Styles.navigation}`
  let headerClass = `${Styles.header}`
  if (navigationOpen) {
    navigationButtonClass = `${Styles.navigationButton} ${Styles.close}`
    navigationClass = `${Styles.navigation} ${Styles.open}`
    headerClass = `${Styles.header} ${Styles.open}`
  }

  // Events
  const toggleNavigation = () => {
    setNavigationOpen(!navigationOpen)
    toggleBodyScroll(navigationOpen)
  }

  // Component
  return (
    <div className={Styles.mobileHeader}>
      <MobileHeaderNavigation
        className={navigationClass}
        toggleNavigation={toggleNavigation}
      />
      <header data-testid="mobile-header" className={`${headerClass}`}>
        <div className={Styles.container}>
          <div className={Styles.actionGroup}>
            <button
              data-testid="navigation-toggle"
              type="button"
              aria-label={
                navigationOpen ? 'Hide Navigation Menu' : 'Show Navigation Menu'
              }
              className={navigationButtonClass}
              onClick={toggleNavigation}
              onKeyDown={e => {
                if (e.code === 'Enter') {
                  toggleNavigation()
                }
              }}
            >
              <span className={Styles.line} />
              <span className={Styles.line} />
              <span className={Styles.line} />
            </button>
          </div>
          <div
            className={`${Styles.actionGroup} ${Styles.orderNowActionGroup}`}
          >
            <a
              href="https://order.raisingcanes.com/"
              target="_blank"
              className={`${Styles.orderNowButton} ${GlobalStyles.redButton}`}
              rel="noreferrer"
            >
              Order Now
            </a>
          </div>
        </div>
      </header>
    </div>
  )
}

export default MobileHeader

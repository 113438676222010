import React, { useState, useEffect } from 'react'
import { PrismicRichText } from '@prismicio/react'
import {
  Maybe,
  PrismicLinkType,
  PrismicStructuredTextType,
} from 'src/typings/generated/graphql'
import BertholdTitle from 'src/components/atoms/bertholdTitle'

import * as GlobalStyles from 'src/styles/global.module.scss'

import ArtOfChickenMeal from 'src/images/ArtofChickenMeal.webp'
import RightArrowIcon from 'src/images/svgs/icons/RightArrowIcon.svg'

import CustomLink from 'src/utils/customLink'
import RetroIconsStrip from 'src/components/atoms/retroIconsStrip'
import { generateRandomId } from 'src/utils/domHelper'
import * as Styles from './artOfAPerfectMeal.module.scss'

type IngredientDetails = {
  title: string
  details: Maybe<PrismicStructuredTextType> | undefined
}

export type ArtOfAPerfectMealContent = {
  anchor?: string
  headline?: string
  link?: PrismicLinkType
  linkText?: string
  sauceTitle?: string
  sauceDetails?: PrismicStructuredTextType
  slawTitle?: string
  slawDetails?: PrismicStructuredTextType
  drinkTitle?: string
  drinkDetails?: PrismicStructuredTextType
  friesTitle?: string
  friesDetails?: PrismicStructuredTextType
  toastTitle?: string
  toastDetails?: PrismicStructuredTextType
  chickenTitle?: string
  chickenDetails?: PrismicStructuredTextType
}

type Props = {
  content: ArtOfAPerfectMealContent
}

const ArtOfAPerfectMeal = ({ content }: Props) => {
  const {
    anchor = generateRandomId(),
    headline = '',
    link,
    linkText = '',
    sauceTitle = '',
    sauceDetails,
    slawTitle = '',
    slawDetails,
    drinkTitle = '',
    drinkDetails,
    friesTitle = '',
    friesDetails,
    toastTitle = '',
    toastDetails,
    chickenTitle = '',
    chickenDetails,
  } = content
  const [activeIngredientIndex, setActiveIngredientIndex] = useState(0)
  const [timerRunning, setTimerRunning] = useState(true)
  const TIMER_INTERVAL = 10000

  const ingredients: IngredientDetails[] = [
    {
      title: sauceTitle,
      details: sauceDetails,
    },
    {
      title: slawTitle,
      details: slawDetails,
    },
    {
      title: drinkTitle,
      details: drinkDetails,
    },
    {
      title: friesTitle,
      details: friesDetails,
    },
    {
      title: toastTitle,
      details: toastDetails,
    },
    {
      title: chickenTitle,
      details: chickenDetails,
    },
  ]

  useEffect(() => {
    let timerInterval: NodeJS.Timer
    if (timerRunning)
      timerInterval = setInterval(() => {
        const nextItem =
          activeIngredientIndex < 5 ? activeIngredientIndex + 1 : 0
        setActiveIngredientIndex(nextItem)
      }, TIMER_INTERVAL)
    return () => clearInterval(timerInterval)
  }, [timerRunning, activeIngredientIndex])

  return (
    <section id={anchor} className={Styles.artOfAPerfectMeal}>
      <div className={Styles.retroIconsWrapper}>
        <RetroIconsStrip />
      </div>
      <div className={Styles.contentWrapper}>
        <h2 className={Styles.headline}>{BertholdTitle({ text: headline })}</h2>
        <div className={Styles.content}>
          <div className={Styles.mealImageWrapper}>
            <img src={ArtOfChickenMeal} alt="Raising Cane's Perfect Meal" />
            <button
              type="button"
              className={`${Styles.ingredientButton} ${Styles.ingredient1} ${
                activeIngredientIndex === 0 ? Styles.active : null
              } ${Styles.selectDisable}`}
              onClick={() => {
                setTimerRunning(false)
                setActiveIngredientIndex(0)
              }}
            >
              <span>01</span>
            </button>
            <button
              type="button"
              className={`${Styles.ingredientButton} ${Styles.ingredient2} ${
                activeIngredientIndex === 1 ? Styles.active : null
              } ${Styles.selectDisable}`}
              onClick={() => {
                setTimerRunning(false)
                setActiveIngredientIndex(1)
              }}
            >
              <span>02</span>
            </button>
            <button
              type="button"
              className={`${Styles.ingredientButton} ${Styles.ingredient3} ${
                activeIngredientIndex === 2 ? Styles.active : null
              } ${Styles.selectDisable}`}
              onClick={() => {
                setTimerRunning(false)
                setActiveIngredientIndex(2)
              }}
            >
              <span>03</span>
            </button>
            <button
              type="button"
              className={`${Styles.ingredientButton} ${Styles.ingredient4} ${
                activeIngredientIndex === 3 ? Styles.active : null
              } ${Styles.selectDisable}`}
              onClick={() => {
                setTimerRunning(false)
                setActiveIngredientIndex(3)
              }}
            >
              <span>04</span>
            </button>
            <button
              type="button"
              className={`${Styles.ingredientButton} ${Styles.ingredient5} ${
                activeIngredientIndex === 4 ? Styles.active : null
              } ${Styles.selectDisable}`}
              onClick={() => {
                setTimerRunning(false)
                setActiveIngredientIndex(4)
              }}
            >
              <span>05</span>
            </button>
            <button
              type="button"
              className={`${Styles.ingredientButton} ${Styles.ingredient6} ${
                activeIngredientIndex === 5 ? Styles.active : null
              } ${Styles.selectDisable}`}
              onClick={() => {
                setTimerRunning(false)
                setActiveIngredientIndex(5)
              }}
            >
              <span>06</span>
            </button>
          </div>
          <div className={Styles.ingredientDetailsWrapper}>
            <button
              className={Styles.previousButton}
              type="button"
              onClick={() => {
                setTimerRunning(false)
                setActiveIngredientIndex(
                  activeIngredientIndex > 0
                    ? activeIngredientIndex - 1
                    : ingredients.length - 1
                )
              }}
              aria-label="previous ingredient"
            >
              <span className={Styles.arrow}>
                <RightArrowIcon />
              </span>
            </button>
            <div
              key={`details-${activeIngredientIndex}`}
              className={Styles.ingredientDetails}
            >
              <p className={Styles.activeIngredientIndex}>
                0{activeIngredientIndex + 1}
              </p>
              <h3 className={Styles.title}>
                {BertholdTitle({
                  text: ingredients[activeIngredientIndex].title,
                })}
              </h3>
              <PrismicRichText
                field={ingredients[activeIngredientIndex].details?.richText}
                components={{
                  hyperlink: ({ node, children }: any) =>
                    CustomLink({ link: node.data, children }),
                }}
              />
              <CustomLink
                className={`${GlobalStyles.redButtonResponsive} ${Styles.detailsButton}`}
                link={link}
              >
                {linkText}
              </CustomLink>
            </div>
            <button
              className={Styles.nextButton}
              type="button"
              onClick={() => {
                setTimerRunning(false)
                setActiveIngredientIndex(
                  activeIngredientIndex < ingredients.length - 1
                    ? activeIngredientIndex + 1
                    : 0
                )
              }}
              aria-label="next ingredient"
            >
              <span className={Styles.arrow}>
                <RightArrowIcon />
              </span>
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ArtOfAPerfectMeal

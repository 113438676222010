import googleMapReact from 'google-map-react'

// eslint-disable-next-line import/prefer-default-export
export const calculateDistance = (
  coord1: googleMapReact.Coords,
  coord2: googleMapReact.Coords
) => {
  // The math module contains a function
  // named toRadians which converts from
  // degrees to radians.
  const lng1 = (coord1.lng * Math.PI) / 180
  const lng2 = (coord2.lng * Math.PI) / 180
  const lat1 = (coord1.lat * Math.PI) / 180
  const lat2 = (coord2.lat * Math.PI) / 180

  // Haversine formula
  const dlon = lng2 - lng1
  const dlat = lat2 - lat1
  const a =
    Math.sin(dlat / 2) ** 2 +
    Math.cos(lat1) * Math.cos(lat2) * Math.sin(dlon / 2) ** 2

  const c = 2 * Math.asin(Math.sqrt(a))

  // Radius of earth in miles
  const r = 3956

  // calculate the result
  return c * r
}

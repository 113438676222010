import { graphql } from 'gatsby'
import React from 'react'
import Faq, { FaqContent } from 'src/components/organisms/general/faq'
import { PrismicPageDataBodyFaq } from 'src/typings/generated/graphql'

type Props = {
  slice: PrismicPageDataBodyFaq
}

const FaqSlice = ({ slice }: Props) => {
  const content: FaqContent = {
    anchor: slice.primary.anchor ?? undefined,
    headline: slice.primary.headline ?? undefined,
  }

  return <Faq content={content} />
}

export default FaqSlice

export const FaqFragment = graphql`
  fragment PageDataBodyFaq on PrismicPageDataBodyFaq {
    primary {
      anchor
      headline
    }
  }
`

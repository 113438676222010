import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicPageDataBodyCardsCta } from 'src/typings/generated/graphql'
import CardsCta, {
  CardsCtaContent,
} from 'src/components/organisms/ctas/cardsCta'

type Props = {
  slice: PrismicPageDataBodyCardsCta
}

const CardsCtaSlice = ({ slice }: Props) => {
  const content: CardsCtaContent = {
    anchor: slice.primary.anchor ?? undefined,
    headline: slice.primary.headline ?? undefined,
    description: slice.primary.description ?? undefined,
    link: slice.primary.link ?? undefined,
    linkText: slice.primary.link_text ?? undefined,
    secondaryText: slice.primary.secondary_text ?? undefined,
    secondaryLink: slice.primary.secondary_link ?? undefined,
    secondaryLinkText: slice.primary.secondary_link_text ?? undefined,
    secondaryLinkStyle: slice.primary.secondary_link_color ?? undefined,
    backgroundColor: slice.primary.background_color ?? undefined,
    showIcons: slice.primary.show_retro_icons ?? undefined,
  }

  return <CardsCta content={content} />
}

export default CardsCtaSlice

export const cardsCtaFragment = graphql`
  fragment PageDataBodyCardsCta on PrismicPageDataBodyCardsCta {
    primary {
      anchor
      headline
      description {
        richText
      }
      link {
        ...BasicLinkFragment
      }
      link_text
      secondary_link {
        ...BasicLinkFragment
      }
      secondary_link_text
      secondary_link_color
      secondary_text
      background_color
      show_retro_icons
    }
  }
`

import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React, { useEffect, useRef, useState } from 'react'
import CategoryPill, { IPillStyle } from 'src/components/atoms/categoryPill'
import {
  Maybe,
  PrismicArticle,
  PrismicCategory,
  PrismicLinkType,
} from 'src/typings/generated/graphql'
import { articleBannerImage } from 'src/utils/articlesHelper'
import VideoPauseIcon from 'src/images/icons/VideoPauseIcon.webp'
import VideoPlayIcon from 'src/images/icons/VideoPlayIcon.webp'

import EmbeddedVideo from './embeddedVideo'
import * as Styles from './saucyCarouselItem.module.scss'

export type SaucyCarouselItemContent = {
  title?: string
  image?: IGatsbyImageData
  imageAlt?: string
  link?: PrismicLinkType
  mobileImage?: IGatsbyImageData
  mobileImageAlt?: string
  overrideTitle?: boolean
  overrideImage?: boolean
  videoUrl?: string
}

type Props = {
  content: SaucyCarouselItemContent
  active: boolean
}

const SaucyCarouselItem = ({ content, active }: Props) => {
  let { title = '' } = content
  const {
    image,
    imageAlt = '',
    link,
    mobileImage,
    mobileImageAlt = '',
    overrideTitle = false,
    overrideImage = false,
    videoUrl,
  } = content
  const linkDocumentType = link?.document?.type
  let article: PrismicArticle | undefined

  if (linkDocumentType === 'article') {
    article = link?.document as PrismicArticle
  }

  if (overrideTitle && article) {
    title = article.data.title?.text ?? ''
  }

  let categoryText = ''
  let categoryIcon: IGatsbyImageData | undefined
  let categoryIconAlt = ''
  if (article) {
    const category = article.data.category?.document as Maybe<PrismicCategory>
    if (category) {
      categoryText = category?.data.name ?? ''
      categoryIcon = category?.data.icon?.gatsbyImageData ?? undefined
      categoryIconAlt = category?.data.icon?.alt ?? ''
    }
  }

  const desktopOnlyImage = !mobileImage
  const backgroundImage = () => {
    if (overrideImage && article)
      return articleBannerImage(article, Styles.backgroundMedia) ?? <div />

    if (image)
      return (
        <>
          <GatsbyImage
            image={image}
            alt={imageAlt}
            className={`${Styles.backgroundMedia} ${
              desktopOnlyImage ? '' : Styles.desktopImage
            }`}
          />
          {mobileImage && !desktopOnlyImage && (
            <GatsbyImage
              image={mobileImage}
              alt={mobileImageAlt}
              className={`${Styles.backgroundMedia} ${Styles.mobileImage}`}
            />
          )}
        </>
      )

    return <div />
  }

  const videoRef = useRef<HTMLVideoElement>(null)
  const [videoPaused, setVideoPaused] = useState(false)
  const toggleVideo = () => {
    if (videoRef.current?.paused) videoRef.current.play()
    else videoRef.current?.pause()

    setVideoPaused(videoRef.current?.paused ?? false)
  }
  useEffect(() => {
    if (videoUrl && active) videoRef.current?.play()
    else videoRef.current?.pause()

    setVideoPaused(videoRef.current?.paused ?? false)
  }, [active])

  let contentClass = `${Styles.content}`
  if (active) contentClass += ` ${Styles.active}`

  let pillClass = `${Styles.categoryPill}`
  if (active) pillClass += ` ${Styles.active}`

  const videoPlayOrPauseIcon = videoPaused ? 'play' : 'pause'

  return (
    <div
      data-testid="sc-item"
      role="link"
      className={`${Styles.saucyCarouselItem}`}
    >
      {!videoUrl && backgroundImage()}
      {videoUrl && (
        <>
          <EmbeddedVideo
            url={videoUrl}
            className={`${Styles.backgroundMedia} ${Styles.backgroundVideo}`}
            ariaLabel={title}
            videoRef={videoRef}
          />
          <button
            type="button"
            className={Styles.videoControl}
            onClick={e => {
              e.stopPropagation()
              toggleVideo()
            }}
            onKeyDown={e => {
              if (e.code === 'Enter') {
                e.stopPropagation()
                toggleVideo()
              }
            }}
            aria-label={videoPaused ? 'Play Video' : 'Pause Video'}
            aria-hidden={!active}
            tabIndex={active ? 0 : -1}
          >
            <img
              src={videoPaused ? VideoPlayIcon : VideoPauseIcon}
              alt={`${videoPlayOrPauseIcon} video`}
              aria-label={`saucy carousel ${videoPlayOrPauseIcon} video icon`}
            />
          </button>
        </>
      )}
      <div className={`${Styles.contentWrapper}`}>
        <div className={contentClass}>
          {!!categoryIcon && (
            <div className={Styles.categoryIcon}>
              <GatsbyImage image={categoryIcon} alt={categoryIconAlt} />
            </div>
          )}
          <div className={Styles.contentLink}>
            <div className={Styles.link}>{title}</div>
          </div>
          {categoryText && (
            <div className={pillClass}>
              <CategoryPill
                category={categoryText}
                style={'grey-gold' as IPillStyle}
                tabIndex={active ? 0 : -1}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default SaucyCarouselItem

/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect } from 'react'
import type { PageProps } from 'gatsby'
import 'src/styles/global.scss'

import toggleOneTrustSettings from 'src/utils/toggleOneTrustSettings'

import Footer from 'src/components/pageLayout/footer'
import Header from 'src/components/pageLayout/header'
import MobileHeader from 'src/components/pageLayout/mobileHeader'
import MobileLocator from 'src/components/organisms/restaurantLocator/mobileLocator'
import * as GlobalStyles from 'src/styles/global.module.scss'
import { generateRandomId } from 'src/utils/domHelper'

import SiteBanner from './siteBanner'
import DownloadAppBanner from './downloadAppBanner'
import * as Styles from './layout.module.scss'

const Layout = ({ children, location }: PageProps) => {
  const { hash } = location

  useEffect(() => {
    if (hash === '#show-cookie-preferences') {
      toggleOneTrustSettings()
      window.history.back()
    }
  }, [hash])

  const mainContentId = `main-content-${generateRandomId()}`

  return (
    <div className={Styles.layout}>
      <SiteBanner />
      <DownloadAppBanner />
      <a className={GlobalStyles.skipLink} href={`#${mainContentId}`}>
        <span>Skip to main content</span>
      </a>
      <div className={Styles.mobileHeader}>
        <MobileHeader />
      </div>
      <MobileLocator />
      <div className={Styles.webHeader}>
        <Header />
      </div>
      <div id={mainContentId} className={Styles.main}>
        <main>{children}</main>
      </div>
      <Footer />
    </div>
  )
}

export default Layout

import parse from 'html-react-parser'
import * as Styles from './bertholdTitle.module.scss'

type Props = {
  text: string
}

const BertholdTitle = (props: Props) => {
  const { text = '' } = props
  const trademarkIconSearch = /\u{2122}/gu
  const registeredIconSearch = /\u{00AE}/gu
  let textEdited = text.replaceAll(
    trademarkIconSearch,
    `<sup class=${Styles.trademarkShrink}>&#x2122</sup>`
  )
  textEdited = textEdited.replaceAll(
    registeredIconSearch,
    `<sup class=${Styles.trademarkShrink}>&#x00AE</sup>`
  )

  return parse(textEdited)
}

export default BertholdTitle

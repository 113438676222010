import { graphql } from 'gatsby'
import React from 'react'
import { PrismicPageDataBodyUpcomingRestaurants } from 'src/typings/generated/graphql'
import UpcomingRestaurants, {
  UpcomingRestaurantsContent,
} from 'src/components/organisms/restaurantLocator/upcomingRestaurants'

type Props = {
  slice: PrismicPageDataBodyUpcomingRestaurants
}

const UpcomingRestaurantsSlice = ({ slice }: Props) => {
  const content: UpcomingRestaurantsContent = {
    anchor: slice.primary.anchor ?? undefined,
    eyebrowText: slice.primary.eyebrow_text ?? undefined,
    headline: slice.primary.headline ?? undefined,
    style: slice.primary.style ?? undefined,
    image1: slice.primary.image_1?.gatsbyImageData ?? undefined,
    image1Alt: slice.primary.image_1?.alt ?? undefined,
    image2: slice.primary.image_2?.gatsbyImageData ?? undefined,
    image2Alt: slice.primary.image_2?.alt ?? undefined,
    image3: slice.primary.image_3?.gatsbyImageData ?? undefined,
    image3Alt: slice.primary.image_3?.alt ?? undefined,
    image4: slice.primary.image_4?.gatsbyImageData ?? undefined,
    image4Alt: slice.primary.image_4?.alt ?? undefined,
    image5: slice.primary.image_5?.gatsbyImageData ?? undefined,
    image5Alt: slice.primary.image_5?.alt ?? undefined,
  }

  return <UpcomingRestaurants content={content} />
}

export default UpcomingRestaurantsSlice

export const UpcomingRestaurantsFragment = graphql`
  fragment PageDataBodyUpcomingRestaurants on PrismicPageDataBodyUpcomingRestaurants {
    primary {
      anchor
      eyebrow_text
      headline
      style
      image_1 {
        gatsbyImageData
        alt
      }
      image_2 {
        gatsbyImageData
        alt
      }
      image_3 {
        gatsbyImageData
        alt
      }
      image_4 {
        gatsbyImageData
        alt
      }
      image_5 {
        gatsbyImageData
        alt
      }
    }
  }
`

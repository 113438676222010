// extracted by mini-css-extract-plugin
export var address = "upcomingRestaurants-module--address--f4b08";
export var columnOne = "upcomingRestaurants-module--column-one--4ccab";
export var columnTwo = "upcomingRestaurants-module--column-two--3d331";
export var content = "upcomingRestaurants-module--content--4b2fe";
export var contentWrapper = "upcomingRestaurants-module--content-wrapper--4b84c";
export var details = "upcomingRestaurants-module--details--c1d2b";
export var eyebrow = "upcomingRestaurants-module--eyebrow--099c1";
export var headline = "upcomingRestaurants-module--headline--92a13";
export var image = "upcomingRestaurants-module--image--b1557";
export var images = "upcomingRestaurants-module--images--95bae";
export var light = "upcomingRestaurants-module--light--b4e0c";
export var name = "upcomingRestaurants-module--name--53e72";
export var openingDate = "upcomingRestaurants-module--opening-date--b0116";
export var restaurant = "upcomingRestaurants-module--restaurant--00d63";
export var restaurants = "upcomingRestaurants-module--restaurants--05c61";
export var retroIcons = "upcomingRestaurants-module--retro-icons--9fddf";
export var schedule = "upcomingRestaurants-module--schedule--470f7";
export var upcomingRestaurants = "upcomingRestaurants-module--upcoming-restaurants--72688";